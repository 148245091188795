import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router";
import { RootStateType, AppDispatchType } from "..";

import { Divider, Button } from 'antd';

// import { setNoteListInfo, resetNoteListInfo, noteListState } from "../reducers/note_list_info";
import { setNoteListInfo, resetNoteListInfo, NoteListState } from "../features/note_list_info";
// import { prevPageState } from "../reducers/prev_page_info";
import { PrevPageState } from "../features/prev_page_info";
import NoteListEntry from "../components/notelist_entry";
import AppLayout from "../components/app_layout";

import "../css/note_list.css";

function Notelist(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => state.auth.authToken);
    const topicNo: number|null = useSelector<RootStateType, number|null>(state => state.topicList.selectedTopicNo);
    const topicTitle: string = useSelector<RootStateType, string>(state => state.topicList.selectedTopicTitle);

    // const curPageNo: number = useSelector<RootStateType, number>(state => state.noteList.curPageNo);
    // const curPageNo: number = useSelector((state: RootStateType): number => state.noteList.curPageNo);
    const curPageNo = useSelector<RootStateType, number>((state) => {
        let _curPageNo = state.noteList.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessNoteListInfo = sessionStorage.getItem("noteListInfo");
            if(sessNoteListInfo){
                const parsedSessNoteListInfo: NoteListState = JSON.parse(sessNoteListInfo);
                _curPageNo = parsedSessNoteListInfo["curPageNo"];
            }
        }

        // console.log('notelist.tsx._curPageNo:', _curPageNo);

        return _curPageNo;
    });

    // const noteListInfo: NoteListState = useSelector((state: RootStateType): NoteListState => {return({...state.noteList})});

    const itemsPerPage: number = 9;

    const prevPage = useSelector<RootStateType, string>(state => {
        let _prevPage = state.prevPageInfo.location;

        if(_prevPage === null || _prevPage === undefined){
            const sessPrevPageInfo = sessionStorage.getItem("prevPageInfo");
            if(sessPrevPageInfo){
                const parsedPrevPageInfo: PrevPageState = JSON.parse(sessPrevPageInfo);
                _prevPage = parsedPrevPageInfo["location"];
            }
        }

        return _prevPage;
    });


    const [notes, setNotes] = useState<JSX.Element>(<div></div>);
    const [pageIndexes, setPageIndexes] = useState<JSX.Element>(<div></div>);

    // const storedNoteList = useRef<Array<any>>([]);

    const [showConfirmDeleteTopic, setShowConfirmDeleteTopic] = useState<Boolean>(false);


    function onClickCreateNoteHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        navigate('/note/create');
    }

    function onClickBackButtonHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        // console.log('notelist.tsx.onClickBackButtonHandler.prevPage:', prevPage);

        // navigate(prevPage);
        navigate('/topics');
    }

    type noteItemType = {
        noteNo: number
        , title: string
        , lastUpdate: string
    };

    type pageIndexDataType = {
        firstPageNum?: number
        , prevPageNum?: number
        , curPageNum: number
        , nextPageNum?: number
        , lastPageNum?: number
    };

    type getnotesResBodyType = {
        success: boolean
        , notes: Array<noteItemType>
        , pageIndex: pageIndexDataType
    };

    function onClickPageIndexHandler(event: React.MouseEvent<HTMLElement>){
        event.preventDefault();

        let newPageNo: number = event.currentTarget.dataset.pageno ? parseInt(event.currentTarget.dataset.pageno) : 1;
        // console.log('notelist.tsx.onClickPageIndexHandler.newPageNo:', newPageNo);

        // console.log('notelist.tsx.onClickPageIndexHandler.noteListInfo.curPageNo:', noteListInfo.curPageNo);

        // console.log('notelist.tsx.onClickPageIndexHandler.curPageNo:', curPageNo);
        // dispatch(setNoteListInfo({curPageNo: newPageNo, selectedNoteNo: null}));
        // dispatch(setNoteListInfo({"curPageNo": newPageNo, "selectedNoteNo": null}));
        dispatch(setNoteListInfo(newPageNo, null));

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            if(topicNo){
                formData.set("topicNo", topicNo.toString());
                formData.set("curPageNo", newPageNo.toString());
                formData.set("itemsPerPage", itemsPerPage.toString());

                fetch('/api/note/getnotes', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getnotesResBodyType) => {
                    if(resBody["success"] === true){
                        // storedNoteList.current = resBody["notes"];
                        setNotes(createNoteInfoLines(resBody["notes"]));
                        setPageIndexes(createPageIndex(resBody["pageIndex"]))
                        // console.log('notelist.tsx.onClickPageIndexHandler.curPageNo:', curPageNo);
                    }
                }).catch((error) => {
                    console.log("[Error]notelist.tsx.onClickPageIndexHandler.fetch('/api/note/getnotes'):", error);
                });
            }
            else{
                navigate('/home');
            }
        }
        else{
            navigate('/home');
        }
    }

    // function createNoteInfoLines(noteList: Array<{"noteNo": string, "title": string, "lastUpdate": string}>): JSX.Element {
    function createNoteInfoLines(noteList: Array<noteItemType>): JSX.Element {

        // console.log('notelist.tsx.createNoteInfoLines.curPageNo:', curPageNo);

        const noteListDiv = noteList.map((noteItem) => {
            // console.log('notelist.tsx.createNoteInfoLines.noteItem:', noteItem);
            return(
                <NoteListEntry noteNo={noteItem["noteNo"]} title={noteItem["title"]} lastUpdate={noteItem["lastUpdate"]} subjectTitle={topicTitle} key={noteItem["noteNo"]}></NoteListEntry>
            );
        });

        return(
            <div id="notelist_div">{noteListDiv}</div>
        );
    }

    // function createPageIndex(pageIndexData: {"firstPageNum"?: string, "prevPageNum"?: string, "curPageNum": string, "nextPageNum"?: string, "lastPageNum"?: string}){
    function createPageIndex(pageIndexes: pageIndexDataType){
        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
        const curPageNum: number = pageIndexes["curPageNum"];
        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPageNum && prevPageNum){
            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
        }
        if(lastPageNum && nextPageNum){
            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
        }

        return(
            <div id="page_index_div">
                {firstPageNum &&
                    <span id="notelist_page_index_first_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
                    </span>
                }
                {prevEllipsis && <span id="notelist_page_index_first_prev_ellipsis">{prevEllipsis}</span>}
                {prevPageNum &&
                    <span id="notelist_page_index_prev_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
                    </span>
                }
                <span id="notelist_page_index_cur_page">{curPageNum}</span>
                {nextPageNum &&
                    <span id="notelist_page_index_next_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
                    </span>
                }
                {nextEllipsis && <span id="notelist_page_index_next_last_ellipsis">{nextEllipsis}</span>}
                {lastPageNum &&
                    <span id="notelist_page_index_last_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
                    </span>
                }
            </div>
        );
    }

    function onClickDeleteTopicHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        setShowConfirmDeleteTopic(true);
    }

    function onClickConfirmDeleteTopicHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            if(topicNo){
                formData.set("topicNo", topicNo.toString());

                fetch('/api/topic/delete', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                //     return response.json();
                // }).then((resBody) => {
                    navigate('/topics');
                }).catch((error) => {
                    console.log('[Error]notelist.tsx.onClickConfirmDeleteTopicHandler:', error);
                });
            }
            else{
                navigate('/topic');
            }
        }
        else{
            navigate('/topic');
        }
    }

    function onClickCancelDeleteTopicHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        setShowConfirmDeleteTopic(false);
    }

    useEffect(() => {
        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            if(topicNo){
                formData.set("topicNo", topicNo.toString());
                formData.set("curPageNo", curPageNo.toString());
                formData.set("itemsPerPage", itemsPerPage.toString());

                fetch('/api/note/getnotes', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getnotesResBodyType) => {
                    if(resBody["success"] === true){
                        // storedNoteList.current = resBody["notes"];
                        setNotes(createNoteInfoLines(resBody["notes"]));
                        setPageIndexes(createPageIndex(resBody["pageIndex"]))
                    }
                }).catch((error) => {
                    console.log("[Error]notelist.tsx.useEffect.fetch('/api/note/getnotes'):", error);
                });
            }
            else{
                navigate('/home');
            }
        }
        else{
            navigate('/home');
        }
    }, [isLoggedIn]);

    return(
        <AppLayout>
            <div id="notelist_top_container_div">
                <div id="notelist_notelist_div">{notes}</div>
                <div id="notelist_page_index_div">{pageIndexes}</div>
                <Divider></Divider>
                <div id="notelist_button_div">
                {showConfirmDeleteTopic === false &&
                    <div id="notelist_create_note_btn_div">
                        <Button onClick={onClickBackButtonHandler}>
                            주제 목록
                        </Button>
                        <Button type="primary" onClick={onClickCreateNoteHandler}>
                            노트 작성
                        </Button>
                        <Button type="primary" danger onClick={onClickDeleteTopicHandler}>
                            주제 삭제
                        </Button>
                    </div>
                }
                {showConfirmDeleteTopic === true &&
                    <div id="notelist_delete_topic_btn_div">
                        <div id="confirm_delete_topic_message">
                            삭제 하시려는 주제의 글들도 함께 삭제됩니다.
                        </div>
                        <Button type="primary" danger onClick={onClickConfirmDeleteTopicHandler}>
                            삭제 확인
                        </Button>
                        <Button onClick={onClickCancelDeleteTopicHandler}>
                            삭제 취소
                        </Button>
                    </div>
                }
                </div>
            </div>
        </AppLayout>
    );
}

export default Notelist;

import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootStateType } from "..";

// import { noteListState } from "../reducers/note_list_info";
import { NoteListState } from "../features/note_list_info";
// import { prevPageState } from "../reducers/prev_page_info";
import { PrevPageState } from "../features/prev_page_info";

import MarkingDown from "../components/md_editor3p1_ems";

import FriendSearchResultEntry from "../components/friend_search_result_entry";

import { Button, Divider } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

import AppLayout from "../components/app_layout";

import "../css/note.css";
import "../css/md_editor3.css";


function Note(): JSX.Element {

    const navigate = useNavigate();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const userNo: number|null = useSelector<RootStateType, number|null>(state => state.auth.userNo);
    const noteNo = useSelector<RootStateType, number|null>(state => {
        let _selectedNoteNo = state.noteList.selectedNoteNo;

        if(_selectedNoteNo === null || _selectedNoteNo === undefined){
            const sessNoteListState: string|null = sessionStorage.getItem("noteListInfo");
            if(sessNoteListState){
                const parsedNoteListState: NoteListState = JSON.parse(sessNoteListState);
                _selectedNoteNo = parsedNoteListState["selectedNoteNo"];
            }
        }

        return _selectedNoteNo;
    });

    const prevPage = useSelector<RootStateType, string>(state => {
        let _prevPage = state.prevPageInfo.location;

        if(_prevPage === null || _prevPage === undefined){
            const sessPrevPageInfo = sessionStorage.getItem("prevPageInfo");
            if(sessPrevPageInfo){
                const parsedPrevPageInfo: PrevPageState = JSON.parse(sessPrevPageInfo);
                _prevPage = parsedPrevPageInfo["location"];
            }
        }

        return _prevPage;
    });


    const [title, setTitle] = useState<string>('');
    const [author, setAuthor] = useState<string>('');
    const [authorNo, setAuthorNo] = useState<number|null>(null);
    const [MDOutput, setMDOutput] = useState<{__html: string}>({__html: ''});
    const [lastUpdate, setLU] = useState<string>('');
    const [isConfirmDeletionComponentVisible, setIsConfirmDeletionComponentVisible] = useState<boolean>(false);
    const [isSharingComponentVisible, setIsSharingComponentVisible] = useState<boolean>(false);
    const [shareBtnLabel, setShareBtnLabel] = useState<string>('공유하기');
    // const [sharedList, setSharedList] = useState<Array<any>>([]);
    const sharedList = useRef<Array<number>>([]);
    const [hubInfoList, setHubInfoList] = useState<JSX.Element>(<div id="hub_info_list_div"></div>);
    const [pageIndexE, setPageIndexE] = useState<JSX.Element>(<div id="page_index_div"></div>);

    // let baseHubNo = useRef<number>(0);
    let curPageNo = useRef<number>(1);
    const hubsPerPage: number = 4;


    type hubInfoItemType = {
        hubNo: number
        , userNos: Array<number>
        , names: Array<string>
        , pics: Array<string>
    };

    type hubInfoListType = Array<hubInfoItemType>;

    function createHubInfoE(hubs: hubInfoListType, sharedHubList: Array<number>): JSX.Element {

        // hubs: [{"hubNo": hubNo, "userNos": participants, "names": participantNames, "pics": participantPics,}, ...]

        // sharedHubList: [hubNo0, hubNo1, hubNo2, ...]

        let hubInfoE = <div id="hub_info_list_div"></div>;

        if(hubs.length > 0){
            let hubInfoListE = hubs.map((hubInfo) => {
                let retE = <div id="hub_info_list_div"></div>;

                if(noteNo){
                    let hubNo = hubInfo["hubNo"];
                    let userNos = hubInfo["userNos"];
                    let names = hubInfo["names"];
                    let pics = hubInfo["pics"];
                    let isNoteShared = false;

                    for (const item of sharedHubList) {
                        if(item == hubNo){
                            isNoteShared = true;
                        }
                    }

                    retE = <FriendSearchResultEntry profPic={pics} names={names} noteNo={noteNo} hubNo={hubNo} isCurNoteShared={isNoteShared} key={hubNo}></FriendSearchResultEntry>;
                }

                return retE;
            });

            hubInfoE = 
            <div id="hub_info_list_div">
                <Divider></Divider>
                {hubInfoListE}
            </div>;
        }
        else{
            hubInfoE = <div id="hub_info_list_div">
                <div className="no_friend_msg_div">아직 노트를 공유할 친구가 없어요.</div>
                <div className="no_friend_msg_div">'친구목록'에서 친구를 찾고 등록해보세요.</div> 
            </div>;
        }

        return hubInfoE;
    }

    type pageIndexDataType = {
        firstPageNum?: number
        , prevPageNum?: number
        , curPageNum: number
        , nextPageNum?: number
        , lastPageNum?: number
    };

    function createPageIndex(pageIndexes: pageIndexDataType){
        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
        const curPageNum: number = pageIndexes["curPageNum"];
        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPageNum && prevPageNum){
            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
        }
        if(lastPageNum && nextPageNum){
            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
        }

        return(
            <div id="page_index_div">
                {firstPageNum &&
                    <span id="share_friend_page_index_first_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
                    </span>
                }
                {prevEllipsis && <span>{prevEllipsis}</span>}
                {prevPageNum &&
                    <span id="share_friend_page_index_prev_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
                    </span>
                }
                <span id="share_friend_page_index_cur_page">{curPageNum}</span>
                {nextPageNum &&
                    <span id="share_friend_page_index_next_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
                    </span>
                }
                {nextEllipsis && <span>{nextEllipsis}</span>}
                {lastPageNum &&
                    <span id="share_friend_page_index_last_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
                    </span>
                }
            </div>
        );
    }

    function onClickPrintNoteHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        window.print();
    }

    function onClickCancelDeletionBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        setIsConfirmDeletionComponentVisible(false);
    }

    function onClickConfirmDeletionBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            if(noteNo){
                headerData.set("authData", authToken);
                formData.set("noteNo", noteNo.toString());

                fetch('/api/note/delete', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    // return response.json();
                    setIsConfirmDeletionComponentVisible(false);
                    navigate('/notelist');
                // }).then((resBody) => {
                //     setIsConfirmDeletionComponentVisible(false);
                //     navigate('/notelist');
                }).catch((error) => {
                    console.log('[Error]note.tsx.onClickConfirmDeleteionBtnHandler:', error);
                });
            }
        }
    }

    function onClickBackBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        // navigate('/notelist');
        navigate(prevPage);
    }


    type sharegethubsResBodyType = {
        success: boolean
        , hubs: Array<hubInfoItemType>
        , pageIndex: pageIndexDataType
        , shared?: Array<number>
    }

    function onClickShareNoteBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        // console.log('note.tsx.onClickShareNoteBtnHandler.');

        //  toggle friends list component
        if(isSharingComponentVisible){
            setHubInfoList(<div id="hub_info_list_div"></div>);
            setPageIndexE(<div id="page_index_div"></div>);
            setIsSharingComponentVisible(!isSharingComponentVisible);
        }
        else{
            if(authToken){
                if(noteNo){
                    setIsConfirmDeletionComponentVisible(false);
                    setIsSharingComponentVisible(!isSharingComponentVisible);
                    curPageNo.current = 1;

                    let headerData = new Headers();
                    let formData = new FormData();

                    headerData.set("authData", authToken);

                    formData.set("noteNo", noteNo.toString());
                    formData.set("curPageNo", curPageNo.current.toString());
                    formData.set("hubsPerPage", hubsPerPage.toString());
                    formData.set("needSharedList", '1');

                    fetch('/api/share/gethubs', {
                        method: 'POST'
                        , headers: headerData
                        , body: formData
                    }).then((response) => {
                        return response.json();
                    }).then((resBody: sharegethubsResBodyType) => {
                        const shared: Array<number> = resBody["shared"] ? resBody["shared"] : [];

                        // setSharedList(resBody["shared"]);
                        sharedList.current = shared;
                        setHubInfoList(createHubInfoE(resBody["hubs"], shared));
                        setPageIndexE(createPageIndex(resBody["pageIndex"]));
                    }).catch((error) => {
                        console.log('[Error]note.tsx.onClickShareNoteBtnHandler:', error);
                    });
                }
            }
        }
    }

    function onClickPageIndexHandler(event: React.MouseEvent<HTMLElement>){
        event.preventDefault();

        const newPageNo: number = event.currentTarget.dataset.pageno ? parseInt(event.currentTarget.dataset.pageno) : 1;

        curPageNo.current = newPageNo;

        if(authToken){
            if(noteNo){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("noteNo", noteNo.toString());
                formData.set("curPageNo", curPageNo.current.toString());
                formData.set("hubsPerPage", hubsPerPage.toString());
                formData.set("needSharedList", '1');

                fetch('/api/share/gethubs', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: sharegethubsResBodyType) => {
                    const shared: Array<number> = resBody["shared"] ? resBody["shared"] : [];

                    // setHubInfoList(createHubInfoE(resBody["hubs"], sharedList));
                    // setHubInfoList(createHubInfoE(resBody["hubs"], sharedList.current));
                    sharedList.current = shared;
                    setHubInfoList(createHubInfoE(resBody["hubs"], shared));
                    setPageIndexE(createPageIndex(resBody["pageIndex"]))
                }).catch((error) => {
                    console.log('[Error]note.tsx.hubs.onClickPageIndexHandler:', error);
                });
            }
        }
    }

    function onClickUpdateNoteBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        navigate('/note/update');
    }

    function onClickDeleteNoteBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        setIsSharingComponentVisible(false);
        setIsConfirmDeletionComponentVisible(true);
    }

    function renderText(encodedText: string): string{

        // console.log('note.tsx.renderText.encodedText:', encodedText);

        let origText = decodeURIComponent(encodedText);

        const md = new MarkingDown();
        md.getData(origText);
        let mdResult = md.doMarkingDown();

        return mdResult;
    }

    useEffect(() => {
        if(isSharingComponentVisible){
            setShareBtnLabel('공유창 닫기');
        }
        else{
            setShareBtnLabel('공유하기');
        }
    }, [isSharingComponentVisible]);

    type getnoteResBodyType = {
        success: boolean
        , author: string
        , authorNo: number
        , title: string
        , text: string
        , lastUpdate: string
    };

    useEffect(() => {
        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            if(noteNo){
                headerData.set("authData", authToken);
                formData.set("noteNo", noteNo.toString());

                fetch('/api/note/get', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getnoteResBodyType) => {
                    if(resBody["success"] === true){
                        // console.log('note.tsx.useEffect.fetch(/api/note/get).resBody:', resBody);
                        // console.log('note.tsx.useEffect.fetch(/api/note/get).resBody.text:', resBody["text"]);
                        setTitle(resBody["title"]);
                        setAuthor(resBody["author"]);
                        setAuthorNo(resBody["authorNo"]);
                        setMDOutput({__html: renderText(resBody["text"])});
                        setLU(resBody["lastUpdate"]);
                    }
                }).catch((error) => {
                    console.log('[Error]note.tsx.useEffect(/api/note/get):', error);
                })
            }
        }
    }, []);

    return(
        <AppLayout>
            <div id="note_top_container_div">
                <div id="note_note_div">
                    <div id="note_title_div">{title}</div>
                    <Divider dashed></Divider>
                    <div id="note_desc_div">
                        <div id="editor_output">
                            <p id="note_MDOutput_p" dangerouslySetInnerHTML={MDOutput}></p>
                        </div>
                    </div>
                    <br/>
                    <div id="note_copyright_div">
                        ⓒ{author}
                    </div>
                    <div id="note_lastupdate_div">
                        {lastUpdate}
                    </div>
                </div>
                <div id="note_print_share_button_div">
                    <Button onClick={onClickPrintNoteHandler}><FileTextOutlined></FileTextOutlined>노트 인쇄하기</Button>
                    {/* {(parseInt(userNo) === authorNo) && */}
                    {userNo && authorNo && (userNo === authorNo) &&
                        <Button type="primary" onClick={onClickShareNoteBtnHandler}>{shareBtnLabel}</Button>
                    }
                </div>
                {hubInfoList}
                {pageIndexE}
                <Divider></Divider>
                {isConfirmDeletionComponentVisible && 
                    <div id="note_confirm_deletion_div">
                        <div>노트를 삭제하실 건가요?</div>
                        <Button onClick={onClickCancelDeletionBtnHandler}>취소</Button>
                        <Button type="primary" danger onClick={onClickConfirmDeletionBtnHandler}>삭제</Button>
                        <Divider></Divider>
                    </div>
                }
                {isSharingComponentVisible &&
                    <div id="note_sharing_div">
                    </div>
                }
                <div id="note_button_div">
                    <Button onClick={onClickBackBtnHandler}>목록으로</Button>
                    {/* {(parseInt(userNo) === authorNo) && */}
                    {userNo && authorNo && (userNo === authorNo) &&
                        <>
                            <Button onClick={onClickUpdateNoteBtnHandler}>이어서 쓰기</Button>
                            <Button type="primary" danger onClick={onClickDeleteNoteBtnHandler}>삭제</Button>
                        </>
                    }
                </div>
            </div>
        </AppLayout>
    );
}

export default Note;

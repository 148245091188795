import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';


export type PrevPageState = {
    location: string
};

const prevPageInitialState = {
    location: '/'
}

const prevPageInfoSlice = createSlice({
    name: 'prevPageInfo'
    , initialState: prevPageInitialState
    , reducers: {
        setPrevPageInfo: {
            reducer: (state, action: PayloadAction<PrevPageState>) => {
                state.location = action.payload.location;
            }
            , prepare: (location: string) => {
                return({payload: {location}});
            }
        }
        , resetPrevPageInfo: {
            reducer: (state, action: PayloadAction<PrevPageState>) => {
                state.location = action.payload.location;
            }
            , prepare: () => {
                return({payload: prevPageInitialState});
            }
        }
    }
});

export const { setPrevPageInfo, resetPrevPageInfo } = prevPageInfoSlice.actions;

export default prevPageInfoSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AgoraState = {
    curPageNo: number
};

const agoraInfoInitialState: AgoraState = {
    curPageNo: 1
}

const agoraInfoSlice = createSlice({
    name: 'agaoraInfo'
    , initialState: agoraInfoInitialState
    , reducers: {
        setAgoraInfo: {
            reducer: (state, action: PayloadAction<AgoraState>) => {
                state.curPageNo = action.payload.curPageNo;
            }
            , prepare: (curPageNo: number) => {
                return({payload: {curPageNo}});
            }
        }
        , resetAgoraInfo: {
            reducer: (state, action: PayloadAction<AgoraState>) => {
                state.curPageNo = action.payload.curPageNo;
            }
            , prepare: () => {
                return({payload: agoraInfoInitialState});
            }
        }
    }
});

export const { setAgoraInfo, resetAgoraInfo } = agoraInfoSlice.actions;

export default agoraInfoSlice.reducer;

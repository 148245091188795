import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
    authToken: string|null
    , userNo: number|null
    , username: string
    , userEmail: string
    , isLoggedIn: boolean
    , privilege: number|null
};

const authInfoInitialState: AuthState = {
    authToken: null
    , userNo: null 
    , username: ''
    , userEmail: ''
    , isLoggedIn: false
    , privilege: null 
};

export type SigninData = {
    email: string
    , password: string
};

type SigninUserInfo = {
    authToken: string|null
    , userNo: number|null
    , username: string
    , userEmail: string
    , privilege: number|null
};


const authInfoSlice = createSlice({
    name: 'authInfo'
    , initialState: authInfoInitialState
    , reducers: {
        signinUser: {
            reducer: (state, action: PayloadAction<AuthState>) => {
                state.authToken = action.payload.authToken;
                state.userNo = action.payload.userNo;
                state.username = action.payload.username;
                state.userEmail = action.payload.userEmail;
                state.privilege = action.payload.privilege;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: ({authToken, userNo, userEmail, username, privilege}: SigninUserInfo) => {
                if(authToken){
                    sessionStorage.setItem("authToken", authToken);
                }

                return({payload: {authToken, userNo, username, userEmail, privilege, isLoggedIn: true}});
            }
        }
        , signoutUser: {
            reducer: (state, action: PayloadAction<AuthState>) => {
                state.authToken = action.payload.authToken; 
                state.userNo = action.payload.userNo;
                state.username = action.payload.username;
                state.userEmail = action.payload.userEmail;
                state.privilege = action.payload.privilege;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: () => {
                sessionStorage.removeItem("authToken");
                return({payload: authInfoInitialState});
            }
        }
        , refreshAuth: {
            reducer: (state, action: PayloadAction<AuthState>) => {
                state.authToken = action.payload.authToken; 
                state.userNo = action.payload.userNo;
                state.username = action.payload.username;
                state.userEmail = action.payload.userEmail;
                state.privilege = action.payload.privilege;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: ({authenticated, authToken, userNo, userEmail, username, privilege}) => {
                if(authenticated === true){
                    return({payload: {authToken, userNo, userEmail, username, privilege, isLoggedIn: true}});
                }
                else{
                    return({payload: authInfoInitialState});
                }
            }
        }
    }
});

export const getAuth = async (data: SigninData) => {
    const response = await fetch('/api/user/signin', {
        method: 'POST',
        headers: {"content-type": 'application/json'},
        body: JSON.stringify(data)
    });

    return response.json(); //  response.json() returns Promise object
};

// export const getAuth = createAsyncThunk(
//     'user/getAuthThunk'
//     , async (data: SigninData) => {
//         const response = await fetch('/api/user/signin', {
//             method: 'POST',
//             headers: {"content-type": 'application/json'},
//             body: JSON.stringify(data)
//         });

//         return response.json(); //  response.json() returns Promise object
//     }
// );

export const checkAuth = async (authToken: string|null) => {
    const response = await fetch('/api/user/checksignin', {
        method: 'POST',
        headers: {"content-type": 'application/json'},
        // body: JSON.stringify(_authToken)
        body: JSON.stringify(authToken)
    });

    return response.json(); //  response.json() returns Promise object
};

// export const checkAuth = createAsyncThunk(
//     'user/checkAuthThunk'
//     , async (authToken: string|null) => {
//         const response = await fetch('/api/user/checksignin', {
//             method: 'POST',
//             headers: {"content-type": 'application/json'},
//             // body: JSON.stringify(_authToken)
//             body: JSON.stringify(authToken)
//         });

//         return response.json(); //  response.json() returns Promise object
//     }
// );

export const removeAuth = (authToken: string|null) => {
    if(authToken){
        fetch('/api/user/signout', {
            method: 'POST',
            headers: {"content-type": 'application/json'},
            body: JSON.stringify(authToken)
        }).then((response) => {
            return response.json();
        }).then((resBody) => {
            console.log('user_auth.tsx.removeAuth.authenticated:', resBody.authenticated);
            // return null;
        }).catch((error) => {
            console.log('[Error]user_auth.tsx.removeAuth():', error);
        });
    }
}

export const { signinUser, signoutUser, refreshAuth } = authInfoSlice.actions;

export default authInfoSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';


export type DiscussionListState = {
    curPageNo: number
};

const discussionListInfoInitialState: DiscussionListState = {
    curPageNo: 1
};

const discussionListInfoSlice = createSlice({
    name: 'discussionListInfo'
    , initialState: discussionListInfoInitialState
    , reducers: {
        setDiscussionListInfo: {
            reducer: (state, action: PayloadAction<DiscussionListState>) => {
                state.curPageNo = action.payload.curPageNo;
            }
            , prepare: (curPageNo: number) => {
                return({payload: {curPageNo}});
            }
        }
        , resetDiscussionListInfo: {
            reducer: (state, action: PayloadAction<DiscussionListState>) => {
                state.curPageNo = action.payload.curPageNo;
            }
            , prepare: () => {
                return({payload: discussionListInfoInitialState});
            }
        }
    }
});

export const { setDiscussionListInfo, resetDiscussionListInfo } = discussionListInfoSlice.actions;

export default discussionListInfoSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';


export type HubListState = {
    curPageNo: number
    , selectedHub: number|null
};

const hubListInitialState: HubListState = {
    curPageNo: 1
    , selectedHub: null
};

const hubListInfoSlice = createSlice({
    name: 'hubListInfo'
    , initialState: hubListInitialState
    , reducers: {
        setHubListInfo: {
            reducer: (state, action: PayloadAction<HubListState>) => {
                state.curPageNo = action.payload.curPageNo;
                state.selectedHub = action.payload.selectedHub;
            }
            , prepare: (curPageNo: number, selectedHub: number|null) => {
                const hubInfo = {"curPageNo": curPageNo, "selectedHub": selectedHub};
                const serializedData = JSON.stringify(hubInfo);
                sessionStorage.setItem("hubListInfo", serializedData);
                return({payload: {curPageNo, selectedHub}});
            }
        }
        , resetHubListInfo: {
            reducer: (state, action: PayloadAction<HubListState>) => {
                state.curPageNo = action.payload.curPageNo;
                state.selectedHub = action.payload.selectedHub;
            }
            , prepare: () => {
                sessionStorage.removeItem("hubListInfo");
                return({payload: hubListInitialState});
            }
        }
    }
});

export const { setHubListInfo, resetHubListInfo } = hubListInfoSlice.actions;

export default hubListInfoSlice.reducer;

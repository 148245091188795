import React, { useState } from "react";

import { useNavigate } from "react-router";

import { Image, Button } from 'antd';

import AppLayout from "../components/app_layout";

import "../css/lookaround_myinfo.css";


function LookaroundMyInfo(): JSX.Element {

    const navigate = useNavigate();

    const [imageSrc, setImageSrc] = useState<string>('/lookaround/prof_pics/saturn_493x352.png');

    const onClickHomeButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/home');
    };

    const onClickLookaroundCreateNoteButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/lookaround/createnote');
    };

    return(
        <AppLayout>
            <div id="lookaround_myinfo_top_container_div">
                <div id="lookaround_myinfo_image_div">
                    <Image src={imageSrc}/>
                </div>
                <div id="lookaround_myinfo_description_div">
                    <p>
                        고리부심이 있다.<br/>
                        목성이와 가까워 나이가 비슷할 것 같지만 한참 어리다.<br/>
                        무거울 것 같아 보이지만 몸이 가벼워 물에 잘 뜬다고 한다.<br/>
                        인터넷에서 멋진 프로필 사진들을 쉽게 찾을 수 있다.<br/>
                    </p>
                </div>
                <div id="lookaround_myinfo_button_div">
                    <Button onClick={onClickHomeButtonHandler}>돌아가기</Button>
                    <Button onClick={onClickLookaroundCreateNoteButtonHandler} type="primary">노트작성</Button>
                </div>            
            </div>
        </AppLayout>
    );
}

export default LookaroundMyInfo;

import React, { useEffect } from 'react';
import logo from './logo.svg';
import 'antd/dist/antd.min.css';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NavBar from './components/navbar';
import WithCheckAuth from './components/check_auth';
import AppFooter from './components/app_footer';

import Home from './views/home';
import Signup from './views/signup';
import Signin from './views/signin';
import ReqPWReset from './views/reqpwreset';
import MyInfo from './views/myinfo';


import { Layout } from 'antd';
import Topics from './views/topics';
import Notelist from './views/notelist';
import CreateNote from './views/create_note';
import Note from './views/note';
import UpdateNote from './views/update_note';
import Hubs from './views/hubs';
import Discussion from './views/discussion';
import Agora from './views/agora';
import ManageNotice from './views/manage_notice';
import LookaroundDiscussion from './views/lookaround_discussion';
import LookaroundNote from './views/lookaround_note';
import LookaroundMyInfo from './views/lookaround_myinfo';
import LookaroundCreateNote from './views/lookaround_create_note';


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const { Header, Content, Footer } = Layout;

function App() {
  return(
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Header>
            <NavBar></NavBar>
          </Header>
          <Content id="content_wrapper">
            <Routes>
              <Route path="/" element={
                <WithCheckAuth
                  InputComponent={Home}
                  loginRequired={false}
                  props={{message: 'test message'}}>
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/home" element={
                <WithCheckAuth
                  InputComponent={Home}
                  loginRequired={false}
                  props={{message: 'test message'}}>
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/user/signup" element={<Signup/>}></Route>
              <Route path="/user/signin" element={<Signin/>}></Route>
              <Route path="/user/reqpwreset" element={<ReqPWReset/>}></Route>
              <Route path="/user/myinfo" element={
                  <WithCheckAuth
                      InputComponent={MyInfo}
                      loginRequired={true}>
                  </WithCheckAuth>}>
              </Route> 
              <Route path="/topics" element={
                <WithCheckAuth
                  InputComponent={Topics}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/notelist" element={
                <WithCheckAuth
                  InputComponent={Notelist}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/note/create" element={
                <WithCheckAuth
                  InputComponent={CreateNote}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/note" element={
                <WithCheckAuth
                  InputComponent={Note}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/note/update" element={
                <WithCheckAuth
                  InputComponent={UpdateNote}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/friends" element={
                <WithCheckAuth
                  InputComponent={Hubs}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/discussion" element={
                <WithCheckAuth
                  InputComponent={Discussion}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/agora" element={
                <WithCheckAuth
                  InputComponent={Agora}
                  loginRequired={false}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/managenotice" element={
                <WithCheckAuth
                  InputComponent={ManageNotice}
                  loginRequired={true}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/lookaround/discussion" element={
                <WithCheckAuth
                  InputComponent={LookaroundDiscussion}
                  loginRequired={false}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/lookaround/note" element={
                <WithCheckAuth
                  InputComponent={LookaroundNote}
                  loginRequired={false}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/lookaround/myinfo" element={
                <WithCheckAuth
                  InputComponent={LookaroundMyInfo}
                  loginRequired={false}
                >
                </WithCheckAuth>
              }>
              </Route>
              <Route path="/lookaround/createnote" element={
                <WithCheckAuth
                  InputComponent={LookaroundCreateNote}
                  loginRequired={false}
                >
                </WithCheckAuth>
              }>
              </Route>
            </Routes>
          </Content>
          <Footer>
            <AppFooter></AppFooter>
          </Footer>
        </Layout>
      </BrowserRouter>
    </div>
  )
}

export default App;

import React, { useEffect, useState } from "react";

import { Row, Col, Divider } from 'antd';

import "../css/notice.css";

type NoticePropTypes = {
    reportItemLength: Function
};

function Notice(props: NoticePropTypes): JSX.Element
{
    let [notices, setNotices] = useState<Array<JSX.Element>>([]);


    type noticeItemType = {
        noticeNo: number
        , content: string
        , timeCreated: string
    };

    function displayNotice(itemList: Array<noticeItemType>): Array<JSX.Element> {
        const contents = itemList.map((item) => {
            return(
                <Row className="notice_item_div" key={item["noticeNo"]}>
                    <Col
                        xs={{span: 18}} sm={{span: 18}} md={{span: 16}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}               
                    >
                        {item["content"]}
                    </Col>
                    <Col
                        xs={{span: 6}} sm={{span: 6}} md={{span: 8}}
                        lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}               
                    >
                        {item["timeCreated"]}
                    </Col>
                </Row>
            );
        });

        return contents;
    }

    type agoraGetNoticesResBodyType = {
        success: boolean
        , itemList: Array<noticeItemType>
    }

    useEffect(() => {

        let headerData = new Headers();

        fetch('/api/agora/getnotices', {
            method: 'GET',
            headers: headerData
        }).then((response) => {
            return response.json();
        }).then((resBody: agoraGetNoticesResBodyType) => {
            // console.log('Notice.useEffect.resBody:', resBody);
            if(resBody.success === true){
                setNotices(displayNotice(resBody.itemList));
                props.reportItemLength(resBody.itemList.length);
            }
            else{
                props.reportItemLength(0);
            }
        }).catch((error) => {
            console.log('[Error]Notice.useEffect.fetch:', error);
        })
    }, []);

    useEffect(() => {
        const noticeDiv = document.getElementById('notice_div');

        if(noticeDiv){
            const noticeDivHeightInPx = `${noticeDiv.scrollHeight}px`;

            // console.log('Notice.tsx.useEffect.noticeDivHeightInPx:', noticeDivHeightInPx);

            noticeDiv.style.height = noticeDivHeightInPx;
        }
    }, [notices])

    return(
        <div id="notice_div" className="hide">
            {notices}
            {notices.length > 0 &&
                <Divider dashed/>
            }
        </div>
    )

}

export default Notice;

import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatchType, RootStateType } from "..";
// import { hubListState } from "../reducers/hub_list_info";
import { HubListState } from "../features/hub_list_info";
// import { discussionListState, setDiscussionListInfo } from "../reducers/discussion_list_info";
import { DiscussionListState, setDiscussionListInfo } from "../features/discussion_list_info";

// import { noteListState, setNoteListInfo } from "../reducers/note_list_info";
import { NoteListState, setNoteListInfo } from "../features/note_list_info";
// import { setPrevPageInfo, prevPageState } from "../reducers/prev_page_info";
import { setPrevPageInfo, PrevPageState } from "../features/prev_page_info";

import { Row, Col, Divider, Button, Collapse, Avatar, Image } from "antd";

import AppLayout from "../components/app_layout";

import "../css/discussion.css";


function Discussion(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    // const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const selectedHubNo: number|null = useSelector<RootStateType, number|null>(state => {
        let _selectedHubNo = state.hubList.selectedHub;

        if(_selectedHubNo === null || _selectedHubNo === undefined){
            const sessHubListInfo = sessionStorage.getItem("hubListInfo");

            if(sessHubListInfo){
                const _hubListInfo: HubListState = JSON.parse(sessHubListInfo);
                _selectedHubNo = _hubListInfo.selectedHub;
            }
        }

        return _selectedHubNo;
    });

    const curNoteListInfo = useSelector<RootStateType, NoteListState>(state => {
        let _curNoteListInfo = state.noteList;

        if(_curNoteListInfo === null || _curNoteListInfo === undefined){
            const sessNoteListInfo = sessionStorage.getItem("noteListInfo");
            if(sessNoteListInfo){
                const parsedNoteListInfo: NoteListState = JSON.parse(sessNoteListInfo);
                _curNoteListInfo = parsedNoteListInfo;
            }
        }

        return _curNoteListInfo;
    });

    const curPageNo: number = useSelector<RootStateType, number>(state => {
        let _curPageNo = state.dicussionList.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessDiscussionListInfo = sessionStorage.getItem("discussionListInfo");
            if(sessDiscussionListInfo){
                const _discussionListInfo: DiscussionListState = JSON.parse(sessDiscussionListInfo);
                _curPageNo = _discussionListInfo.curPageNo;
            }
        }

        return _curPageNo;
    });

    const { Panel } = Collapse;

    const [notesE, setNotesE] = useState<JSX.Element[]>([]);
    const [pageIndexesE, setPageIndexesE] = useState<JSX.Element>(<div></div>);
    
    // let curPageNo = useRef<number>(1);
    const numberOfItemsPerPage = 9;

    const prevPage = useSelector<RootStateType, string>(state => {
        let _prevPage = state.prevPageInfo.location;

        if(_prevPage === null || _prevPage === undefined){
            const sessPrevPageInfo = sessionStorage.getItem("prevPageInfo");
            if(sessPrevPageInfo){
                const parsedPrevPageInfo: PrevPageState = JSON.parse(sessPrevPageInfo);
                _prevPage = parsedPrevPageInfo["location"];
            }
        }

        return _prevPage;
    });

    const [friendsE, setFriendsE] = useState<JSX.Element>(<div></div>);
    const [friendsETitle, setFriendsETitle] = useState<string>("이 방의 친구들");


    function onClickNoteHandler(event: React.MouseEvent<HTMLDivElement>){
        event.preventDefault();

        const noteNo = event.currentTarget.dataset.noteno;

        if(noteNo){
            // dispatch(setNoteListInfo({...curNoteListInfo, "selectedNoteNo": parseInt(noteNo)}));
            dispatch(setNoteListInfo(curPageNo, parseInt(noteNo)));
            dispatch(setPrevPageInfo('/discussion'));
            navigate('/note');
        }
    }

    type NoteItemType = {
        noteNo: number
        , isAuthor: boolean
        , subjectTitle: string
        , articleTitle: string
        , writerName: string
        , profPic: string
        // , timeCreated: string
        , lastUpdate: string
    };

    function createItemsElement(notes: NoteItemType[]) {
            
        // console.log('discussion.tsx.createItemsElement().notes:', notes);

        let items = notes.map((item: NoteItemType) => {

            if(item.isAuthor === true){
                return(
                    // <div className="discussion_note_right_align" key={item.noteNo}>
                    //     <div className="discussion_note_left_spacer"></div>
                    //     <div className="time_created_div"><span className="time_created">{item.lastUpdate}</span></div>
                    //     <div className="True" onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                    //         {/* {item.title} */}
                    //         {item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}
                    //         &nbsp;--&nbsp;
                    //         {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                    //     </div>
                    // </div>
                    <Row className="discussion_note_right_align" key={item.noteNo}>
                        <Col className="discussion_note_left_spacer"
                            xs={{span: 2}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col className="True" onClick={onClickNoteHandler} data-noteno={item.noteNo}
                            xs={{span: 18}} sm={{span: 18}} md={{span: 18}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <div className="title_div">
                                [{item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}]
                                &nbsp;
                                {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                            </div>
                            <div className="time_div">
                                {item.lastUpdate}
                            </div>
                        </Col>
                        <Col className="discussion_note_profile_pic"
                            xs={{span: 4}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        <span>
                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={item.profPic} alt="profile pic author"/>}>
                            </Avatar>
                        </span>
                        </Col>
                    </Row>
                );
                // return(
                //     <div className="discussion_note_mine" key={item.noteNo} onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                //         <Row className="discussion_mine_header_row">
                //             <Col className="discussion_note_writer_div"
                //                 xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
                //                 lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                //             >
                //                 작성자: {item.writerName}
                //             </Col>
                //             <Col className="discussion_note_timecreated_div"
                //                 xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                //                 lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                //             >
                //                 {/* {item.timeCreated} */}
                //                 최종 수정: {item.lastUpdate}
                //             </Col>
                //         </Row>
                //         <Row className="discussion_mine_title_row">
                //             <Col className="discussion_note_subject_title_div"
                //                 xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                //                 lg={{span: 5}} xl={{span: 5}} xxl={{span: 5}}               
                //             >   
                //                 {item.subjectTitle ? item.subjectTitle : '노트 주제가 없습니다'}
                //             </Col>
                //             <Col className="discussion_note_title_hypen_div"
                //                 xs={{span: 2}} sm={{span: 2}} md={{span: 2}}
                //                 lg={{span: 1}} xl={{span: 1}} xxl={{span: 1}}               
                //             >   
                //                 &nbsp;-&nbsp;
                //             </Col>
                //             <Col className="discussion_note_article_title_div"
                //                 xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                //                 lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                //             >   
                //                 {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                //             </Col>
                //         </Row>
                //     </div>
                // );
            }
            else{
                return(
                    // <div className="discussion_note_left_align" key={item.noteNo}>
                    //     <div className="False" onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                    //         {/* {item.title} */}
                    //         {item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}
                    //     </div>
                    //     <div className="discussion_note_right_spacer"></div>
                    // </div>
                    <Row className="discussion_note_left_align" key={item.noteNo}>
                        <Col className="discussion_note_profile_pic"
                            xs={{span: 4}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        <span>
                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={item.profPic} alt="profile pic author"/>}>
                            </Avatar>
                        </span>
                        </Col>
                        <Col className="False" onClick={onClickNoteHandler} data-noteno={item.noteNo}
                            xs={{span: 18}} sm={{span: 18}} md={{span: 18}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <div className="title_div">
                                [{item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}]
                                &nbsp;
                                {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                            </div>
                            <div className="time_div">
                                {item.lastUpdate}
                            </div>
                        </Col>
                        <Col className="discussion_note_right_spacer"
                            xs={{span: 2}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                );
                // return(
                //     <div className="discussion_note_friends" key={item.noteNo} onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                //         <Row className="discussion_friends_header_row">
                //             <Col className="discussion_note_writer_div"
                //                 xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
                //                 lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                //             >
                //                 작성자: {item.writerName}
                //             </Col>
                //             <Col className="discussion_note_timecreated_div"
                //                 xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                //                 lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                //             >
                //                 {/* {item.timeCreated} */}
                //                 최종 수정: {item.lastUpdate}
                //             </Col>
                //         </Row>
                //         <Row className="discussion_friends_title_row">
                //             <Col className="discussion_note_subject_title_div"
                //                 xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                //                 lg={{span: 5}} xl={{span: 5}} xxl={{span: 5}}               
                //             >   
                //                 {item.subjectTitle ? item.subjectTitle : '노트 주제가 없습니다'}
                //             </Col>
                //             <Col className="discussion_note_title_hypen_div"
                //                 xs={{span: 2}} sm={{span: 2}} md={{span: 2}}
                //                 lg={{span: 1}} xl={{span: 1}} xxl={{span: 1}}               
                //             >   
                //                 &nbsp;-&nbsp;
                //             </Col>
                //             <Col className="discussion_note_article_title_div"
                //                 xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                //                 lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                //             >   
                //                 {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                //             </Col>
                //         </Row>
                //     </div>
                // );
            }
        });

        // console.log('discussion.tsx.createItemsElement().items:', items);

        return items;
    }

    type pageIndexDataType = {
        firstPageNum?: number
        , prevPageNum?: number
        , curPageNum: number
        , nextPageNum?: number
        , lastPageNum?: number
    };

    type getsharednotesResBodyType = {
        success: boolean
        , notes: Array<NoteItemType>
        , pageIndex: pageIndexDataType
    };

    function onClickPageIndexHandler(event: React.MouseEvent<HTMLAnchorElement>){
        event.preventDefault();

        const newPageNo = event.currentTarget.dataset.pageno;

        if(newPageNo){
            // curPageNo.current = parseInt(newPageNo);
            dispatch(setDiscussionListInfo(parseInt(newPageNo)));
        }

        if(authToken){
            if(selectedHubNo && newPageNo){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("selectedHubNo", selectedHubNo.toString());
                formData.set("curPageNo", newPageNo);
                formData.set("numberOfItemsPerPage", numberOfItemsPerPage.toString());
            
                fetch('/api/discussion/getsharednotes', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getsharednotesResBodyType) => {
                    if(resBody["success"] === true){
                        setNotesE(createItemsElement(resBody["notes"]));
                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
                    }
                }).catch((error) => {
                    console.log('[Error]discussion.tsx.onClickPageIndexHandler.fetch(/api/discussion/getnotes):', error);
                });
            }
        }
    }

    function createPageIndex(pageIndexes: pageIndexDataType){
        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
        const curPageNum: number = pageIndexes["curPageNum"];
        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPageNum && prevPageNum){
            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
        }
        if(lastPageNum && nextPageNum){
            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
        }

        return(
            <div id="discussion_page_index_div">
                {firstPageNum &&
                    <span id="discussion_page_index_first_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
                    </span>
                }
                {prevEllipsis && <span>{prevEllipsis}</span>}
                {prevPageNum &&
                    <span id="discussion_page_index_prev_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
                    </span>
                }
                <span id="discussion_page_index_cur_page">{curPageNum}</span>
                {nextPageNum &&
                    <span id="discussion_page_index_next_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
                    </span>
                }
                {nextEllipsis && <span>{nextEllipsis}</span>}
                {lastPageNum &&
                    <span id="discussion_page_index_last_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
                    </span>
                }
            </div>
        );
    }

    function onClickBackToHubListHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        // console.log('discussion.tsx.onClickBackToHubListHandler.prevPage:', prevPage);

        navigate('/friends');
        // navigate(prevPage);
    }

    // function onClickCreateNoteHandler(event: React.MouseEvent<HTMLButtonElement>) {
    //     event.preventDefault();

    //     navigate('/note/create');
    // }

    useEffect(() => {

        if(authToken){
            if(selectedHubNo){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("selectedHubNo", selectedHubNo.toString());
                formData.set("curPageNo", curPageNo.toString());
                formData.set("numberOfItemsPerPage", numberOfItemsPerPage.toString());
            
                fetch('/api/discussion/getsharednotes', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getsharednotesResBodyType) => {
                    if(resBody["success"] == true){
                        setNotesE(createItemsElement(resBody["notes"]));
                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
                    }
                }).catch((error) => {
                    console.log('[Error]discussion.tsx.useEffect.fetch(/api/discussion/getnotes):', error);
                });
            }
        }
    }, []);

    type participantsType = {
        matchingNo: number
        , userNo: number
        , userName: string
        , profPic: string
    };

    type gethubinfoResBodyType = {
        success: boolean
        , participants: Array<participantsType>
    }

    useEffect(() =>{
        if(authToken){
            if(selectedHubNo){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("hubNo", selectedHubNo.toString());

                fetch('/api/friends/gethubinfo', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: gethubinfoResBodyType) => {
                    // console.log('discussion.tsx.useEffect.fetch(/api/friends/gethubinfo).resBody:', resBody);
                    if(resBody["success"] === true){
                        const participants: Array<participantsType> = resBody["participants"];
                        const participantsArrLen = participants.length;
                        let friendsNames: string = '';

                        participants.forEach((participant, index) => {
                            friendsNames = friendsNames.concat(participant["userName"]);
                            if (index < participantsArrLen - 1){
                                friendsNames = friendsNames.concat(', ');
                            }
                        });

                        // console.log('discussion.tsx.useEffect.fetch(/api/friends/gethubinfo).friendsNames:', friendsNames);

                        setFriendsE(<div>{friendsNames}</div>);
                        setFriendsETitle(`이 방의 친구들(${participantsArrLen}명)`);
                    }
                    else{
                        setFriendsE(<div>[오류] 친구 목록을 가져올 수 없습니다.</div>);
                        setFriendsETitle('친구 목록을 가져올 수 없습니다.');
                    }
                }).catch((error) => {
                    console.log('[Error]discussion.tsx.useEffect.fetch(/api/friends/gethubinfo):', error);
                })
            }
        }
    }, []);

    return(
        <AppLayout>
            <div id="discussion_top_container_div">
                <Row>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 22}}
                        lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    >
                        <div id="discussion_friend_list_div">
                            <Collapse>
                                <Panel header={friendsETitle} key={0}>
                                    {friendsE}
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                </Row>
                <Row>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 22}}
                        lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    >
                        <div id="discussion_note_list_div">
                            {notesE}
                        </div>
                    </Col>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                </Row>
                <Row>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    ></Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                    >
                        <div id="discussion_note_page_index_div">
                            {pageIndexesE}
                        </div>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    ></Col>
                </Row>
                <Divider/>
                <div id="discussion_buttons_div">
                    <Button type="default" onClick={onClickBackToHubListHandler}>친구목록</Button> 
                    {/* <Button type="primary" onClick={onClickCreateNoteHandler}>노트작성</Button>  */}
                </div>

            </div>
        </AppLayout>
    );
}

export default Discussion;

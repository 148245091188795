import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RootStateType } from "..";
// import { noteListState } from "../reducers/note_list_info";
import { NoteListState } from "../features/note_list_info";
import MarkingDown from "../components/md_editor3p1_ems";
import MDSyntaxInfo from "../components/md_syntax_info";

import { Divider, Input, Collapse, Button, Switch } from "antd";

import AppLayout from "../components/app_layout";

import "../css/update_note.css";
import "../css/md_editor3.css";


function UpdateNote(): JSX.Element {

    const navigate = useNavigate();

    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const noteNo = useSelector<RootStateType, number|null>(state => {
        let _selectedNoteNo = state.noteList.selectedNoteNo;

        if(_selectedNoteNo === null || _selectedNoteNo === undefined){
            const sessNoteListState: string|null = sessionStorage.getItem("noteListInfo");
            if(sessNoteListState){
                const parsedNoteListState: NoteListState = JSON.parse(sessNoteListState);
                _selectedNoteNo = parsedNoteListState["selectedNoteNo"];
            }
        }

        return _selectedNoteNo;
    });

    const { TextArea } = Input;
    const { Panel } = Collapse;

    const titleRef = useRef<HTMLDivElement>(null);
    const [title, setTitle] = useState<string>('');
    const [titleMsg, setTitleMsg] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [MDOutput, setMDOutput] = useState<{__html: string}>({__html: ''});

    const [audioNameElement, setAudioNameE] = useState<JSX.Element>(<span></span>);
    const [audioControlComponent, setAudioControlC] = useState<JSX.Element>(<div></div>);

    const [submitMsg, setSubmitMsg] = useState<string|JSX.Element>('저장');
    const [onUploading, setOnUploading] = useState<boolean>(false);

    const [updateMsg, setUpdateMsg] = useState<string>('');

    const [periodicAutoUpdate, setPAU] = useState<boolean>(false);
    const autoUpdateTimerID = useRef<number>(-1);
    const autoUpdateCallback = useRef<Function>(autoUpdate);

    function checkInputData(): boolean {
        let chkResult = false;

        // check title length
        if(title.length > 0 && title.length <= 40){
            setTitleMsg('');
            chkResult = true;
        }
        else{
            setTitleMsg('제목을 40글자 이하로 입력해주세요.');
            if(titleRef.current){
                titleRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }

        return chkResult;
    }

    function renderMDOutput(text: string) {
        const md = new MarkingDown();
        // md.getData(desc);
        md.getData(text);
        let mdResult = md.doMarkingDown();

        // setMDOutput({__html: mdResult});
        return mdResult;
    }    

    function onChangeTitleHandler(event: React.ChangeEvent<HTMLInputElement>){
        event.preventDefault();
        setTitle(event.currentTarget.value);
    }

    function onChangeDescHandler(event: React.ChangeEvent<HTMLTextAreaElement>){
        event.preventDefault();
        setDesc(event.currentTarget.value);
    }

    function onClickCancelUpdateBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        navigate('/note');
    }

    function onClickSubmitBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        let chkResult= checkInputData();

        if(chkResult){
            if(authToken){
                if(noteNo){
                    let headerData = new Headers();
                    let formData = new FormData();

                    headerData.set("authData", authToken);
                
                    formData.set("noteNo", noteNo.toString());
                    formData.set("title", title);
                    // formData.set("text", desc);
                    formData.set("text", encodeURIComponent(desc));

                    fetch('/api/note/update', {
                        method: 'POST'
                        , headers: headerData
                        , body: formData
                    }).then((response) => {
                        // navigate('/note');
                        return response.json();
                    }).then((resBody) => {
                        if(resBody["success"] === true){
                            const currentDate = new Date();
                            const currentHour = currentDate.getHours();
                            const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                            const currentMin = currentDate.getMinutes();
                            const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                            const currentSec = currentDate.getSeconds();
                            const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                            const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 저장되었습니다.`;
                            setUpdateMsg(currentTimeMsg);
                        }
                    }).catch((error) => {
                        console.log('[Error]update_note.tsx.onClickSubmitBtnHandler:', error);
                    });
                }
            }
        }
    }

    function autoUpdate() {
        let chkResult= checkInputData();

        if(chkResult){
            if(authToken){
                if(noteNo){
                    let headerData = new Headers();
                    let formData = new FormData();

                    headerData.set("authData", authToken);
                
                    formData.set("noteNo", noteNo.toString());
                    formData.set("title", title);
                    // formData.set("text", desc);
                    formData.set("text", encodeURIComponent(desc));

                    // console.log('update_note.tsx.uesEffect.periodicAutoUpdate.desc:', desc);

                    fetch('/api/note/update', {
                       method: 'POST'
                       , headers: headerData
                       , body: formData
                    }).then((response) => {
                       // navigate('/note');
                       return response.json();
                    }).then((resBody) => {
                       if(resBody["success"] === true){
                           const currentDate = new Date();
                           const currentHour = currentDate.getHours();
                           const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                           const currentMin = currentDate.getMinutes();
                           const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                           const currentSec = currentDate.getSeconds();
                           const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                           const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 자동 저장되었습니다.`;
                           setUpdateMsg(currentTimeMsg);
                       }
                    }).catch((error) => {
                       console.log('[Error]update_note.tsx.useEffect.periodicAutoUpdate:', error);
                    });
                    
                    // const currentDate = new Date();
                    // const currentHour = currentDate.getHours();
                    // const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                    // const currentMin = currentDate.getMinutes();
                    // const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                    // const currentSec = currentDate.getSeconds();
                    // const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                    // const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 자동 저장되었습니다.`;
                    // setUpdateMsg(currentTimeMsg);
                }
            }
        }
    }

    // a custom hook for setInterval
    // function useInterval(callback: Function, delay: number) {
    //     const savedCallback = useRef<Function>();
    //     let id: number = -1;

    //     useEffect(() => {
    //         savedCallback.current = callback;
    //     }, [callback]);

    //     useEffect(() => {
    //         function tick() {
    //             if(savedCallback.current){
    //                 savedCallback.current();
    //             }
    //         }

    //         if(delay != null){
    //             id = window.setInterval(tick, delay);
    //             return () => {
    //                 console.log('update_note.tsx.useInterval.clearInterval.id:', id);
    //                 window.clearInterval(id);
    //             }
    //         }
    //     }, [delay]);

    //     return id;
    // }

    function onChangePAUSwitch(checked: boolean){
        setPAU(checked);
    }

    useEffect(() => {
        setMDOutput({__html: renderMDOutput(desc)});
    }, [desc]);

    useEffect(() => {
        autoUpdateCallback.current = autoUpdate;
        // console.log('update_note.tsx.useEffect.autoUpdateCallback:', autoUpdate);
    }, [autoUpdate]);

    useEffect(() => {
        function autoUpdater() {
            autoUpdateCallback.current();
        }

        if(periodicAutoUpdate === true) {
            setUpdateMsg('1분 주기로 노트를 저장합니다.');

            const autoUpdateInterval = 60000;  //  1min in ms
            // const autoUpdateInterval = 5000;  //  5sec in ms - for test

            // autoUpdateTimerID.current = window.setInterval(() => {
            //     if(autoUpdateCallback.current){
            //         autoUpdateCallback.current();
            //     }
            // }, autoUpdateInterval);

            autoUpdateTimerID.current = window.setInterval(autoUpdater, autoUpdateInterval);
        }
        else{
            if(autoUpdateTimerID.current > 0){
                window.clearInterval(autoUpdateTimerID.current);
                autoUpdateTimerID.current = -1;
                setUpdateMsg('자동 저장이 해제되었습니다.');
            }
        }
    }, [periodicAutoUpdate]);

    type getnoteResBodyType = {
        success: boolean
        , author: string
        , authorNo: number
        , title: string
        , text: string
        , lastUpdate: string
    };

    useEffect(() => {
        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            if(noteNo){
                headerData.set("authData", authToken);
                formData.set("noteNo", noteNo.toString());

                fetch('/api/note/get', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: getnoteResBodyType) => {
                    if(resBody["success"] === true){
                        // console.log('update_note.tsx.useEffect.fetch(api/note/get).resBody:', resBody);
                        // console.log('update_note.tsx.useEffect.fetch(api/note/get).resBody.text:', resBody["text"]);
                        setTitle(resBody["title"]);
                        setDesc(decodeURIComponent(resBody["text"]));
                        setMDOutput({__html: renderMDOutput(resBody["text"])});
                    }
                }).catch((error) => {
                    console.log('[Error]update_note.tsx.useEffect(/api/note/get):', error);
                });
            }
        }

        return function updateNoteCleanUp() {
            // console.log('update_note.tsx.updateNoteCleanUp() called.');

            if(autoUpdateTimerID.current > 0){
                window.clearInterval(autoUpdateTimerID.current);
                // console.log('update_note.tsx.updateNoteCleanUp().autoUpdateTimerID.current:', autoUpdateTimerID.current);
            }
        }
    }, []);

    return(
        <AppLayout>
            <div id="update_note_top_container_div">
                <div id="update_note_title_div" ref={titleRef}>
                    <Input placeholder="제목" maxLength={40} showCount={true} allowClear value={title} onChange={onChangeTitleHandler}></Input>
                </div>
                <div id="update_note_title_msg_div">{titleMsg}</div>
                <Divider></Divider>
                <div id="update_note_desc_input_div">
                    <TextArea showCount maxLength={5000} onChange={onChangeDescHandler} value={desc} autoSize={true}></TextArea>
                </div>
                <div id="update_note_desc_auxiliary_div">
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="마크다운 결과 보기" key="1">
                        {/* <Panel header="마크다운 결과 보기" id="editor_output" key="1"> */}
                            {/* <p id="update_note_MDOutput_p" dangerouslySetInnerHTML={MDOutput}></p> */}
                            <p id="editor_output" dangerouslySetInnerHTML={MDOutput}></p>
                        </Panel>
                        <Panel header="마크다운 문법 참고" key="2">
                            <MDSyntaxInfo></MDSyntaxInfo>
                        </Panel>
                    </Collapse>
                </div>
                <Divider></Divider>
                {/* <div id="update_note_audio_div">
                    {audioNameElement}
                    {audioControlComponent}
                    <Divider></Divider>
                </div> */}
                <div id="update_note_update_msg_div">
                    {updateMsg.length === 0 ? <span>&nbsp;</span> : updateMsg}
                </div>
                <div id="update_note_button_div">
                    자동 저장&nbsp;<Switch onChange={onChangePAUSwitch}/>
                    <Button type="primary" disabled={onUploading} htmlType="submit" onClick={onClickSubmitBtnHandler}>
                        {submitMsg}
                    </Button>
                    <Button onClick={onClickCancelUpdateBtnHandler}>나가기</Button>
                </div>
            </div>
        </AppLayout>
    );
}

export default UpdateNote;

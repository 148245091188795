import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatchType, RootStateType } from "..";
// import { hubListState, setHubListInfo } from "../reducers/hub_list_info";
import { HubListState, setHubListInfo } from "../features/hub_list_info";
// import { setPrevPageInfo } from "../reducers/prev_page_info";
import { setPrevPageInfo } from "../features/prev_page_info";

import { Row, Col, Input, Divider, Avatar, Image, Badge, Button } from "antd";

import AppLayout from "../components/app_layout";

import "../css/hubs.css";
import { isShorthandPropertyAssignment } from "typescript";
import { TypeofTypeAnnotation } from "@babel/types";

//function Hubs(): JSX.Element {
//
//    const dispatch = useDispatch<AppDispatchType>();
//    const navigate = useNavigate();
//
//    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
//    const userNo = useSelector<RootStateType, number|null>(state => state.auth.userNo);
//    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
//        let _authToken = state.auth.authToken;
//
//        if(_authToken === null || _authToken === undefined){
//            const sessAuthToken = sessionStorage.getItem("authToken");
//            if(sessAuthToken){
//                _authToken = sessAuthToken;
//            }
//        }
//
//        return _authToken;
//    });
//
//    // const curHubListInfo = useSelector<RootStateType, HubListState>(state => {
//    //     let _curHubListInfo = state.hubList;
//
//    //     if(_curHubListInfo === null || _curHubListInfo === undefined){
//    //         const sessHubListInfo = sessionStorage.getItem("hubListInfo");
//    //         if(sessHubListInfo){
//    //             const parsedHubListInfo: HubListState = JSON.parse(sessHubListInfo);
//    //             _curHubListInfo = parsedHubListInfo;
//    //         }
//    //     }
//
//    //     return _curHubListInfo;
//    // });
//
//    // let curPageNo = useRef<number>(1);
//
//    const curPageNo = useSelector<RootStateType, number>(state => {
//        let _curPageNo = state.hubList.curPageNo;
//
//        if(_curPageNo === null || _curPageNo === undefined){
//            const sessHubListInfo = sessionStorage.getItem("hubListInfo");
//            if(sessHubListInfo){
//                const parsedHubListInfo: HubListState = JSON.parse(sessHubListInfo);
//                _curPageNo = parsedHubListInfo["curPageNo"];
//            }
//        }
//
//        return _curPageNo;
//    });
//
//    const selecteHub = useSelector<RootStateType, number|null>(state => {
//        let _selectedHub = state.hubList.selectedHub;
//
//        if(_selectedHub === null || _selectedHub === undefined){
//            const sessHubListInfo = sessionStorage.getItem("hubListInfo");
//            if(sessHubListInfo){
//                const parsedHubListInfo: HubListState = JSON.parse(sessHubListInfo);
//                _selectedHub = parsedHubListInfo["selectedHub"];
//            }
//        }
//
//        return _selectedHub;
//    });
//
//
//    type hubElementType = {
//        hubNo: number 
//        , userNos: Array<number>
//        , names: Array<string>
//        , pics: Array<string> 
//        , numUnreadArticles: number 
//    };
//
//    type friendSearchResultType = {
//        userNo: number
//        , userName: string
//        , email: string
//        , mobile: string|null
//        , profPic: string
//    };
//
//    const hubsPerPage: number = 2;
//
//    const [numHubsRead, setNumHubsRead] = useState<number>(0);
//    const [friendsE, setFriendsE] = useState<JSX.Element[]>([]);
//    const [pageIndexesE, setPageIndexesE] = useState<JSX.Element>(<div></div>);
//    const [searchResultE, setSearchResultE] = useState<JSX.Element>(<div></div>);
//    // const [isValidSearchResult, setIsValidSearchResult] = useState<boolean>(false);
//
//    const retrievedHubs = useRef<Array<hubElementType>>([]);
//    const searchedFriends = useRef<Array<friendSearchResultType>>([]);
//    const searchedFriendsUserNos = useRef<Array<number>>([]);
//
//    const [searchInputValue, setSearchInputValue] = useState<string>('');
//
//    const selectedSearchResults = useRef<Array<number>>([]);
//    const [srCurPageNo, setSRCurPageNo] = useState<number>(1);
//    const searchResultsPerPage = 2;
//
//
//    const [meE, setMeE] = useState<JSX.Element>(<div></div>);
//
//    const { Search } = Input;
//
//
//    function onClickHubHandler(event: React.MouseEvent<HTMLElement>) {
//        event.preventDefault();
//
//        const hubNo = event.currentTarget.dataset.hubno;
//
//        if(hubNo){
//            dispatch(setHubListInfo(curPageNo, parseInt(hubNo)));
//        }
//
//        dispatch(setPrevPageInfo('/friends'));
//        navigate('/discussion');
//    }
//
//    type pageIndexDataType = {
//        firstPageNum?: number
//        , prevPageNum?: number
//        , curPageNum: number
//        , nextPageNum?: number
//        , lastPageNum?: number
//    };
//
//    type UserInfo = {
//        userName: string
//        , profPic: string
//    };
//
//    type gethubsResBodyType = {
//        success: boolean
//        , hubs: Array<hubElementType>
//        , pageIndex: pageIndexDataType
//        , me: UserInfo
//    };
//
//    function onClickPageIndexHandler(event: React.MouseEvent<HTMLAnchorElement>) {
//        event.preventDefault();
//
//        const newPageNo = event.currentTarget.dataset.pageno;
//
//        if(newPageNo){
//            dispatch(setHubListInfo(parseInt(newPageNo), selecteHub));
//        }
//
//        if(authToken && newPageNo){
//            let headerData = new Headers();
//            let formData = new FormData();
//
//            headerData.set("authData", authToken);
//
//            formData.set("curPageNo", newPageNo);
//            formData.set("hubsPerPage", hubsPerPage.toString());
//
//            fetch('/api/friends/gethubs', {
//                method: 'POST'
//                , headers: headerData
//                , body: formData
//            }).then((response) => {
//                return response.json();
//            }).then((resBody: gethubsResBodyType) => {
//                if(resBody["success"] === true){
//                    setNumHubsRead(resBody["hubs"].length);
//                    setFriendsE(createHubsItems(resBody["hubs"], searchedFriendsUserNos.current));
//                    setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//                }
//            }).catch((error) => {
//                console.log('[Error]hubs.tsx.fetch(/api/friends/gethubs):', error);
//            });
//        }
//    }
//
//
//    type createhubResBodyType = {
//        success: boolean
//        , hubs: Array<hubElementType>
//        , pageIndex: pageIndexDataType
//    };
//
//    function onClickCreateMatchingHandler(event: React.MouseEvent<HTMLButtonElement>) {
//        event.preventDefault();
//
//        const friendNo = event.currentTarget.dataset.userno;
//
//        if(authToken){
//            if(friendNo && userNo){
//
//                let headerData = new Headers();
//                let formData = new FormData();
//
//                headerData.set("authData", authToken);
//
//                let friends = [];
//                friends.push(userNo);
//                friends.push(parseInt(friendNo));
//
//                formData.set("friendsJSON", JSON.stringify(friends));
//                formData.set("hubsPerPage", hubsPerPage.toString());
//
//                fetch('/api/friends/createhub', {
//                    method: 'POST'
//                    , headers: headerData
//                    , body: formData
//                }).then((response) => {
//                    return response.json();
//                }).then((resBody: createhubResBodyType) => {
//                    // console.log('hubs.tsx.onClickCreateMatchingHandler.resBody:', resBody);
//
//                    if(resBody["success"] === true){
//                        dispatch(setHubListInfo(1, null));
//
//                        retrievedHubs.current = resBody["hubs"]
//
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//
//                        setNumHubsRead(resBody["hubs"].length);
//                        setFriendsE(createHubsItems(resBody["hubs"], []));
//                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//                        setSearchResultE(<div></div>);
//
//                        // setIsValidSearchResult(false);
//                    }
//                    else{
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//                        setNumHubsRead(retrievedHubs.current.length);
//                        setFriendsE(createHubsItems(retrievedHubs.current, []));
//                        // setPageIndexesE((prevState: JSX.Element) => {
//                        //     // console.log('hubs.tsx.onClickCreateMatchingHandler.setPageIndexesE.prevState:', prevState);
//                        //     return prevState;
//                        // });
//                        setSearchResultE(<div></div>);
//                    }
//                }).catch((error) => {
//                    console.log('[Error]hubs.tsx.onClickCreateMatchingHandler:', error);
//                });
//            }
//        }
//    }
//
//    type addmatchingResBodyType = {
//        success: boolean
//        , hubs: Array<hubElementType>
//        , pageIndex: pageIndexDataType
//    };
//
//    function onClickAddFriendHandler(event: React.MouseEvent<HTMLButtonElement>){
//        event.preventDefault();
//
//        // const friendNo: string = event.currentTarget.dataset.friendno ? event.currentTarget.dataset.friendno: '-1';
//        // const hubNo: string = event.currentTarget.dataset.hubno ? event.currentTarget.dataset.hubno : '-1';
//
//        const friendNo = event.currentTarget.dataset.friendno;
//        const hubNo = event.currentTarget.dataset.hubno;
//
//        // console.log('hubs.tsx.onClickAddFriendHandler().friendno:', friendNo);
//        // console.log('hubs.tsx.onClickAddFriendHandler().hubno:', hubNo);
//        // console.log('hubs.tsx.onClickAddFriendHandler().retrievedHubs:', retrievedHubs.current);
//
//        // setIsValidSearchResult(false);
//
//        if(authToken){
//            if(userNo && friendNo && hubNo){
//                const retrievedHubsLen = retrievedHubs.current.length;
//                let friends: Array<number> = [];
//
//                friends.push(userNo);
//
//                //  create new friend list including searched user
//                for(let i=0; i<retrievedHubsLen; i++){
//                    let targetHub = retrievedHubs.current[i];
//                    // let targetHubNo = typeof(targetHub["hubNo"]) === 'number' ? targetHub["hubNo"] : parseInt(targetHub["hubNo"]);
//                    let targetHubNo = targetHub["hubNo"];
//
//                    // console.log('hubs.tsx.onClickAddFriendHandler().retrievedHubs.targetHub:', targetHub);
//
//                    // console.log('hubs.tsx.onClickAddFriendHandler().retrievedHubs.typeof(targetHubNo):', typeof(targetHubNo));
//                    // console.log('hubs.tsx.onClickAddFriendHandler().retrievedHubs.targetHubNo:', targetHubNo);
//                    // console.log('hubs.tsx.onClickAddFriendHandler().typeof(hubNo):', typeof(hubNo));
//                    // console.log('hubs.tsx.onClickAddFriendHandler().hubNo:', hubNo);
//
//                    if(targetHubNo == parseInt(hubNo)){
//                        // friends = targetHub["userNos"];
//                        friends = [...friends, ...targetHub["userNos"]];
//                        
//                        // const targetHubUserNosLen = targetHub["userNos"].length;
//
//                        // for(let j=0; j<targetHubUserNosLen; j++){
//                        //     friends.push(parseInt(targetHub["userNos"][j]));
//                        // }
//
//                        // console.log('hubs.tsx.onClickAddFriendHandler().retrievedHubs.targetHub.friends:', friends);
//                        break;
//                    }
//                }
//
//                friends.push(parseInt(friendNo));   // a friend to be added to the target hub
//                //  friends array will be [userNo, ...targetHub["userNos"], friendNo]
//
//                // console.log('hubs.tsx.onClickAddFriendHandler().friends:', friends);
//
//                friends.forEach((item, index) => {
//                    console.log('hubs.tsx.onClickAddFriendHandler().typeof(friends[%s]):%s', index, typeof(item));
//                    console.log('hubs.tsx.onClickAddFriendHandler().friends[%s]:%s', index, item);
//                });
//
//                let headerData = new Headers();
//                let formData = new FormData();
//
//                headerData.set("authData", authToken);
//
//                formData.set("friendsJSON", JSON.stringify(friends));
//                formData.set("friendNo", friendNo);
//                formData.set("hubNo", hubNo);
//                formData.set("curPageNo", curPageNo.toString());
//                formData.set("hubsPerPage", hubsPerPage.toString());
//
//                fetch('/api/friends/addmatching', {
//                    method: 'POST'
//                    , headers: headerData
//                    , body: formData
//                }).then((response) => {
//                    return response.json();
//                }).then((resBody: addmatchingResBodyType) => {
//                    if(resBody["success"] === true){
//                        // console.log('hubs.tsx.onClickAddFriendHandler.resBody:', resBody);
//
//                        retrievedHubs.current = resBody["hubs"];
//
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//
//                        setNumHubsRead(resBody["hubs"].length);
//                        setFriendsE(createHubsItems(resBody["hubs"], []));
//                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//                        setSearchResultE(<div></div>);
//                    }
//                    else{
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//                        setNumHubsRead(retrievedHubs.current.length);
//                        setFriendsE(createHubsItems(retrievedHubs.current, []));
//                        // setPageIndexesE((prevState: JSX.Element) => {
//                        //     // console.log('hubs.tsx.onClickAddFriendHandler.setPageIndexesE.prevState:', prevState);
//                        //     return prevState;
//                        // });
//                        setSearchResultE(<div></div>);
//                    }
//                }).catch((error) => {
//                    console.log('[Error]hubs.tsx.onClickAddFriendHandler:', error);
//                });
//
//            }   //  if(userNo && friendNo){  
//        }   //  if(authToken){  
//    }
//
//    function haveArraysCommons(targetArr0: Array<any>, targetArr1: Array<any>){
//        let hasCommons = false;
//
//        const targetArr0Len = targetArr0.length;
//        const targetArr1Len = targetArr1.length;
//
//        if(targetArr1Len === 0){
//            // if targetArr1 is null set, it is considered that target arrays having common null element.
//            hasCommons = true;
//        }
//        else{
//            if(targetArr0Len > 0 && targetArr1Len > 0){
//                for(let i=0; i<targetArr1Len; i++){
//                    let indexFound = targetArr0.indexOf(targetArr1[i]);
//                    if(indexFound >= 0){
//                        hasCommons = true;
//                        break;
//                    }
//                }
//            }
//        }
//
//        return hasCommons;
//    }
//
//    type disjoinResBodyType = {
//        success: boolean
//        , hubs: Array<hubElementType>
//        , pageIndex: pageIndexDataType
//    }
//
//    function onClickConfirmDisjoinHandler(event: React.MouseEvent<HTMLButtonElement>){
//        event.preventDefault();
//
//        const targetHubNo = event.currentTarget.dataset.hubno;
//
//        // console.log('hubs.tsx.onClickConfirmDisjoinHandler.targetHubNo:', targetHubNo);
//
//        if(authToken){
//            if(targetHubNo){
//                let headerData = new Headers();
//                let formData = new FormData();
//
//                headerData.set("authData", authToken);
//
//                formData.set("targetHubNo", targetHubNo);
//                formData.set("curPageNo", curPageNo.toString());
//                formData.set("hubsPerPage", hubsPerPage.toString());
//
//                fetch("/api/friends/disjoin", {
//                    method: 'POST'
//                    , headers: headerData
//                    , body: formData
//                }).then((response) => {
//                    return response.json();
//                }).then((resBody: disjoinResBodyType) => {
//                    if(resBody["success"] === true){
//                        retrievedHubs.current = resBody["hubs"]
//
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//
//                        setNumHubsRead(resBody["hubs"].length);
//                        setFriendsE(createHubsItems(resBody["hubs"], []));
//                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//                        setSearchResultE(<div></div>);
//                    }
//                    else{
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//                        setNumHubsRead(retrievedHubs.current.length);
//                        setFriendsE(createHubsItems(retrievedHubs.current, []));
//                        setSearchResultE(<div></div>);
//                    }
//
//                }).catch((error) => {
//                    console.log('[Error]hubs.tsx.onClickConfirmDisjoinHandler.fetch(/api/friends/disjoin):', error);
//                });
//            }
//        }
//    }
//
//    function onClickDisjoinHandler(event: React.MouseEvent<HTMLButtonElement>){
//        event.preventDefault();
//
//        const indexStr: string|undefined = event.currentTarget.dataset.index;
//        // console.log('hubs.tsx.onClickDisjoinHandler.hubE.index:', indexStr);
//
//        // replacing 'disjoin' button of target hub with 'confirm disjoin' button 
//
//        if(indexStr){
//            const index: number = parseInt(indexStr);
//
//            setFriendsE((prevState) => {
//                // console.log('hubs.tsx.onClickDisjoinHandler.setFriendsE.prevState', prevState);
//
//                const targetHub: hubElementType = retrievedHubs.current[index];
//                // console.log('hubs.tsx.onClickDisjoinHandler.setFriendsE.targetHub', targetHub);
//
//                let newHubE = <div></div>;
//
//                let _profPicE: JSX.Element = <span></span>;
//                let _nameE: JSX.Element = <span></span>;
//
//                //if(targetHub.userNos.length > 1){
//                //    newHubE = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
//                //        <Row align="middle">
//                //            <Col className="hubs_hub_img"
//                //                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//                //                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                //            >
//                //                {/* <Avatar
//                //                    size={{xs: 40, sm: 40, md: 64, lg: 100, xl: 100, xxl: 100}}
//                //                    src={<Image src={element.profPic} alt="Profile pic friends"/>}
//                //                /> */}
//                //                <Avatar.Group maxCount={targetHub.userNos.length} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
//                //                    size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}
//                //                >
//                //                    <Avatar src={<Image src={targetHub.pics[0]} alt="Profile pic friends"/>}/>
//                //                    <Avatar src={<Image src={targetHub.pics[1]} alt="Profile pic friends"/>}/>
//                //                </Avatar.Group>
//                //            </Col>
//                //            <Col className="hubs_hub_name"
//                //                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//                //                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                //            >
//                //                {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>{element.userName}</a> */}
//                //                <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>{targetHub.names[0]}+</a>
//                //            </Col>
//                //            <Col
//                //                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                //                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                //            >
//                //                {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>
//                //                    {/* {{element.numUnreadLogs > 0 && {<span>새 로그</span>}}}
//                //                    <Badge className="unreadnum_badge"
//                //                        count={element.numUnreadLogs}
//                //                        // count={12}
//                //                        overflowCount={99}
//                //                    />
//                //                </a> */}
//                //                <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>
//                //                    {/* {element.numUnreadLogs > 0 && */}
//                //                        {/* <span>새 로그</span> */}
//                //                    {/* } */}
//                //                    <Badge className="unreadnum_badge"
//                //                        count={parseInt(targetHub.numUnreadArticles)}
//                //                        // count={12}
//                //                        overflowCount={99}
//                //                    />
//                //                </a>
//                //            </Col>
//                //            {searchedFriends.current.length == 0 && 
//                //                <Col className="hubs_hub_button"
//                //                    xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                //                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                //                >
//                //                    <Button onClick={onClickConfirmDisjoinHandler} type="primary" danger data-hubno={targetHub.hubNo}>풀기 확인</Button>
//                //                </Col>
//                //            }
//                //        </Row>
//                //    </div>;
//                //}
//                //else{   //  if(targetHub.userNos.length > 1){  
//                //    newHubE = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
//                //        <Row align="middle">
//                //            <Col className="hubs_hub_img"
//                //                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//                //                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                //            >
//                //                <Avatar
//                //                    size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
//                //                    src={<Image src={targetHub.pics[0]} alt="Profile_pic_friends"/>}
//                //                />
//                //            </Col>
//                //            <Col className="hubs_hub_name"
//                //                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//                //                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                //            >
//                //                {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>{element.userName}</a> */}
//                //                <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>{targetHub.names[0]}</a>
//                //            </Col>
//                //            <Col
//                //                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                //                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                //            >
//                //                {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>
//                //                    {/* {{element.numUnreadLogs > 0 && {<span>새 로그</span>}}}
//                //                    <Badge className="unreadnum_badge"
//                //                        count={element.numUnreadLogs}
//                //                        // count={12}
//                //                        overflowCount={99}
//                //                    />
//                //                </a> */}
//                //                <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>
//                //                    {/* {element.numUnreadLogs > 0 && */}
//                //                        {/* <span>새 로그</span> */}
//                //                    {/* } */}
//                //                    <Badge className="unreadnum_badge"
//                //                        count={targetHub.numUnreadArticles}
//                //                        // count={12}
//                //                        overflowCount={99}
//                //                    />
//                //                </a>
//                //            </Col>
//                //            {searchedFriends.current.length == 0 && 
//                //                <Col className="hubs_hub_button"
//                //                    xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                //                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                //                >
//                //                    <Button onClick={onClickConfirmDisjoinHandler} type="primary" danger data-hubno={targetHub.hubNo}>풀기 확인</Button>
//                //                </Col>
//                //            }
//                //       </Row>
//                //    </div>;
//                //}
//
//
//                if(targetHub.pics.length > 3){
//                    _profPicE = <span>
//                        <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                            <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
//                            <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
//                            <Avatar src={<Image src={targetHub.pics[2]} alt="profile pic friends_3"/>}/>
//                        </Avatar.Group>
//                    </span>;
//                }
//                else{
//                    if(targetHub.pics.length > 2){
//                        _profPicE = <span>
//                            <Avatar.Group maxCount={3} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                                <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
//                                <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
//                                <Avatar src={<Image src={targetHub.pics[2]} alt="profile pic friends_3"/>}/>
//                            </Avatar.Group>
//                        </span>;
//                    }
//                    else{
//                        if(targetHub.pics.length > 1){
//                            _profPicE = <span>
//                                <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                                    <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
//                                    <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
//                                </Avatar.Group>
//                            </span>;
//                        }
//                        else{
//                            _profPicE = <span>
//                                <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={targetHub.pics[0]} alt="profile pic friends"/>}>
//                                </Avatar>
//                            </span>;
//                        }
//                    }
//                }
//
//                if(targetHub.names.length > 2){
//                    _nameE = <span>
//                        {targetHub.names[0]},&nbsp;{targetHub.names[1]}&nbsp;+{targetHub.names.length - 2}
//                    </span>;
//                }
//                else{
//                    if(targetHub.names.length > 1){
//                        _nameE = <span>
//                            {targetHub.names[0]},&nbsp;{targetHub.names[1]}
//                        </span>;
//                    }
//                    else{
//                        _nameE = <span>
//                            {targetHub.names[0]}
//                        </span>;
//                    }
//                }
//
//                newHubE = <div className="hubs_hub" key={index} data-index={index}>
//                    <Row align="middle">
//                        <Col className="hubs_hub_img"
//                            xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                        >
//                            {_profPicE}
//                       </Col>
//                        <Col className="hubs_hub_name"
//                            xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//                            lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                        >
//                            <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>{_nameE}</a>
//                        </Col>
//                        <Col
//                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                        >
//                            <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>
//                                {/* {element.numUnreadLogs > 0 && */}
//                                    {/* <span>새 로그</span> */}
//                                {/* } */}
//                                <Badge className="unreadnum_badge"
//                                    count={targetHub.numUnreadArticles}
//                                    // count={12}
//                                    overflowCount={99}
//                                />
//                            </a>
//                        </Col>
//                        {searchedFriends.current.length == 0 && 
//                            <Col className="hubs_hub_button"
//                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                                <Button onClick={onClickConfirmDisjoinHandler} type="primary" danger data-hubno={targetHub.hubNo}>풀기 확인</Button>
//                            </Col>
//                        }
//                    </Row>
//                </div>;
//
//                prevState.splice(index, 1, newHubE);
//
//                return([...prevState]);
//            });
//        }   //  if(index)
//    }
//
//
//    function createHubsItems(friendInfo: Array<hubElementType>, searchResultsUserNos: Array<number>): JSX.Element[] {
//
//        // console.log('hubs.tsx.createHubsItems.friendInfo:', friendInfo);
//        // console.log('hubs.tsx.createHubsItems.searchResults:', searchResults);
//
//        // console.log('hubs.tsx.createHubsItems.friendInfo:%o(%s)', friendInfo, typeof(friendInfo));
//
//        const friendsE = friendInfo.map((element: hubElementType, index: number) => {
//            let resultElement: JSX.Element = <div></div>;
//            const doNotAddSRButton: boolean = haveArraysCommons(element.userNos, searchResultsUserNos);
//            const addDisjoinButton: boolean = searchedFriends.current.length === 0;
//
//            // console.log('hubs.tsx.createHubsItems.friendInfo[%d].hubNo:%d(%s)', index, element["hubNo"], typeof(element["hubNo"]));
//            // console.log('hubs.tsx.createHubsItems.friendInfo[%d].userNos:%o(%s)', index, element["userNos"], typeof(element["userNos"]));
//            // console.log('hubs.tsx.createHubsItems.friendInfo[%d].names:%o(%s)', index, element["names"], typeof(element["names"]));
//            // console.log('hubs.tsx.createHubsItems.friendInfo[%d].pics:%o(%s)', index, element["pics"], typeof(element["pics"]));
//            // console.log('hubs.tsx.createHubsItems.friendInfo[%d].numUnreadArticles:%d(%s)', index, element["numUnreadArticles"], typeof(element["numUnreadArticles"]));
//
//
//            // console.log('hubs.tsx.createHubsItems.doNotAddSRButton:', doNotAddSRButton);
//
//            //if(element.userNos.length > 1){
//            //    resultElement = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
//            //        <Row align="middle">
//            //            <Col className="hubs_hub_img"
//            //                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//            //                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //            >
//            //                <Avatar.Group maxCount={element.userNos.length} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
//            //                    size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}
//            //                >
//            //                    <Avatar src={<Image src={element.pics[0]} alt="Profile pic friends"/>}/>
//            //                    <Avatar src={<Image src={element.pics[1]} alt="Profile pic friends"/>}/>
//            //                </Avatar.Group>
//            //            </Col>
//            //            <Col className="hubs_hub_name"
//            //                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//            //                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//            //            >
//            //                <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{element.names[0]}+</a>
//            //            </Col>
//            //            {doNotAddSRButton &&
//            //            <>
//            //                <Col
//            //                    xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//            //                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//            //                >
//            //                    <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//            //                        {/* {element.numUnreadLogs > 0 && */}
//            //                            {/* <span>새 로그</span> */}
//            //                        {/* } */}
//            //                        <Badge className="unreadnum_badge"
//            //                            count={element.numUnreadArticles}
//            //                            // count={12}
//            //                            overflowCount={99}
//            //                        />
//            //                    </a>
//            //                </Col>
//            //                {/* {searchedFriends.current.length == 0 &&  */}
//            //                { addDisjoinButton &&
//            //                    <Col className="hubs_hub_button"
//            //                        xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//            //                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //                    >
//            //                        <Button onClick={onClickDisjoinHandler} data-index={index.toString()}>친구 풀기</Button>
//            //                    </Col>
//            //                }
//            //            </>
//            //            }
//            //            {!doNotAddSRButton && 
//            //            <>
//            //                <Col
//            //                    xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//            //                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//            //                >
//            //                    <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//            //                        {/* {element.numUnreadLogs > 0 && */}
//            //                            {/* <span>새 로그</span> */}
//            //                        {/* } */}
//            //                        <Badge className="unreadnum_badge"
//            //                            count={element.numUnreadArticles}
//            //                            // count={12}
//            //                            overflowCount={99}
//            //                        />
//            //                    </a>
//            //                </Col>
//            //                <Col className="hubs_hub_button"
//            //                    xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//            //                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //                >
//            //                    <Button onClick={onClickAddFriendHandler} data-friendno={searchResults[0]} data-hubno={element.hubNo} type="primary">친구 추가</Button>
//            //                </Col>
//            //            </>
//            //            }
//            //        </Row>
//            //    </div>;
//            //}
//            //else{   //  if(element.userNos.length > 1){
//            //    resultElement = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
//            //        <Row align="middle">
//            //            <Col className="hubs_hub_img"
//            //                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//            //                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //            >
//            //                <Avatar
//            //                    size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
//            //                    src={<Image src={element.pics[0]} alt="Profile_pic_friends"/>}
//            //                />
//            //            </Col>
//            //            <Col className="hubs_hub_name"
//            //                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//            //                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//            //            >
//            //                <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{element.names[0]}</a>
//            //            </Col>
//            //            {doNotAddSRButton &&
//            //            <>
//            //                <Col
//            //                    xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//            //                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//            //                >
//            //                    <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//            //                        {/* {element.numUnreadLogs > 0 && */}
//            //                            {/* <span>새 로그</span> */}
//            //                        {/* } */}
//            //                        <Badge className="unreadnum_badge"
//            //                            count={element.numUnreadArticles}
//            //                            // count={12}
//            //                            overflowCount={99}
//            //                        />
//            //                    </a>
//            //                </Col>
//            //                {/* {searchedFriends.current.length == 0 &&  */}
//            //                { addDisjoinButton &&
//            //                    <Col className="hubs_hub_button"
//            //                        xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//            //                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //                    >
//            //                        <Button onClick={onClickDisjoinHandler} data-index={index.toString()}>친구 풀기</Button>
//            //                    </Col>
//            //                }
//            //            </>
//            //            }
//            //            {!doNotAddSRButton && 
//            //            <>
//            //                <Col
//            //                    xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//            //                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//            //                >
//            //                    <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//            //                        {/* {element.numUnreadLogs > 0 && */}
//            //                            {/* <span>새 로그</span> */}
//            //                        {/* } */}
//            //                        <Badge className="unreadnum_badge"
//            //                            count={element.numUnreadArticles}
//            //                            // count={12}
//            //                            overflowCount={99}
//            //                        />
//            //                    </a>
//            //                </Col>
//            //                <Col className="hubs_hub_button"
//            //                    xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//            //                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//            //                >
//            //                    <Button onClick={onClickAddFriendHandler} data-friendno={searchResults[0]} data-hubno={element.hubNo} type="primary">친구 추가</Button>
//            //                </Col>
//            //            </>
//            //            }
//            //        </Row>
//            //    </div>;
//            //}   //  if(element.userNos.length > 1)
//
//
//            let _profPicE: JSX.Element = <span></span>;
//            let _nameE: JSX.Element = <span></span>;
//
//            if(element.pics.length > 3){
//                _profPicE = <span>
//                    <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                        <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
//                        <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
//                        <Avatar src={<Image src={element.pics[2]} alt="profile pic friends_3"/>}/>
//                    </Avatar.Group>
//                </span>;
//            }
//            else{
//                if(element.pics.length > 2){
//                    _profPicE = <span>
//                        <Avatar.Group maxCount={3} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                            <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
//                            <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
//                            <Avatar src={<Image src={element.pics[2]} alt="profile pic friends_3"/>}/>
//                        </Avatar.Group>
//                    </span>;
//                }
//                else{
//                    if(element.pics.length > 1){
//                        _profPicE = <span>
//                            <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
//                                <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
//                                <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
//                            </Avatar.Group>
//                        </span>;
//                    }
//                    else{
//                        _profPicE = <span>
//                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={element.pics[0]} alt="profile pic friends"/>}>
//                            </Avatar>
//                        </span>;
//                    }
//                }
//            }
//
//            if(element.names.length > 2){
//                _nameE = <span>
//                    {element.names[0]},&nbsp;{element.names[1]}&nbsp;+{element.names.length - 2}
//                </span>;
//            }
//            else{
//                if(element.names.length > 1){
//                    _nameE = <span>
//                        {element.names[0]},&nbsp;{element.names[1]}
//                    </span>;
//                }
//                else{
//                    _nameE = <span>
//                        {element.names[0]}
//                    </span>;
//                }
//            }
//
//            resultElement = <div className="hubs_hub" key={index} data-index={index}>
//                <Row align="middle">
//                    <Col className="hubs_hub_img"
//                        xs={{span: 7}} sm={{span: 7}} md={{span: 5}}
//                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                    >
//                        {_profPicE}
//                    </Col>
//                    <Col className="hubs_hub_name"
//                        xs={{span: 7}} sm={{span: 7}} md={{span: 9}}
//                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                    >
//                        <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{_nameE}</a>
//                    </Col>
//                    {doNotAddSRButton &&
//                    <>
//                        <Col
//                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                        >
//                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//                                {/* {element.numUnreadLogs > 0 && */}
//                                    {/* <span>새 로그</span> */}
//                                {/* } */}
//                                <Badge className="unreadnum_badge"
//                                    count={element.numUnreadArticles}
//                                    // count={12}
//                                    overflowCount={99}
//                                />
//                            </a>
//                        </Col>
//                        {/* {searchedFriends.current.length == 0 &&  */}
//                        {addDisjoinButton &&
//                            <Col className="hubs_hub_button"
//                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                                <Button onClick={onClickDisjoinHandler} data-index={index}>친구 풀기</Button>
//                            </Col>
//                        }
//                    </>
//                    }
//                    {!doNotAddSRButton && 
//                    <>
//                        <Col
//                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                        >
//                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
//                                {/* {element.numUnreadLogs > 0 && */}
//                                    {/* <span>새 로그</span> */}
//                                {/* } */}
//                                <Badge className="unreadnum_badge"
//                                    count={element.numUnreadArticles}
//                                    // count={12}
//                                    overflowCount={99}
//                                />
//                            </a>
//                        </Col>
//                        <Col className="hubs_hub_button"
//                            xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                        >
//                            <Button onClick={onClickAddFriendHandler} data-friendno={searchResultsUserNos[0]} data-hubno={element.hubNo} type="primary">친구 추가</Button>
//                        </Col>
//                    </>
//                    }
//                </Row>
//            </div>;
//
//            return resultElement;
//        });
//
//        return friendsE;
//    }
//
//
//    function createPageIndex(pageIndexes: pageIndexDataType){
//
//        console.log('hubs.tsx.createPageIndex.pageIndexes:%o(%s)', pageIndexes, typeof(pageIndexes));
//
//        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
//        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
//        const curPageNum: number = pageIndexes["curPageNum"];
//        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
//        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;
//
//        let prevEllipsis: string|null = null;
//        let nextEllipsis: string|null = null;
//
//        if(firstPageNum && prevPageNum){
//            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
//        }
//        if(lastPageNum && nextPageNum){
//            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
//        }
//
//        return(
//            <div id="page_index_div">
//                {firstPageNum &&
//                    <span id="hubs_page_index_first_page">
//                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
//                    </span>
//                }
//                {prevEllipsis && <span>{prevEllipsis}</span>}
//                {prevPageNum &&
//                    <span id="hubs_page_index_prev_page">
//                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
//                    </span>
//                }
//                <span id="hubs_page_index_cur_page">{curPageNum}</span>
//                {nextPageNum &&
//                    <span id="hubs_page_index_next_page">
//                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
//                    </span>
//                }
//                {nextEllipsis && <span>{nextEllipsis}</span>}
//                {lastPageNum &&
//                    <span id="hubs_page_index_last_page">
//                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
//                    </span>
//                }
//            </div>
//        );
//    }
//
//
//    function createPageIndexForSR(){
//        const curPageNoForSR = srCurPageNo;
//
//        const numSR = searchedFriendsUserNos.current.length;
//
//        if(numSR == 0){
//            const lastPageNumForSR = 1;
//        }
//        else{
//
//        }
//
//
//    }
//
//
//    function createSearchResultDiv(searchResultArray: Array<friendSearchResultType>): JSX.Element {
//
//        // console.log('hubs.tsx.createSearchResultDiv.searchResultArray:%o(%s)', searchResultArray, typeof(searchResultArray));
//
//        let retE: JSX.Element = <div id="friends_friend_search_result"></div>;
//
//        if(searchResultArray.length > 0){
//            const searchResult = searchResultArray[0];
//
//            if(searchResult["userNo"]){
//                let candidateNo: number = searchResult["userNo"];
//
//                if(candidateNo >= 0){
//                    retE = <div id="friends_friend_search_result">
//                        <Row align="middle">
//                            <Col className="search_result_pic" 
//                                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                                <Avatar
//                                    size={{xs: 40, sm: 40, md: 64, lg: 80, xl: 80, xxl: 80}}
//                                    src={<Image src={searchResult["profPic"]} alt="search result_profile_pic"/>}
//                                />
//                            </Col>
//                            <Col className="search_result_username"
//                                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//                                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                            >
//                                {searchResult["userName"]}
//                            </Col>
//                            <Col
//                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
//                                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
//                            >
//                            </Col>
//                            <Col className="search_result_add_button"
//                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                                <Button onClick={onClickCreateMatchingHandler} data-userno={candidateNo}>친구 맺기</Button>
//                                {/* <Button onClick={onClickSelectSearchResultHandler} data-userno={candidateNo}>선택</Button> */}
//                            </Col>
//                        </Row>
//                    </div>;
//                }
//                else if(candidateNo == -1){
//                    retE = <div id="friends_friend_search_result">
//                        <Row align="middle">
//                            <Col span={4}></Col>
//                            <Col className="search_result_not_found" span={16}>입력하신 조건과 일치하는 친구가 아직 없어요 😢🤦‍♀️🤦‍♂️</Col>
//                            <Col span={4}></Col>
//                        </Row>
//                    </div>;
//                }
//                else{
//                    retE = <div id="friends_friend_search_result"></div>;
//                }
//            }   // if(searchResult["userNo"])
//        }   // if(searchResultArray.length > 0)
//        else{
//            retE = <div id="friends_friend_search_result">
//                <Row align="middle">
//                    <Col span={4}></Col>
//                    <Col className="search_result_not_found" span={16}>입력하신 조건과 일치하는 친구가 아직 없어요 😢🤦‍♀️🤦‍♂️</Col>
//                    <Col span={4}></Col>
//                </Row>
//            </div>;
//        }
//
//        if(searchResultArray.length > 0){
//
//            const searchResultsArrayForCurPage = searchResultArray.slice((srCurPageNo - 1)*searchResultsPerPage, srCurPageNo*searchResultsPerPage);
//
//            if(searchResultsArrayForCurPage.length > 0){
//
//            }
//
//
//            const searchResultRows = searchResultArray.map((searchResult) => {
//
//            });
//        }
//        else{
//            retE = <div id="friends_friend_search_result">
//                <Row align="middle">
//                    <Col span={4}></Col>
//                    <Col className="search_result_not_found" span={16}>입력하신 조건과 일치하는 친구가 아직 없어요 😢🤦‍♀️🤦‍♂️</Col>
//                    <Col span={4}></Col>
//                </Row>
//            </div>;
//        }
//
//        return retE;
//    }
//
//    function onClickMeHandler(event: React.MouseEvent<HTMLAnchorElement>){
//        event.preventDefault();
//
//        navigate('/user/myinfo');
//    }
//
//    function createMeInfoDiv(userInfo: UserInfo): JSX.Element {
//
//        let profPic: string = userInfo.profPic;
//        let username: string = userInfo.userName;
//
//        return(
//            <div>
//                <Row align="middle">
//                    <Col id="hubs_me_img"
//                        xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
//                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                    >
//                        <Badge color="orange" count={"나"} size="default">
//                            <Avatar
//                                size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
//                                src={<Image src={profPic} alt="Profile pic of me"/>}
//                            />
//                        </Badge>
//                    </Col>
//                    <Col id="hubs_me_name"
//                        xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
//                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                    >
//                        <a onClick={onClickMeHandler}>{username}</a>
//                    </Col>
//                    <Col
//                        xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
//                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
//                    ></Col>
//                </Row>                
//            </div>
//        );
//    }
//
//    type searchFriendResBodyType = {
//        success: boolean
//        , searchResults: Array<friendSearchResultType>
//        , searchResultUserNos: Array<number>
//    };
//
//    function onClickSearchButtonHandler(searchInputVal: string){
//
//        if(searchInputVal.length > 4) {
//
//            if(authToken){
//                let headerData = new Headers();
//                let formData = new FormData();
//
//                const targetPat = /-+|\s+/g;
//                const searchInputValWOTargetPat = searchInputVal.replace(targetPat, '');
//                const searchInputValArray = searchInputValWOTargetPat.split(',');
//                const searchInputValArrayJSON = JSON.stringify(searchInputValArray);
//
//                // console.log('hubs.tsx.onClickSearchButtonHandler.searchInputValArray:', searchInputValArray);
//
//                headerData.set("authData", authToken);
//                // formData.set("searchInputVal", searchInputVal);
//                formData.set("candidatesJSON", searchInputValArrayJSON);
//
//                fetch('/api/friends/search', {
//                    method: 'POST'
//                    , headers: headerData
//                    , body: formData
//                }).then((response) => {
//                    return response.json();
//                }).then((resBody: searchFriendResBodyType) => {
//                    // console.log('hubs.tsx.onClickSearchButtonHandler().resBody:', resBody);
//                    // setSearchResultE(createSearchResultDiv({
//                    //     "userNo": resBody["userNo"]
//                    //     , "userName": resBody["userName"]
//                    //     , "email": resBody["email"]
//                    //     , "mobile": resBody["mobile"]
//                    //     , "profPic": resBody["profPic"]
//                    //     }));
//                    // if(resBody["success"] === true && parseInt(resBody["userNo"]) > 0){
//                    //     setIsValidSearchResult(true);
//                    // }
//                    if(resBody["success"] === true){
//                        if(resBody["searchResults"].length > 0){
//                            searchedFriends.current = resBody["searchResults"];
//                            // searchedFriendsUserNos.current = [resBody["searchResults"][0]["userNo"]];
//                            searchedFriendsUserNos.current = resBody["searchResultUserNos"];
//                            setSearchInputValue('');
//                            setSearchResultE(createSearchResultDiv(resBody["searchResults"]));
//                            setFriendsE(createHubsItems(retrievedHubs.current, [resBody["searchResults"][0]["userNo"]]));
//                        }
//                        else{
//                            searchedFriends.current = [];
//                            searchedFriendsUserNos.current = [];
//                            setSearchInputValue('');
//                            setSearchResultE(createSearchResultDiv(resBody["searchResults"]));
//                            setFriendsE(createHubsItems(retrievedHubs.current, []));
//                        }
//                   }
//
//                }).catch((error) => {
//                    console.log('[Error]Hubs.tsx.onSearchButtonHandler:', error);
//                }); 
//            }
//        }
//        else{
//            if(searchInputVal.length === 0) {   //  refresh page
//                if(authToken){
//                    let headerData = new Headers();
//                    let formData = new FormData();
//
//                    headerData.set("authData", authToken);
//
//                    formData.set("curPageNo", curPageNo.toString());
//                    formData.set("hubsPerPage", hubsPerPage.toString());
//
//                    fetch('/api/friends/gethubs', {
//                        method: 'POST'
//                        , headers: headerData
//                        , body: formData
//                    }).then((response) => {
//                        return response.json();
//                    }).then((resBody: gethubsResBodyType) => {
//                        console.log('hubs.tsx.onClickSearchButtonHandler.fetch(/api/friends/gethubs).resBody:', resBody);
//                        retrievedHubs.current = resBody["hubs"];
//
//                        searchedFriends.current = [];
//                        searchedFriendsUserNos.current = [];
//
//                        setNumHubsRead(resBody["hubs"].length);
//                        setMeE(createMeInfoDiv(resBody["me"]));
//                        setFriendsE(createHubsItems(resBody["hubs"], searchedFriendsUserNos.current));
//                        setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//                        setSearchResultE(<div></div>);
//                    }).catch((error) => {
//                        console.log('[Error]hubs.tsx.onClickSearchButtonHandler.fetch(/api/friends/gethubs):', error);
//                    })
//                }
//            }
//        }
//    }
//
//    function onChangeSearchInputHandler(event: React.ChangeEvent<HTMLInputElement>){
//        setSearchInputValue(event.currentTarget.value);
//    }
//
//    useEffect(() => {
//        if(authToken){
//            let headerData = new Headers();
//            let formData = new FormData();
//
//            headerData.set("authData", authToken);
//
//            formData.set("curPageNo", curPageNo.toString());
//            formData.set("hubsPerPage", hubsPerPage.toString());
//
//            fetch('/api/friends/gethubs', {
//                method: 'POST'
//                , headers: headerData
//                , body: formData
//            }).then((response) => {
//                return response.json();
//            }).then((resBody: gethubsResBodyType) => {
//                // console.log('hubs.tsx.fetch(/api/friends/gethubs).resBody:', resBody);
//                retrievedHubs.current = resBody["hubs"];
//
//                setNumHubsRead(resBody["hubs"].length);
//                setMeE(createMeInfoDiv(resBody["me"]));
//                setFriendsE(createHubsItems(resBody["hubs"], searchedFriendsUserNos.current));
//                setPageIndexesE(createPageIndex(resBody["pageIndex"]));
//            }).catch((error) => {
//                console.log('[Error]hubs.tsx.fetch(/api/friends/gethubs):', error);
//            })
//        }
//
//    }, [isLoggedIn]);
//
//    return(
//        <div id="hubs_top_continer_div">
//            <AppLayout>
//                <div id="hubs_top_container_div">
//                    <div id="hubs_me_div">{meE}</div>
//                    <Divider/>
//                    <div id="hubs_hubs_div">{friendsE}</div>
//                    <div id="hubs_page_index_div">{pageIndexesE}</div>
//                    {(numHubsRead > 0) && <Divider dashed/>}
//                    <div id="hubs_search_div">
//                        <Row id="hubs_search_guide_message_div">
//                            <Col
//                                xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                            </Col>
//                            <Col
//                                xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
//                                lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
//                            >
//                                <span>콤마(,)로 구분하여 입력하면 여러명 검색이 가능합니다.</span>
//                            </Col>
//                            <Col
//                                xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                            </Col>                            
//                        </Row>
//                        <Row id="hubs_search_input_div">
//                            <Col
//                                xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                            </Col>
//                            <Col
//                                xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
//                                lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
//                            >
//                                <Search placeholder="메일주소 | 전화번호" onSearch={onClickSearchButtonHandler} onChange={onChangeSearchInputHandler} value={searchInputValue} enterButton/>
//                            </Col>
//                            <Col
//                                xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
//                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
//                            >
//                            </Col>                            
//                        </Row>
//                    </div>
//                    <div id="hubs_search_result_div">{searchResultE}</div>
//                </div>
//            </AppLayout>
//        </div>
//    );
//}



function Hubs(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const userNo = useSelector<RootStateType, number|null>(state => state.auth.userNo);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const curPageNo = useSelector<RootStateType, number>(state => {
        let _curPageNo = state.hubList.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessHubListInfo = sessionStorage.getItem("hubListInfo");
            if(sessHubListInfo){
                const parsedHubListInfo: HubListState = JSON.parse(sessHubListInfo);
                _curPageNo = parsedHubListInfo["curPageNo"];
            }
        }

        return _curPageNo;
    });

    const curPageNoObj: {"curPageNo": number} = {"curPageNo": 1};   // for updated value to event listeners

    const selectedHub = useSelector<RootStateType, number|null>(state => {
        let _selectedHub = state.hubList.selectedHub;

        if(_selectedHub === null || _selectedHub === undefined){
            const sessHubListInfo = sessionStorage.getItem("hubListInfo");
            if(sessHubListInfo){
                const parsedHubListInfo: HubListState = JSON.parse(sessHubListInfo);
                _selectedHub = parsedHubListInfo["selectedHub"];
            }
        }

        return _selectedHub;
    });

    const { Search } = Input;

    type hubElementType = {
        hubNo: number 
        , userNos: Array<number>
        , names: Array<string>
        , pics: Array<string> 
        , numUnreadArticles: number 
    };

    type friendSearchResultType = {
        userNo: number
        , userName: string
        , email: string
        , mobile: string|null
        , profPic: string
    };

    const hubsPerPage: number = 7;

    const retrievedHubs = useRef<Array<hubElementType>>([]);

    const searchedFriends = useRef<Array<friendSearchResultType>>([]);
    const searchedFriendsUserNos = useRef<Array<number>>([]);


    const [meE, setMeE] = useState<JSX.Element>(<div></div>);
    const [friendsE, setFriendsE] = useState<JSX.Element[]>([]);
    const [pageIndexE, setPageIndexE] = useState<JSX.Element>(<div></div>);

    const searchResultsPerPage: number = 2;
    // const [searchResultCurPageNo, setSRCurPageNo] = useState<number>(1);
    const searchResultCurPageNo = useRef<number>(1);

    const [hubsSearchResultE, setHubsSearchResultE] = useState<JSX.Element[]>([]);
    const [hubsSearchResultPageIndexE, setHubsSearchResultPageIndexE] = useState<JSX.Element[]>([]);
    const [hubsButtonE, setHubsButtonE] = useState<JSX.Element>(<div></div>);

    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const selectedFriendList = useRef<Array<number>>([]);

    const [hubsMessageE, setHubsMessageE] = useState<JSX.Element>(<span></span>);


    function onClickMeHandler(event: React.MouseEvent<HTMLAnchorElement>){
        event.preventDefault();

        navigate('/user/myinfo');
    }

    type pageIndexDataType = {
        firstPageNum?: number
        , prevPageNum?: number
        , curPageNum: number
        , nextPageNum?: number
        , lastPageNum?: number
    };

    type UserInfo = {
        userName: string
        , profPic: string
    };

    type getHubsResBodyType = {
        success: boolean
        , hubs: Array<hubElementType>
        , pageIndex: pageIndexDataType
        , me: UserInfo
    };


    function createMeItem(userInfo: UserInfo): JSX.Element {

        let profPic: string = userInfo.profPic;
        let username: string = userInfo.userName;

        return(
            <div>
                <Row align="middle">
                    <Col id="hubs_me_img"
                        xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                    >
                        <Badge color="orange" count={"나"} size="default">
                            <Avatar
                                size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                src={<Image src={profPic} alt="Profile pic of me"/>}
                            />
                        </Badge>
                    </Col>
                    <Col id="hubs_me_name"
                        xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                    >
                        <a onClick={onClickMeHandler}>{username}</a>
                    </Col>
                    <Col
                        xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                    ></Col>
                </Row>                
            </div>
        );
    }   // function createMeItem(...)


    function haveArraysCommons(targetArr0: Array<any>, targetArr1: Array<any>){
        let hasCommons = false;

        const targetArr0Len = targetArr0.length;
        const targetArr1Len = targetArr1.length;

        if(targetArr1Len === 0){
            // if targetArr1 is null set, it is considered that target arrays having common null element.
            hasCommons = true;
        }
        else{
            if(targetArr0Len > 0 && targetArr1Len > 0){
                for(let i=0; i<targetArr1Len; i++){
                    let indexFound = targetArr0.indexOf(targetArr1[i]);
                    if(indexFound >= 0){
                        hasCommons = true;
                        break;
                    }
                }
            }
        }

        return hasCommons;
    }


    function calcComplementarySet(targetArr0: Array<number>, targetArr1: Array<number>): Array<number> {
        //  cSet = targetArr0 - targetArr1

        let cSet: Array<number> = targetArr0.slice();

        const targetArr0Len = targetArr0.length;
        const targetArr1Len = targetArr1.length;

        for(let i = 0; i < targetArr1Len; i++){
            let indexNo = cSet.indexOf(targetArr1[i]);

            if(indexNo >= 0){
                cSet.splice(indexNo, 1);
            }
        }

        return cSet;
    }


    function onClickHubHandler(event: React.MouseEvent<HTMLElement>){
        event.preventDefault();

        const hubNo = event.currentTarget.dataset.hubno;

        if(hubNo != undefined){
            // dispatch(setHubListInfo(curPageNo, parseInt(hubNo)));

            const pageNo: number = curPageNoObj["curPageNo"];

            // console.log('hubs.tsx.onClickHubHandler().(hubNo, pageNo): (%d, %d)', hubNo, pageNo);

            dispatch(setHubListInfo(pageNo, parseInt(hubNo)));
        }

        dispatch(setPrevPageInfo('/friends'));
        navigate('/discussion');
    }


    function displayMessage(message: string): JSX.Element{

        let retE = <span></span>;

        if(message.length > 0){
            retE = <span>{message}</span>;
        }

        return(
            retE
        );
    }


    type addFriendsResBodyType = {
        success: boolean
        , hubs: Array<hubElementType>
        , pageIndex: pageIndexDataType
        , message: string
    };

    function onClickAddFriendsHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        // console.log('hubs.tsx.onClickAddFriendsHandler()');

        if(event.currentTarget.dataset.index && event.currentTarget.dataset.hubno && userNo){
            const indexNo: number = parseInt(event.currentTarget.dataset.index);
            const hubNo: number = parseInt(event.currentTarget.dataset.hubno);

            const participantsOfCurHub: Array<number> = new Array();
            participantsOfCurHub.push(userNo);
            participantsOfCurHub.push(...retrievedHubs.current[indexNo].userNos);

            // console.log('hubs.tsx.onClickAddFriendsHandler().participantsOfCurHub:', participantsOfCurHub);

            const candidateList: Array<number> = selectedFriendList.current.slice();
            const complementaryCandidates: Array<number> = calcComplementarySet(candidateList, participantsOfCurHub);

            const expectedResultList: Array<number> = new Array();

            expectedResultList.push(...participantsOfCurHub);
            expectedResultList.push(...complementaryCandidates);

            // console.log('hubs.tsx.onClickAddFriendsHandler().cadidateList:', candidateList);
            // console.log('hubs.tsx.onClickAddFriendsHandler().complementaryCandidates:', complementaryCandidates);
            // console.log('hubs.tsx.onClickAddFriendsHandler().expectedAddResultList:', expectedResultList);

            if(complementaryCandidates.length > 0){
                addFriends(complementaryCandidates, expectedResultList, hubNo).then((resBody: addFriendsResBodyType) => {
                    if(resBody["success"] === true){
                        selectedFriendList.current = [];
                        searchedFriends.current = [];
                        searchedFriendsUserNos.current = [];
                        searchResultCurPageNo.current = 1;
                        retrievedHubs.current = resBody["hubs"];
                        setFriendsE(createHubsItems(resBody["hubs"]));
                        setPageIndexE(createPageIndex(resBody["pageIndex"]));
                        setHubsSearchResultE([]);
                        setHubsSearchResultPageIndexE([]);
                        setHubsButtonE(<div></div>);
                        setHubsMessageE(<span></span>);
                    }
                    else{
                        if(resBody["message"].length > 0){
                            selectedFriendList.current = [];
                            searchedFriends.current = [];
                            searchedFriendsUserNos.current = [];
                            searchResultCurPageNo.current = 1;
                            setHubsSearchResultE([]);
                            setHubsSearchResultPageIndexE([]);
                            setHubsButtonE(<div></div>);
                            setHubsMessageE(displayMessage(resBody["message"]));
                        }
                    }
                }).catch((error) => {
                    console.log('[Error]hubs.tsx.onClickAddFriendsHandler().addFriends():', error);
                });
            }
            else{
                const message = '이미 같은 멤버들의 토론방이 있습니다.';

                selectedFriendList.current = [];
                searchedFriends.current = [];
                searchedFriendsUserNos.current = [];
                searchResultCurPageNo.current = 1;
                setHubsSearchResultE([]);
                setHubsSearchResultPageIndexE([]);
                setHubsButtonE(<div></div>);
                setHubsMessageE(displayMessage(message));
            }
        }
    }


    function onClickConfirmDisjoinHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        const targetHub: string|undefined = event.currentTarget.dataset.hubno;

        if(targetHub){
            const targetHubNo: number = parseInt(targetHub);

            // console.log('hubs.tsx.onClickConfirmDisjoinHandler().(targetHubNo, userNo): (%d, %d)', targetHubNo, userNo);

            disjoinFromHub(targetHubNo).then((resBody) => {
                if(resBody["success"] === true){
                    retrievedHubs.current = resBody["hubs"];
                    searchedFriends.current = [];
                    searchedFriendsUserNos.current = [];
                    selectedFriendList.current = [];
                    searchResultCurPageNo.current = 1;
                    setFriendsE(createHubsItems(resBody["hubs"]));
                    setPageIndexE(createPageIndex(resBody["pageIndex"]));
                    setHubsSearchResultE([]);
                    setHubsSearchResultPageIndexE([]);
                }
            }).catch((error) => {
                console.log('[Error]hubs.tsx.onClickConfirmDisjoinHandler().disjoinFromHub():', error);
            })
        }
    }   // function onClickConfirmDisjoinHandler(...)


    function onClickDisjoinHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        const indexStr: string|undefined = event.currentTarget.dataset.index;
        // console.log('hubs.tsx.onClickDisjoinHandler.hubE.index:', indexStr);

        // replacing 'disjoin' button of target hub with 'confirm disjoin' button 
        if(indexStr){
            const index: number = parseInt(indexStr);

            setFriendsE((prevState) => {
                // console.log('hubs.tsx.onClickDisjoinHandler.setFriendsE.prevState', prevState);

                const targetHub: hubElementType = retrievedHubs.current[index];
                // console.log('hubs.tsx.onClickDisjoinHandler.setFriendsE.targetHub', targetHub);

                let newHubE = <div></div>;

                let _profPicE: JSX.Element = <span></span>;
                let _nameE: JSX.Element = <span></span>;

                if(targetHub.pics.length > 3){
                    _profPicE = <span>
                        <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                            <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
                            <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
                            <Avatar src={<Image src={targetHub.pics[2]} alt="profile pic friends_3"/>}/>
                        </Avatar.Group>
                    </span>;
                }
                else{
                    if(targetHub.pics.length > 2){
                        _profPicE = <span>
                            <Avatar.Group maxCount={3} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                                <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
                                <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
                                <Avatar src={<Image src={targetHub.pics[2]} alt="profile pic friends_3"/>}/>
                            </Avatar.Group>
                        </span>;
                    }
                    else{
                        if(targetHub.pics.length > 1){
                            _profPicE = <span>
                                <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                                    <Avatar src={<Image src={targetHub.pics[0]} alt="profile pic friends_1"/>}/>
                                    <Avatar src={<Image src={targetHub.pics[1]} alt="profile pic friends_2"/>}/>
                                </Avatar.Group>
                            </span>;
                        }
                        else{
                            _profPicE = <span>
                                <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={targetHub.pics[0]} alt="profile pic friends"/>}>
                                </Avatar>
                            </span>;
                        }
                    }
                }

                if(targetHub.names.length > 2){
                    _nameE = <span>
                        {targetHub.names[0]},&nbsp;{targetHub.names[1]}&nbsp;+{targetHub.names.length - 2}
                    </span>;
                }
                else{
                    if(targetHub.names.length > 1){
                        _nameE = <span>
                            {targetHub.names[0]},&nbsp;{targetHub.names[1]}
                        </span>;
                    }
                    else{
                        _nameE = <span>
                            {targetHub.names[0]}
                        </span>;
                    }
                }

                newHubE = <div className="hubs_hub" key={index} data-index={index}>
                    <Row align="middle">
                        <Col className="hubs_hub_img"
                            xs={{span: 7}} sm={{span: 7}} md={{span: 5}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            {_profPicE}
                       </Col>
                        <Col className="hubs_hub_name"
                            xs={{span: 7}} sm={{span: 7}} md={{span: 9}}
                            lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                        >
                            <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>{_nameE}</a>
                        </Col>
                        <Col
                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                            <a onClick={onClickHubHandler} data-hubno={targetHub.hubNo}>
                                {/* {element.numUnreadLogs > 0 && */}
                                    {/* <span>새 로그</span> */}
                                {/* } */}
                                <Badge className="unreadnum_badge"
                                    count={targetHub.numUnreadArticles}
                                    // count={12}
                                    overflowCount={99}
                                />
                            </a>
                        </Col>
                        {selectedFriendList.current.length === 0 && 
                            <Col className="hubs_hub_button"
                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                <Button onClick={onClickConfirmDisjoinHandler} type="primary" danger data-hubno={targetHub.hubNo}>풀기 확인</Button>
                            </Col>
                        }
                    </Row>
                </div>;

                prevState.splice(index, 1, newHubE);

                return([...prevState]);
            });
        }   //  if(index)
    }   // function onClickDisjoinHandler(...)


    function createHubsItems(friendInfo: Array<hubElementType>): JSX.Element[] {

        // console.log('hubs.tsx.createHubsItems.friendInfo:%o(%s)', friendInfo, typeof(friendInfo));
        // console.log('hubs.tsx.createHubsItems.friendInfo:', friendInfo);

        // console.log('hubs.tsx.createHubsItems.searchResults:', searchResults);

        const friendsE = friendInfo.map((element: hubElementType, index: number) => {
            let resultElement: JSX.Element = <div></div>;
            // const doNotAddSRButton: boolean = haveArraysCommons(element.userNos, searchResultsUserNos);
            // const addDisjoinButton: boolean = searchedFriends.current.length === 0;

            const addSelectedFriendsButton: boolean = selectedFriendList.current.length > 0;

            let _profPicE: JSX.Element = <span></span>;
            let _nameE: JSX.Element = <span></span>;

            if(element.pics.length > 3){
                _profPicE = <span>
                    <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                        <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
                        <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
                        <Avatar src={<Image src={element.pics[2]} alt="profile pic friends_3"/>}/>
                    </Avatar.Group>
                </span>;
            }
            else{
                if(element.pics.length > 2){
                    _profPicE = <span>
                        <Avatar.Group maxCount={3} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                            <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
                            <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
                            <Avatar src={<Image src={element.pics[2]} alt="profile pic friends_3"/>}/>
                        </Avatar.Group>
                    </span>;
                }
                else{
                    if(element.pics.length > 1){
                        _profPicE = <span>
                            <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}>
                                <Avatar src={<Image src={element.pics[0]} alt="profile pic friends_1"/>}/>
                                <Avatar src={<Image src={element.pics[1]} alt="profile pic friends_2"/>}/>
                            </Avatar.Group>
                        </span>;
                    }
                    else{
                        _profPicE = <span>
                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={element.pics[0]} alt="profile pic friends"/>}>
                            </Avatar>
                        </span>;
                    }
                }
            }

            if(element.names.length > 2){
                _nameE = <span>
                    {element.names[0]},&nbsp;{element.names[1]}&nbsp;+{element.names.length - 2}
                </span>;
            }
            else{
                if(element.names.length > 1){
                    _nameE = <span>
                        {element.names[0]},&nbsp;{element.names[1]}
                    </span>;
                }
                else{
                    _nameE = <span>
                        {element.names[0]}
                    </span>;
                }
            }

            resultElement = <div className="hubs_hub" key={index} data-index={index}>
                <Row align="middle">
                    <Col className="hubs_hub_img"
                        xs={{span: 7}} sm={{span: 7}} md={{span: 5}}
                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                    >
                        {_profPicE}
                    </Col>
                    <Col className="hubs_hub_name"
                        xs={{span: 7}} sm={{span: 7}} md={{span: 9}}
                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                    >
                        <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{_nameE}</a>
                    </Col>
                    {addSelectedFriendsButton && 
                    <>
                        <Col
                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
                                {/* {element.numUnreadLogs > 0 && */}
                                    {/* <span>새 로그</span> */}
                                {/* } */}
                                <Badge className="unreadnum_badge"
                                    count={element.numUnreadArticles}
                                    // count={12}
                                    overflowCount={99}
                                />
                            </a>
                        </Col>
                        <Col className="hubs_hub_button"
                            xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            {/* <Button onClick={onClickAddFriendHandler} data-friendno={searchResultsUserNos[0]} data-hubno={element.hubNo} type="primary">친구 추가</Button> */}
                            <Button onClick={onClickAddFriendsHandler} data-hubno={element.hubNo} data-index={index} type="primary">친구 추가</Button>
                        </Col>
                    </>
                    }
                    {!addSelectedFriendsButton &&
                    <>
                        <Col
                            xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>
                                {/* {element.numUnreadLogs > 0 && */}
                                    {/* <span>새 로그</span> */}
                                {/* } */}
                                <Badge className="unreadnum_badge"
                                    count={element.numUnreadArticles}
                                    // count={12}
                                    overflowCount={99}
                                />
                            </a>
                        </Col>
                        <Col className="hubs_hub_button"
                            xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            <Button onClick={onClickDisjoinHandler} data-index={index}>친구 풀기</Button>
                        </Col>
                    </>
                    }
                </Row>
            </div>;

            return resultElement;
        });

        return friendsE;
    }   // function createHubsItems(...)


    function onClickPageIndexHandler(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        if(event.currentTarget.dataset.pageno){
            const newPageNo: number = parseInt(event.currentTarget.dataset.pageno);

            // console.log('hubs.tsx.onClickPageIndexHandler.newPageNo:', newPageNo);

            dispatch(setHubListInfo(newPageNo, selectedHub));
            curPageNoObj["curPageNo"] = newPageNo;

            if(authToken && newPageNo){
                getHubs(newPageNo, hubsPerPage).then((resBody: getHubsResBodyType) => {
                    if(resBody["success"] === true){
                        retrievedHubs.current = resBody["hubs"];
                        setFriendsE(createHubsItems(resBody["hubs"]));
                        setPageIndexE(createPageIndex(resBody["pageIndex"]));
                    }
                }).catch((error) => {
                    console.log('[Error]hubs.tsx.onClickPageIndexHandler().fetch(/api/friends/gethubs):', error);
                });
            }
        }
    }


    function createPageIndex(pageIndexes: pageIndexDataType){

        // console.log('hubs.tsx.createPageIndex.pageIndexes:%s(%o)', typeof(pageIndexes), pageIndexes);

        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
        const curPageNum: number = pageIndexes["curPageNum"];
        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPageNum && prevPageNum){
            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
        }
        if(lastPageNum && nextPageNum){
            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
        }

        return(
            <div id="page_index_div">
                {firstPageNum &&
                    <span id="hubs_page_index_first_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
                    </span>
                }
                {prevEllipsis && <span>{prevEllipsis}</span>}
                {prevPageNum &&
                    <span id="hubs_page_index_prev_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
                    </span>
                }
                <span id="hubs_page_index_cur_page">{curPageNum}</span>
                {nextPageNum &&
                    <span id="hubs_page_index_next_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
                    </span>
                }
                {nextEllipsis && <span>{nextEllipsis}</span>}
                {lastPageNum &&
                    <span id="hubs_page_index_last_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
                    </span>
                }
            </div>
        );
    }   // function createPageIndex(...)


    type searchFriendResBodyType = {
        success: boolean
        , searchResults: Array<friendSearchResultType>
        , searchResultUserNos: Array<number>
    };


    function onClickSearchButtonHandler(searchInputVal: string){

        if(searchInputVal.length > 4) {
            searchFriends().then((resBody: searchFriendResBodyType) => {
                if(resBody["success"] === true){
                    searchedFriends.current = resBody["searchResults"];
                    searchedFriendsUserNos.current = resBody["searchResultUserNos"];
                    selectedFriendList.current = [];
                    searchResultCurPageNo.current = 1;
                    setFriendsE(createHubsItems(retrievedHubs.current));
                    setSearchInputValue('');
                    setHubsSearchResultE(displaySearchedItems(resBody["searchResults"]));
                    setHubsSearchResultPageIndexE(displaySearchResultPageIndexE(searchResultCurPageNo.current));
                    setHubsButtonE(<div></div>);
                    setHubsMessageE(<span></span>);
                }
                else{
                    searchedFriends.current = [];
                    searchedFriendsUserNos.current = [];
                    selectedFriendList.current = [];
                    searchResultCurPageNo.current = 1;
                }
            }).catch((error) => {
                console.log('[Error]SearchHubs.tsx.onClickSearchButtonHandler:', error);
            });
        }
        else{
            if(searchInputVal.length === 0) {   //  refresh page
                getHubs(curPageNo, hubsPerPage).then((resBody: getHubsResBodyType) => {
                    retrievedHubs.current = resBody["hubs"];
                    searchedFriends.current = [];
                    searchedFriendsUserNos.current = [];
                    selectedFriendList.current = [];
                    searchResultCurPageNo.current = 1;
                    setMeE(createMeItem(resBody["me"]));
                    setFriendsE(createHubsItems(resBody["hubs"]));
                    setPageIndexE(createPageIndex(resBody["pageIndex"]));
                    setHubsSearchResultE([]);
                    setHubsSearchResultPageIndexE([]);
                    setHubsMessageE(<span></span>);
                }).catch((error) => {
                    console.log('[Error]hubs.tsx.onClickSearchButtonHandler().getHubs():', error);
                });
            }
        }
    }   // function onClickSearchButtonHandler(...)


    function onClickSelectSearchedItemHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        const selectedUserNo: string|undefined = event.currentTarget.dataset.userno;
        const indexOfSelectedItem: string|undefined = event.currentTarget.dataset.index;

        // console.log('hubs.tsx.onClickSelectSearchedItemHandler.selectedUserNo:', selectedUserNo);
        // console.log('hubs.tsx.onClickSelectSearchedItemHandler.indexOfSelectedItem:', indexOfSelectedItem);

        if(selectedUserNo && indexOfSelectedItem){
            if(selectedFriendList.current.indexOf(parseInt(selectedUserNo)) < 0){   // not found
                selectedFriendList.current.push(parseInt(selectedUserNo));
            }
            const index: number = parseInt(indexOfSelectedItem);

            setHubsSearchResultE((prevState) => {

                const targetItem: friendSearchResultType = searchedFriends.current[(searchResultCurPageNo.current - 1)*searchResultsPerPage + index];
                
                const selectBtn: JSX.Element = <Button onClick={onClickUnSelectSearchedItemHandler} danger data-userno={targetItem["userNo"]} data-index={index}>해제</Button>;

                const selectedItem: JSX.Element = 
                    <div className="hubs_search_result" key={targetItem["userNo"]}>
                        <Row align="middle">
                            <Col className="search_result_pic" 
                                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                <Avatar
                                    size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                    src={<Image src={targetItem["profPic"]} alt="search result_profile_pic"/>}
                                />
                            </Col>
                            <Col className="search_result_username"
                                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                            >
                                {targetItem["userName"]}
                            </Col>
                            <Col
                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                            >
                            </Col>
                            <Col className="search_result_add_button"
                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                {selectBtn}
                            </Col>
                        </Row>
                    </div>;

                prevState.splice(index, 1, selectedItem);

                return [...prevState];
            });

            // console.log('hubs.tsx.onClickSelectSearchedItemHandler.selectedFriendList:', selectedFriendList.current);

            if(selectedFriendList.current.length > 0){
                setFriendsE(createHubsItems(retrievedHubs.current));

                const createNewHubButton: JSX.Element = <Button type="primary" onClick={onClickCreateNewHubButtonHandler}>새 토론방 생성</Button>;

                setHubsButtonE(createNewHubButton);
            }
            else{
                setHubsButtonE(<span></span>);
                setHubsMessageE(<span></span>);
            }
        }   // if(selectedUserNo && indexOfSelectedItem)
    }   // function onClickSelectSearchedItemHandler(...)


    function onClickUnSelectSearchedItemHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        const selectedUserNo = event.currentTarget.dataset.userno;
        const indexOfSelectedItem: string|undefined = event.currentTarget.dataset.index;

        // console.log('hubs.tsx.onClickUnSelectSearchedItemHandler.selectedUserNo:', selectedUserNo);
        // console.log('hubs.tsx.onClickUnSelectSearchedItemHandler.indexOfSelectedItem:', indexOfSelectedItem);

        if(selectedUserNo && indexOfSelectedItem){
            const targetIdx = selectedFriendList.current.indexOf(parseInt(selectedUserNo));
            selectedFriendList.current.splice(targetIdx, 1);

            const index: number = parseInt(indexOfSelectedItem);

            setHubsSearchResultE((prevState) => {

                const targetItem: friendSearchResultType = searchedFriends.current[(searchResultCurPageNo.current - 1)*searchResultsPerPage + index];
                
                const selectBtn: JSX.Element = <Button onClick={onClickSelectSearchedItemHandler} data-userno={targetItem["userNo"]} data-index={index}>선택</Button>;

                const selectedItem: JSX.Element = 
                    <div className="hubs_search_result" key={targetItem["userNo"]}>
                        <Row align="middle">
                            <Col className="search_result_pic" 
                                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                <Avatar
                                    size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                    src={<Image src={targetItem["profPic"]} alt="search result_profile_pic"/>}
                                />
                            </Col>
                            <Col className="search_result_username"
                                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                            >
                                {targetItem["userName"]}
                            </Col>
                            <Col
                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                            >
                            </Col>
                            <Col className="search_result_add_button"
                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                {selectBtn}
                            </Col>
                        </Row>
                    </div>;

                prevState.splice(index, 1, selectedItem);

                return [...prevState];
            });

            // console.log('hubs.tsx.onClickUnSelectSearchedItemHandler.selectedFriendList:', selectedFriendList.current);

            if(selectedFriendList.current.length === 0){
                setFriendsE(createHubsItems(retrievedHubs.current));
                setHubsButtonE(<span></span>);
                setHubsMessageE(<span></span>);
            }
        }   // if(selectedUserNo && indexOfSelectedItem)
    }   // function onClickUnSelectSearchedItemHandler(...)


    function displaySearchedItems(searchResultArray: Array<friendSearchResultType>): JSX.Element[] {

        // console.log('hubs.tsx.displaySearchedItems.searchResultCurPageNo:', searchResultCurPageNo.current);
        // console.log('hubs.tsx.displaySearchedItems.searchResultArray:%o(%s)', searchResultArray, typeof(searchResultArray));

        const startIdx = (searchResultCurPageNo.current - 1)*searchResultsPerPage;
        const endIdx = startIdx + searchResultsPerPage;

        const targetSearchResultArray: Array<friendSearchResultType> = searchResultArray.slice(startIdx, endIdx);

        // console.log('hubs.tsx.displaySearchedItems.targetSearchResultArray:%o(%s)', targetSearchResultArray, typeof(targetSearchResultArray));

        let retE: JSX.Element[] = [];

        if(targetSearchResultArray.length > 0 && userNo){
            retE = targetSearchResultArray.map((searchedItem: friendSearchResultType, index: number) => {

                const addSelectBtn: boolean = searchedItem.userNo === userNo ? false : true;

                const selectBtn: JSX.Element = selectedFriendList.current.indexOf(searchedItem["userNo"]) >= 0 ? <Button onClick={onClickUnSelectSearchedItemHandler} danger data-userno={searchedItem["userNo"]} data-index={index}>해제</Button> : <Button onClick={onClickSelectSearchedItemHandler} data-userno={searchedItem["userNo"]} data-index={index}>선택</Button>;

                if(searchedItem["userNo"]){
                    return(<div className="hubs_search_result" key={searchedItem["userNo"]}>
                        <Row align="middle">
                            <Col className="search_result_pic" 
                                xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                <Avatar
                                    size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                    src={<Image src={searchedItem["profPic"]} alt="search result_profile_pic"/>}
                                />
                            </Col>
                            <Col className="search_result_username"
                                xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                                lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                            >
                                {searchedItem["userName"]}
                            </Col>
                            <Col
                                xs={{span: 4}} sm={{span: 4}} md={{span: 4}}
                                lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                            >
                            </Col>
                            <Col className="search_result_add_button"
                                xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                                lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                            >
                                {addSelectBtn && selectBtn}
                            </Col>
                        </Row>
                    </div>);
                }
                else{
                    return(<div className="hubs_search_result"></div>);
                }
            });

        }
        else{
            retE.push(
                <Row align="middle" key={0}>
                    <Col span={4}></Col>
                    <Col className="search_result_not_found" span={16}>입력하신 조건과 일치하는 친구가 아직 없어요 😢🤦‍♀️🤦‍♂️</Col>
                    <Col span={4}></Col>
                </Row>
            );
        }

        return retE;
    }   // function displaySearchedItems(...)


    function onClickSRPageIndexHandler(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        const newSRPageNo: string|undefined = event.currentTarget.dataset.srpageno;

        if(newSRPageNo){
            // setSRCurPageNo(parseInt(newSRPageNo));
            searchResultCurPageNo.current = parseInt(newSRPageNo);

            setHubsSearchResultE(displaySearchedItems(searchedFriends.current));
            setHubsSearchResultPageIndexE(displaySearchResultPageIndexE(parseInt(newSRPageNo)));
        }
    }


    function displaySearchResultPageIndexE(curSRPageNo: number): Array<JSX.Element> {

        const SRPageIndexEList: Array<JSX.Element> = [];

        if(searchedFriends.current.length > 0){
            const lastSRPageNo = Math.ceil(searchedFriends.current.length / searchResultsPerPage);
            const firstSRPageNo = 1;
            const prevPageNumEllipsis: string = '...';
            const nextPageNumEllipsis: string = '...';

            if(curSRPageNo > firstSRPageNo){
                SRPageIndexEList.push(
                    <span id="hubs_search_result_page_index_first_page" key={0}>
                        <a onClick={onClickSRPageIndexHandler} data-srpageno={firstSRPageNo}>{firstSRPageNo}</a>
                    </span>
                );
            }

            if(curSRPageNo - firstSRPageNo > 1){
                const prevSRPageNo = curSRPageNo - 1;

                if(prevSRPageNo - firstSRPageNo > 1){
                    SRPageIndexEList.push(<span key={1}>{prevPageNumEllipsis}</span>);
                }

                SRPageIndexEList.push(
                    <span id="hubs_search_result_page_index_prev_page" key={2}>
                        <a onClick={onClickSRPageIndexHandler} data-srpageno={prevSRPageNo}>{prevSRPageNo}</a>
                    </span>
                );
            }

            if(curSRPageNo){
                SRPageIndexEList.push(
                    <span id="hubs_search_result_page_index_cur_page" key={3}>{curSRPageNo}</span>
                );
            }

            if(lastSRPageNo - curSRPageNo > 1){
                const nextSRPageNo = curSRPageNo + 1;
                SRPageIndexEList.push(
                    <span id="hubs_search_result_page_index_next_page" key={4}>
                        <a onClick={onClickSRPageIndexHandler} data-srpageno={nextSRPageNo}>{nextSRPageNo}</a>
                    </span>
                );

                if(lastSRPageNo - nextSRPageNo > 1){
                    SRPageIndexEList.push(<span key={5}>{nextPageNumEllipsis}</span>);
                }
            }

            if(lastSRPageNo > curSRPageNo) {
                SRPageIndexEList.push(
                    <span id="hubs_search_result_page_index_last_page" key={6}>
                        <a onClick={onClickSRPageIndexHandler} data-srpageno={lastSRPageNo}>{lastSRPageNo}</a>
                    </span>
                );
            }
        }

        return SRPageIndexEList;
    }   // function displaySearchResultPageIndexE(...)


    function onChangeSearchInputHandler(event: React.ChangeEvent<HTMLInputElement>){
        setSearchInputValue(event.currentTarget.value);
    }


    function onClickCreateNewHubButtonHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        const candidateList: Array<number> = new Array();

        if(userNo && selectedFriendList.current.length > 0){
            candidateList.push(userNo);
            candidateList.push(...selectedFriendList.current);

            // console.log('hubs.tsx.onClickCreateNewHubButtonHandler().cadidateList:', candidateList);

            createNewHub(candidateList).then((resBody: addFriendsResBodyType) => {
                if(resBody["success"] === true){
                    selectedFriendList.current = [];
                    searchedFriends.current = [];
                    searchedFriendsUserNos.current = [];
                    searchResultCurPageNo.current = 1;
                    setFriendsE(createHubsItems(resBody["hubs"]));
                    setPageIndexE(createPageIndex(resBody["pageIndex"]));
                    setHubsSearchResultE([]);
                    setHubsSearchResultPageIndexE([]);
                    setHubsButtonE(<div></div>);
                    setHubsMessageE(<span></span>);
                }
                else{
                    if(resBody["message"].length > 0){
                        setHubsMessageE(<span>{resBody["message"]}</span>);
                    }
                }
            }).catch((error) => {
                console.log('[Error]hubs.tsx.onClickCreateNewHubButtonHandler().error', error);
            });
        }

    }   // function onClickCreateNewHubButtonHandler(...)


    async function getHubs(targetPageNo: number, numOfItemsPerPage: number): Promise<getHubsResBodyType> {

        let resBody: getHubsResBodyType = {
            success: false
            , hubs: []
            , pageIndex: {
                curPageNum: 1
            }
            , me: {
                userName: ''
                , profPic: ''
            }
        };

        // let resBody = {};

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("curPageNo", targetPageNo.toString());
            formData.set("hubsPerPage", numOfItemsPerPage.toString());

            const response = await fetch('/api/friends/gethubs', {
                method: 'POST'
                , headers: headerData
                , body: formData
            });

            resBody = await response.json();
        }

        return resBody;
    }   // async function getHubs(...)


    async function searchFriends(): Promise<searchFriendResBodyType> {

        let resBody: searchFriendResBodyType = {
            success: false
            , searchResults: []
            , searchResultUserNos: []
        };

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            const targetPat = /-+|\s+/g;
            const searchInputValWOTargetPat = searchInputValue.replace(targetPat, '');
            const searchInputValArray = searchInputValWOTargetPat.split(',');
            const searchInputValArrayJSON = JSON.stringify(searchInputValArray);

            // console.log('SearchHubs.tsx.searchFriends.searchInputValArray:', searchInputValArray);

            headerData.set("authData", authToken);
            formData.set("candidatesJSON", searchInputValArrayJSON);

            const response = await fetch('/api/friends/search', {
                method: 'POST'
                , headers: headerData
                , body: formData
            });

            resBody = await response.json();
        }

        return resBody;
    }   // async function searchFriends(...)


    async function addFriends(candidates: Array<number>, expectedResult: Array<number>, hubNo: number){

        let resBody: addFriendsResBodyType = {
            success: false
            , hubs: []
            , pageIndex: {
                curPageNum: 1
            }
            , message: ''
        };

        // let resBody = {};

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("hubNo", hubNo.toString());
            formData.set("curPageNo", curPageNo.toString());
            formData.set("hubsPerPage", hubsPerPage.toString());
            formData.set("friendsJSON", JSON.stringify(candidates));
            formData.set("expectedResult", JSON.stringify(expectedResult));

            const response = await fetch('/api/friends/addmatching', {
                method: 'POST'
                , headers: headerData
                , body: formData
            });

            resBody = await response.json();
        }

        return resBody;
    }   // async function addFriends(...)

    async function createNewHub(candidates: Array<number>){

        let resBody: addFriendsResBodyType = {
            success: false
            , hubs: []
            , pageIndex: {
                curPageNum: 1
            }
            , message: ''
        };

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("curPageNo", curPageNo.toString());
            formData.set("hubsPerPage", hubsPerPage.toString());
            formData.set("candidatesJSON", JSON.stringify(candidates));

            const response = await fetch('/api/friends/createhub', {
                method: 'POST'
                , headers: headerData
                , body: formData
            });

            resBody = await response.json();
        }

        return resBody;
    }   // async function createNewHub(...)


    async function disjoinFromHub(targetHubNo: number){

        let resBody: addFriendsResBodyType = {
            success: false
            , hubs: []
            , pageIndex: {
                curPageNum: 1
            }
            , message: ''
        };

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("curPageNo", curPageNo.toString());
            formData.set("hubsPerPage", hubsPerPage.toString());
            formData.set("targetHubNo", targetHubNo.toString());

            const response = await fetch('/api/friends/disjoin', {
                method: 'POST'
                , headers: headerData
                , body: formData
            });

            resBody = await response.json();
        }

        return resBody;
    }   // async function disjoinFromHub(...) 


    useEffect(() => {
        getHubs(curPageNo, hubsPerPage).then((resBody: getHubsResBodyType) => {
            retrievedHubs.current = resBody["hubs"];
            setMeE(createMeItem(resBody["me"]));
            setFriendsE(createHubsItems(resBody["hubs"]));
            setPageIndexE(createPageIndex(resBody["pageIndex"]));
        }).catch((error) => {
            console.log('[Error]hubs.tsx.useEffect.getHubs():', error);
        });
    }, [isLoggedIn]);


    return(
        <AppLayout>
            <div id="hubs_top_container_div">
                <div id="hubs_me_div">{meE}</div>
                <Divider/>
                <div id="hubs_hubs_div">{friendsE}</div>
                <div id="hubs_page_index_div">{pageIndexE}</div>
                {(retrievedHubs.current.length > 0) && <Divider dashed/>}
                <div id="hubs_search_input_div">
                    <Row id="hubs_search_guide_message_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                        >
                            <span>콤마(,)로 구분하여 입력하면 여러명 검색이 가능합니다.</span>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                        </Col>                            
                    </Row>
                    <Row id="hubs_search_input_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                        >
                            <Search placeholder="메일주소 | 전화번호" onSearch={onClickSearchButtonHandler} onChange={onChangeSearchInputHandler} value={searchInputValue} enterButton/>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                        </Col>                            
                    </Row>
                </div>
                <div id="hubs_search_result_div">{hubsSearchResultE}</div>
                <div id="hubs_search_result_page_index_div">{hubsSearchResultPageIndexE}</div>
                <div id="hubs_button_div">{hubsButtonE}</div>
                <div id="hubs_message_div">{hubsMessageE}</div>
            </div>
        </AppLayout>
    );

}

export default Hubs;

import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router";

import { Button, Collapse, Divider, Input, Switch } from "antd";

import MDSyntaxInfo from "../components/md_syntax_info";
import MarkingDown from "../components/md_editor3p1_ems";
import AppLayout from "../components/app_layout";

import "../css/create_note.css";
import "../css/md_editor3.css";


function LookaroundCreateNote(): JSX.Element {

    const navigate = useNavigate();

    const { TextArea } = Input;
    const { Panel } = Collapse;

    const [title, setTitle] = useState<string>('');
    const [titleMsg, setTitleMsg] = useState<string>('');

    const [desc, setDesc] = useState<string>('');
    const [MDOutput, setMDOutput] = useState<{__html: string}>({__html: ''});

    const [updateMsg, setUpdateMsg] = useState<string>('');

    // const [periodicAutoUpdate, setPAU] = useState<boolean>(false);



    function onChangeTitleInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setTitle(event.currentTarget.value);
        setTitleMsg('');
    }

    function onChangeDescInputHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        setDesc(event.currentTarget.value);
    }

    function onChangePAUSwitch(checked: boolean){
        // setPAU(checked);

        if(checked){
            setUpdateMsg('1분 주기로 노트가 저장됩니다.');
        }
        else{
            setUpdateMsg('자동 저장이 취소되었습니다.');
        }
    }

    function onClickSubmitBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        setUpdateMsg('둘러보기에서는 노트 저장 기능이 지원되지 않습니다.');
    }

    const onClickHomeButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/home');
    };

    function renderMDOutput() {
        const md = new MarkingDown();
        md.getData(desc);
        let mdResult = md.doMarkingDown();

        setMDOutput({__html: mdResult});
    }

    useEffect(() => {
        renderMDOutput();
    }, [desc]);


    return(
        <AppLayout>
            <div id="lookaround_create_note_top_container_div">
                <div id="create_note_title_div">
                    <Input placeholder="제목" allowClear value={title} onChange={onChangeTitleInputHandler}></Input>
                </div>
                <div id="create_note_title_msg_div">{titleMsg}</div>
                <Divider></Divider>
                <div id="create_note_desc_input_div">
                    <TextArea showCount maxLength={5000} onChange={onChangeDescInputHandler} autoSize={true}></TextArea>
                </div>
                <div id="create_note_desc_auxiliary_div">
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="마크다운 결과 보기" key="1">
                        {/* <Panel header="마크다운 결과 보기" id="editor_output" key="1"> */}
                            {/* <p id="create_note_MDOutput_p" dangerouslySetInnerHTML={MDOutput}></p> */}
                            <p id="editor_output" dangerouslySetInnerHTML={MDOutput}></p>
                        </Panel>
                        <Panel header="마크다운 문법 참고" key="2">
                            <MDSyntaxInfo></MDSyntaxInfo>
                        </Panel>
                    </Collapse>
                </div>
                <Divider></Divider>
                {/* <AudioManager transferRecordedData={transferRecordedData} removeRecordedData={removeRecordedData} isPlayOnly={false} onHold={onUploading} extAudioBlobURL={''}></AudioManager>
                <Divider></Divider> */}
                <div id="create_note_update_msg_div">
                    {updateMsg.length === 0 ? <span>&nbsp;</span> : updateMsg}
                </div>
                <div id="create_note_button_div">
                    자동 저장&nbsp;<Switch disabled={false} onChange={onChangePAUSwitch}/>
                    <Button type="primary" disabled={false} htmlType="submit" onClick={onClickSubmitBtnHandler}>저장하기</Button>
                    <Button onClick={onClickHomeButtonHandler}>홈으로</Button>
                </div>
            </div>
        </AppLayout>
    );
}

export default LookaroundCreateNote;

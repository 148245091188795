import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { RootStateType } from "..";

import { Card, Divider, Row, Col, Button } from "antd";

import AppLayout from "../components/app_layout";
import LookaroundHubs from "../components/lookaround_hubs";
import Notice from "../components/notice";

import "../css/home.css";


function Home() {

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const [numOfNoticeItems, setNumOfNoticeItems] = useState<number>(0);
    const [showNoticeBtnLabel, setShowNoticeBtnLabel] = useState<string>('공지 감추기');

    function setNoticeItemLength(value: number){
        setNumOfNoticeItems(value);
    }

    function onClickShowNoticeBtnHandler() {
        const noticeDiv = document.getElementById('notice_div');

        // if(noticeDiv){
        //     noticeDiv.classList.toggle('display');
        //     noticeDiv.classList.toggle('hide');
        // }

        if(showNoticeBtnLabel === '공지 보기'){
            if(noticeDiv){
                // noticeDiv.style.display = 'block';
                // noticeDiv.style.height = `${noticeDiv.offsetHeight}px`;
                noticeDiv.style.height = `${noticeDiv.scrollHeight}px`;
            }
            setShowNoticeBtnLabel('공지 감추기');
        }
        else{
            if(noticeDiv){
                // noticeDiv.style.display = 'none';
                noticeDiv.style.height = '0px';
            }
            setShowNoticeBtnLabel('공지 보기');
        }
    }

    // type dtTestType = {
    //     success: boolean
    //     , testNum: number
    //     , testStr: string
    // };

    // useEffect(() => {

    //     fetch('/api/home/senddata', {
    //         method: 'GET'
    //     }).then((response) => {
    //         return response.json();
    //     }).then((resBody: dtTestType) => {
    //         if(resBody["success"] === true){
    //             console.log('home.tsx.useEffect.fetch(/api/home/senddata).resBody:', resBody);
    //             console.log('home.tsx.useEffect.fetch(/api/home/senddata).typeof(resBody[testNum]):', typeof(resBody["testNum"]));
    //             console.log('home.tsx.useEffect.fetch(/api/home/senddata).resBody[testNum]:', resBody["testNum"]);
    //             console.log('home.tsx.useEffect.fetch(/api/home/senddata).typeof(resBody[testStr]):', typeof(resBody["testStr"]));
    //             console.log('home.tsx.useEffect.fetch(/api/home/senddata).resBody[testStr]:', resBody["testStr"]);
    //         }
    //     }).catch((error) => {
    //         console.log('[Error]home.tsx.useEffect.fetch(/api/home/senddata):', error);
    //     })
    // }, []);

    return(
        <AppLayout>
            <div id="home_top_container_div">
                <p>
                    디스쿠르는 토론, 공유, 이해와 공감을 위해 만들어졌습니다.<br/>
                    디스쿠르를 아래와 같이 사용할 수 있습니다.<br/>
                </p>
                <ul>
                    <li>언제 어디서나 노트, 회의록 작성</li>
                    <li>작성한 노트 친구와 공유하기</li>
                    <li>대화와 토론, 그리고 더 나은 방법 찾아가기</li>
                </ul>
                <p>디스쿠르는 크롬에서 가장 원활하게 동작합니다.</p>               
            </div>
            {/* <Divider></Divider> */}
            { !isLoggedIn &&
                //  <div id="home_lookaround_hubs_div">
                <Row id="home_lookaround_hubs_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                    >
                        <Card title="둘러보기" bordered={true}>
                            <LookaroundHubs></LookaroundHubs>
                        </Card>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                </Row>
                //  </div>
            }
            { isLoggedIn &&
                <div id="home_notice_div">
                    <Row id="home_divider_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                        >
                            <Divider></Divider>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                        >
                        </Col>
                    </Row>
                    <Row id="home_notice_contents_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                        >
                            <Notice reportItemLength={setNoticeItemLength}></Notice>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                        >
                        </Col>
                    </Row>
                    <Row id="home_notice_button_div">
                        <Col
                            xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                            lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                        >
                            {numOfNoticeItems > 0 &&
                                <div id="agora_show_notice_button_div">
                                    <Button type="primary" onClick={onClickShowNoticeBtnHandler}>{showNoticeBtnLabel}</Button>
                                </div>
                            }    
                        </Col>
                    </Row>
                </div>
            }
        </AppLayout>
    );
}

export default Home;

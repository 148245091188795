

export class ImageResizer {

    // static resize(file: File, targetWidth: number, targetHeight: number, cb: Function) {
    static resize(file: File, targetWidth: number, targetHeight: number): Promise<Blob> {

        // let image = document.createElement('img');

        // image.onload = (event) => {
        //     let width = image.width;
        //     let height = image.height;

        //     console.log('ImageResizer.targetImage.width: %d, height: %d', width, height);

        //     let getWidthHeight = (img: HTMLImageElement, targetSize: number): Array<number> => {
        //         const { width, height } = img;
        //         let positions = [0, 0, 0, 0];
        //         if(width === height){
        //             positions = [0, 0, targetSize, targetSize];
        //             // return([0, 0, targetSize, targetSize]);
        //         }
        //         else{
        //             if(width < height){
        //                 let ratio = height/width;
        //                 let top = ((targetSize*ratio) - targetSize)/2;
        //                 positions = [0, -1*top, targetSize, targetSize*ratio];
        //                 // return([0, -1*top, targetSize, targetSize*ratio]);
        //             }
        //             else{
        //                 let ratio = width/height;
        //                 let left = ((targetSize*ratio) - targetSize)/2;
        //                 positions = [-1*left, 0, targetSize*ratio, targetSize];
        //                 // return([-1*left, 0, targetSize*ratio, targetSize]);
        //             }
        //         }

        //         return positions;
        //     };

        //     let canvas = document.createElement('canvas');
        //     canvas.width = targetWidth;
        //     canvas.height = targetHeight;

        //     let context = canvas.getContext('2d');

        //     if(context){
        //         const coordinates = getWidthHeight(image, 235);

        //         context.imageSmoothingEnabled = true;
        //         context.imageSmoothingQuality = 'high';
        //         // context.drawImage(image, ...getWidthHeight(image, 235));
        //         context.drawImage(image, coordinates[0], coordinates[1], coordinates[2], coordinates[3]);
        //     }

        //     let hasToBlobSupport = (typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false);
        //     let hasBlobSupport = hasToBlobSupport || (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined');

        //     if(hasToBlobSupport){
        //         canvas.toBlob((blob) => {
        //             cb(blob);
        //         }, file.type)
        //     }
        //     else{
        //         let blob = ImageResizer.toBlob(canvas, file.type);
        //         cb(blob);
        //     }
        // };

        // ImageResizer.loadImage(image, file);

        // return true;

        // type loadedImage = {
        //     success: boolean,
        //     image: HTMLImageElement
        // }        

        return new Promise((resolve, reject) => {
            let image = document.createElement('img');

            ImageResizer.loadImage(image, file).then((result) => {
                if(result === true){
                    let width = image.width;
                    let height = image.height;

                    // console.log('ImageResizer.targetImage.width: %d, height: %d', width, height);

                    let getWidthHeight = (img: HTMLImageElement, targetSize: number): Array<number> => {
                        const { width, height } = img;
                        let positions = [0, 0, 0, 0];
                        if(width === height){
                            positions = [0, 0, targetSize, targetSize];
                            // return([0, 0, targetSize, targetSize]);
                        }
                        else{
                            if(width < height){
                                let ratio = height/width;
                                let top = ((targetSize*ratio) - targetSize)/2;
                                positions = [0, -1*top, targetSize, targetSize*ratio];
                                // return([0, -1*top, targetSize, targetSize*ratio]);
                            }
                            else{
                                let ratio = width/height;
                                let left = ((targetSize*ratio) - targetSize)/2;
                                positions = [-1*left, 0, targetSize*ratio, targetSize];
                                // return([-1*left, 0, targetSize*ratio, targetSize]);
                            }
                        }

                        return positions;
                    };

                    let canvas = document.createElement('canvas');
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;

                    let context = canvas.getContext('2d');

                    if(context){
                        const coordinates = getWidthHeight(image, 235);

                        context.imageSmoothingEnabled = true;
                        context.imageSmoothingQuality = 'high';
                        // context.drawImage(image, ...getWidthHeight(image, 235));
                        context.drawImage(image, coordinates[0], coordinates[1], coordinates[2], coordinates[3]);
                    }

                    let hasToBlobSupport = (typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false);
                    let hasBlobSupport = hasToBlobSupport || (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined');

                    if(hasToBlobSupport){
                        // canvas.toBlob((blob) => {
                        //     cb(blob);
                        // }, file.type)

                        canvas.toBlob((blob) => {
                            if(blob) {
                                resolve(blob);
                            }
                        }, file.type)
                    }
                    else{
                        let blob = ImageResizer.toBlob(canvas, file.type);
                        // cb(blob);
                        resolve(blob);
                    }
                }
                else{
                    reject(new Error('Loading image failed.'));
                }
            }).catch((error) => {console.log('[Error]ImageResizer:', error)});
        });

        // return true;

    }   // static resize(file: File, targetWidth: number, targetHeight: number): Promise<Blob> {

    static toBlob(canvas: HTMLCanvasElement, type: string) {
        let dataURI = canvas.toDataURL(type);
        let dataURIParts = dataURI.split(',');
        let byteString;

        if(dataURIParts[0].indexOf('base64') >= 0){
            // Convert base64 to raw binary data held in a string
            byteString = atob(dataURIParts[1]);
        }
        else{
            // Convert base64/URLEncoded data component to raw binary data
            byteString = decodeURIComponent(dataURIParts[1]);
        }

        let arrayBuffer = new ArrayBuffer(byteString.length);
        let intArray = new Uint8Array(arrayBuffer);

        for(let i = 0; i < byteString.length; i = i + 1){
            intArray[i] = byteString.charCodeAt(i);
        }

        let mimeString = dataURIParts[0].split(':')[1].split(';')[0];
        let blob = null;

        let hasBlobConstructor = typeof Blob !== 'undefined' && (function () {
            try{
                return Boolean(new Blob());
            } catch(e){
                return false;
            }
        }());

        let hasArrayBufferViewSupport = hasBlobConstructor && typeof Uint8Array !== 'undefined' && (function (){
            try{
                return new Blob([new Uint8Array(100)]).size === 100;
            } catch(e){
                return false;
            }
        }());

        if(hasBlobConstructor){
            blob = new Blob(
                [hasArrayBufferViewSupport ? intArray : arrayBuffer], {type: mimeString}
            );
        }
        else{
            //  ? new Blob([intArray]). arrayBuffer seems empty.
            blob = new Blob([arrayBuffer]);
        }

        return blob;
    }

    static loadImage(image: HTMLImageElement, file: File) {
        // if(typeof URL === 'undefined'){
        //     let reader = new FileReader();
        //     reader.onload = (event) => {
        //         if(event.target && typeof event.target.result === 'string'){
        //             image.src = event.target.result;
        //         }
        //     };
        //     reader.readAsDataURL(file);
        // }
        // else{
        //     image.src = URL.createObjectURL(file);
        // }

        return new Promise((resolve, reject) => {

            image.onload = (event) => {
                // console.log('Signup.ImageResizer.loadImage.event:', event);
                // console.log('Signup.ImageResizer.loadImage.image.(width: %d, height: %d):', image.width, image.height);
                resolve(true);
            };

            if(typeof URL === 'undefined'){
                let reader = new FileReader();
                reader.onload = (event) => {
                    if(event.target && typeof event.target.result === 'string'){
                        image.src = event.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }
            else{
                image.src = URL.createObjectURL(file);
            }
        });
    }
}

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';


export type TopicListState = {
    curPageNo: number
    , selectedTopicNo: number|null
    , selectedTopicTitle: string
};

const topicListInitialState: TopicListState = {
    curPageNo: 1
    , selectedTopicNo: null
    , selectedTopicTitle: ''
};

const topicListInfoSlice = createSlice({
    name: 'topicListInfo'
    , initialState: topicListInitialState
    , reducers: {
        setTopicListInfo: {
            reducer: (state, action: PayloadAction<TopicListState>) => {
                state.curPageNo = action.payload.curPageNo;
                state.selectedTopicNo = action.payload.selectedTopicNo;
                state.selectedTopicTitle = action.payload.selectedTopicTitle;
            }
            , prepare: (curPageNo: number, selectedTopicNo: number|null, selectedTopicTitle: string) => {
                return({payload: {curPageNo, selectedTopicNo, selectedTopicTitle}});
            }
        }
        , resetTopicListInfo: {
            reducer: (state, action: PayloadAction<TopicListState>) => {
                state.curPageNo = action.payload.curPageNo;
                state.selectedTopicNo = action.payload.selectedTopicNo;
                state.selectedTopicTitle = action.payload.selectedTopicTitle;
            }
            , prepare: () => {
                return({payload: topicListInitialState});
            }
        }
    }
});

export const { setTopicListInfo, resetTopicListInfo } = topicListInfoSlice.actions;

export default topicListInfoSlice.reducer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootStateType } from "..";

import AppLayout from "../components/app_layout";

import { Row, Col, Input, Button } from "antd";

import "../css/reqpwreset.css";


function ReqPWReset(): JSX.Element {

    let [email, setEmail] = useState<string>('');
    let [message, setMessage] = useState<string>('');

    const navigate = useNavigate();

    const isLoggedIn = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    const onEmailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setMessage('');
        setEmail(event.currentTarget.value);
    }

    const onClickCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/home');
    }

    const onClickSubmitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let formData = new FormData();
        
        formData.append("email", email);

        fetch('/api/user/reqpwreset', {
            method: 'POST',
            body: formData
        }).then((response) => {
            return response.json();
            // navigate('/home');
        }).then((resBody) => {
            if(resBody["success"] === true) {
                navigate('/home');
            }
            else{
                setMessage('가입 정보가 없습니다. 이메일을 확인해주세요.');
            }
        }).catch((error) => {
            console.log('[Error]reqPWReset:', error);
        });
    }

    useEffect(() => {
        if(isLoggedIn){
            navigate('/home');
        }
    }, [isLoggedIn]);

    return(
        <AppLayout>
            <Row id="reqpwreset_notice_div">
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                >
                </Col>
                <Col
                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                    lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                >
                    <p>등록하신 이메일 주소로 임시 비밀번호를 보내드립니다.</p>
                    <p>임시 비밀번호로 로그인하신 후 비밀번호를 반드시 변경해주세요.</p>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                >
                </Col>
            </Row>
            <Row id="reqpwreset_email_input_div">
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 6}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}               
                >
                </Col>
                <Col
                    xs={{span: 22}} sm={{span: 22}} md={{span: 12}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}               
                >
                    <Input type="text" onChange={onEmailChangeHandler} addonBefore="email"></Input>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 6}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}               
                >
                </Col>
            </Row>
            <Row id="reqpwreset_msg_div">
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                >
                </Col>
                <Col
                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                    lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                >
                    {message}
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                >
                </Col>
            </Row>
            <Row id="reqpwreset_button_div">
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                >
                </Col>
                <Col
                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                    lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                >
                    <Button type="primary" onClick={onClickSubmitHandler}>제출</Button>
                    <Button type="default" onClick={onClickCancelHandler}>취소</Button>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                >
                </Col>
            </Row>
        </AppLayout>
    );
}

export default ReqPWReset;

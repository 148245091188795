import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootStateType, AppDispatchType } from "..";
import { useNavigate } from "react-router";

// import { setTopicListInfo, topicListState } from "../reducers/topic_list_info";
import { setTopicListInfo, TopicListState } from "../features/topic_list_info";

import { Button, Divider, Input, Row, Col } from "antd";

import AppLayout from "../components/app_layout";
import Topic from "../components/topic";

import "../css/topics.css";


function Topics() {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => state.auth.authToken);

    // const curPageNo: number = useSelector<RootStateType, number>(state => state.topicList.curPageNo);
    const curPageNo: number = useSelector<RootStateType, number>((state) => {
        let _curPageNo = state.topicList.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessTopicListInfo = sessionStorage.getItem("topicListInfo");
            if(sessTopicListInfo){
                const parsedSessTopicListInfo: TopicListState = JSON.parse(sessTopicListInfo);
                _curPageNo = parsedSessTopicListInfo["curPageNo"];
            }
        }

        return _curPageNo;
    });

    const [topicTitle, setTopicTitle] = useState<string>('');

    const itemsPerPage: number = 8;

    // const [showCreateTopicE, setShowCreateTopicE] = useState<Boolean>(false);

    // const createTopicBtnDiv: JSX.Element = <div id="topics_button_div">
    //     <Button type="primary" onClick={onClickCreateTopicHandler}>
    //         주제 생성
    //     </Button>
    // </div>;

    const [topics, setTopics] = useState<JSX.Element>(<div></div>);
    const [pageIndexes, setPageIndexes] = useState<JSX.Element>(<div></div>);
    // const [createTopicComponent, setCreateTopicComponent] = useState<JSX.Element>(createTopicBtnDiv);

    // const storedTopicList = useRef<Array<subjectDataType>>([]);


    type subjectDataType = {
        subjectNo: number
        , title: string
    };

    function createTopicLines(topicList: Array<subjectDataType>): JSX.Element {
        const topicComponents = topicList.map((item) => {
            return(
                <Topic subjectNo={item["subjectNo"]} title={item["title"]} key={item["subjectNo"]}></Topic>
            );
        });

        return(
            <div id="topics_div">
                {topicComponents}
            </div>
        );
    }

    function onClickPageIndexHandler(event: React.MouseEvent<HTMLElement>){
        event.preventDefault();

        let newPageNo: number = event.currentTarget.dataset.pageno ? parseInt(event.currentTarget.dataset.pageno) : 0;

        dispatch(setTopicListInfo(newPageNo, null, ''));
    }

    type pageIndexDataType = {
        firstPageNum?: number
        , prevPageNum?: number
        , curPageNum: number
        , nextPageNum?: number
        , lastPageNum?: number
    };

    function createPageIndex(pageIndexes: pageIndexDataType){
        const firstPageNum: number|null = pageIndexes["firstPageNum"] ? pageIndexes["firstPageNum"] : null;
        const prevPageNum: number|null = pageIndexes["prevPageNum"] ? pageIndexes["prevPageNum"] : null;
        const curPageNum: number = pageIndexes["curPageNum"];
        const nextPageNum: number|null = pageIndexes["nextPageNum"] ? pageIndexes["nextPageNum"] : null;
        const lastPageNum: number|null = pageIndexes["lastPageNum"] ? pageIndexes["lastPageNum"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPageNum && prevPageNum){
            prevEllipsis = (prevPageNum - firstPageNum) > 1 ? '...' : null;
        }
        if(lastPageNum && nextPageNum){
            nextEllipsis = (lastPageNum - nextPageNum) > 1 ? '...' : null;
        }

        return(
            <div id="page_index_div">
                {firstPageNum &&
                    <span id="topics_page_index_first_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={firstPageNum}>{firstPageNum}</a>
                    </span>
                }
                {prevEllipsis && <span id="topics_page_index_first_prev_ellipsis">{prevEllipsis}</span>}
                {prevPageNum &&
                    <span id="topics_page_index_prev_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={prevPageNum}>{prevPageNum}</a>
                    </span>
                }
                <span id="topics_page_index_cur_page">{curPageNum}</span>
                {nextPageNum &&
                    <span id="topics_page_index_next_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={nextPageNum}>{nextPageNum}</a>
                    </span>
                }
                {nextEllipsis && <span id="topics_page_index_next_last_ellipsis">{nextEllipsis}</span>}
                {lastPageNum &&
                    <span id="topics_page_index_last_page">
                        <a onClick={onClickPageIndexHandler} data-pageno={lastPageNum}>{lastPageNum}</a>
                    </span>
                }
            </div>
        );
    }

    // function topicCancelCreationHandler() {
    //     setCreateTopicComponent(createTopicBtnDiv);
    // }

    // function onClickCreateTopicHandler(event: React.MouseEvent<HTMLElement>){
    //     // console.log('topics.tsx.onClickCreateTopicHandler().');
    //     // setCreateTopicComponent(<CreateTopic creationDone={topicCreationDoneHandler} cancelCreation={topicCancelCreationHandler}></CreateTopic>);

    //     // setCreateTopicComponent(createTopicInputDiv);

    //     setShowCreateTopicE(true);
    // }

    function onChangeTopicTitleInputHandler(event: React.ChangeEvent<HTMLInputElement>){
        event.preventDefault();

        setTopicTitle(event.currentTarget.value);
    }

    type createTopicResBodyType = {
        success: boolean
        // , subjectNo: number
        // , title: string
        , subjects: Array<subjectDataType>
        , pageIndex: pageIndexDataType
    };

    function onClickCreateTopicBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        
        if(topicTitle.length > 0){

            if(authToken){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);

                formData.set("title", topicTitle);
                formData.set("itemsPerPage", itemsPerPage.toString())

                fetch('/api/topic/create', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return(response.json());
                }).then((resBody: createTopicResBodyType) => {
                    // console.log('create_topic.tsx.onClickCreateTopicBtnHandler().resBody:', resBody);
                    if(resBody["success"] === true){
                        // console.log('create_topic.tsx.onClickCreateTopicBtnHandler().(subjectNo:%s, title:%s):', resBody["subjectNo"].toString(), resBody["title"]);
                        // prop.creationDone(resBody["subjectNo"], resBody["title"]);
                        setTopics(createTopicLines(resBody["subjects"]));
                        setPageIndexes(createPageIndex(resBody["pageIndex"]));
                        // setCreateTopicComponent(createTopicBtnDiv);
                        setTopicTitle('');
                        // setShowCreateTopicE(false);
                    }
                }).catch((error) => {
                    console.log('[Error]create_topic.tsx.onClickCreateTopicBtnHandler:', error);
                });
            }
        }
    }


    type gettopicsResBodyType = {
        success: boolean
        , subjects: Array<subjectDataType>
        , pageIndex: pageIndexDataType
    };

    useEffect(() => {
        let headerData = new Headers();
        
        if(authToken){
            headerData.set("authData", authToken);

            let formData = new FormData();

            formData.set("curPageNo", curPageNo.toString());
            formData.set("itemsPerPage", itemsPerPage.toString())

            fetch('/api/topic/gettopics', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody: gettopicsResBodyType) => {
                // console.log('topics.tsx.useEffect(/api/topic/gettopics).resBody:', resBody);
                if(resBody["success"] == true){
                    // console.log('topics.tsx.useEffect(/api/topic/gettopics).resBody["subjects"]:', resBody["subjects"]);
                    // console.log('topics.tsx.useEffect(/api/topic/gettopics).resBody["pageIndex"]:', resBody["pageIndex"]);
                    // storedTopicList.current = resBody["subjects"];
                    setTopics(createTopicLines(resBody["subjects"]));
                    setPageIndexes(createPageIndex(resBody["pageIndex"]));
                }
            }).catch((error) => {
                console.log('[Error]topics.tsx.useEffect(/api/topic/gettopics):', error);
            });
        }

    }, [curPageNo, isLoggedIn]);

    return(
        <AppLayout>
            <div id="topics_top_container_div">
                <div id="topics_topics_div">{topics}</div>
                <div id="topics_page_index_div">{pageIndexes}</div>
                <Divider></Divider>
                <div id="topics_create_topic_component">
                   <Row>
                        <Col
                            xs={{span: 0}} sm={{span: 0}} md={{span: 4}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}                   
                        >
                        </Col>
                        <Col
                            xs={{span: 24}} sm={{span: 24}} md={{span: 16}}
                            lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                        >
                            <Row>
                                <Col
                                    xs={{span: 0}} sm={{span: 0}} md={{span: 2}}
                                    lg={{span: 2}} xl={{span: 2}} xxl={{span: 3}}
                                >
                                </Col>
                                <Col id="topics_create_topic_input_div"
                                    xs={{span: 18}} sm={{span: 18}} md={{span: 14}}
                                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 14}}
                                >
                                    <Input maxLength={40} showCount value={topicTitle} onChange={onChangeTopicTitleInputHandler} addonBefore="주제"></Input>
                                </Col>
                                <Col
                                    xs={{span: 0}} sm={{span: 0}} md={{span: 2}}
                                    lg={{span: 2}} xl={{span: 2}} xxl={{span: 3}}
                                >
                                </Col>
                                <Col id="topics_create_topic_button_div"
                                    xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                                >
                                    <Button type="primary" onClick={onClickCreateTopicBtnHandler}>생성</Button>
                                </Col>
                            </Row>
                        </Col>

                        {/* <Col
                            xs={{span: 18}} sm={{span: 18}} md={{span: 12}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}                   
                        >
                            <Input id="topics_create_topic_input_div" maxLength={40} showCount value={topicTitle} onChange={onChangeTopicTitleInputHandler} addonBefore="주제"></Input>
                        </Col>
                        <Col
                            xs={{span: 6}} sm={{span: 6}} md={{span: 4}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}                   
                        >
                            <Button type="primary" id="topics_create_topice_button" onClick={onClickCreateTopicBtnHandler}>생성</Button> */}
                            {/* <Button id="create_topic_cancel_button" onClick={topicCancelCreationHandler}>취소</Button> */}
                        {/* </Col> */}
                        <Col
                            xs={{span: 0}} sm={{span: 0}} md={{span: 4}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}                   
                        >
                        </Col>
                    </Row>
                </div>
            </div>
        </AppLayout>        
    );
}

export default Topics;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type NoteListState = {
    curPageNo: number
    , selectedNoteNo: number|null
};

const noteListInitialState: NoteListState = {
    curPageNo: 1
    , selectedNoteNo: null
};

const noteListInfoSlice = createSlice({
    name: 'noteListInfo'
    , initialState: noteListInitialState
    , reducers: {
        setNoteListInfo: {
            reducer: (state, action: PayloadAction<NoteListState>) => {
                // state = action.payload;
                state.curPageNo = action.payload.curPageNo;
                state.selectedNoteNo = action.payload.selectedNoteNo;
            }
            , prepare: (curPageNo: number, selectedNoteNo: number|null) => {
                return({payload: {curPageNo, selectedNoteNo}});
            }
        }
        , resetNoteListInfo: {
            reducer: (state, action: PayloadAction<NoteListState>) => {
                // state = action.payload;
                state.curPageNo = action.payload.curPageNo;
                state.selectedNoteNo = action.payload.selectedNoteNo;
            }
            // , prepare: (curPageNo: number, selectedNoteNo: number|null) => {
            , prepare: () => {
                return({payload: noteListInitialState});
            }
        }
    }
});

export const { setNoteListInfo, resetNoteListInfo } = noteListInfoSlice.actions;

export default noteListInfoSlice.reducer;

import { combineReducers } from "redux";
// import authReducer from "./reducers/user_auth";
// import topicListReducer from "./reducers/topic_list_info";
// import noteListReducer from "./reducers/note_list_info";
// import hubListReducer from "./reducers/hub_list_info";
// import discussionListReducer from "./reducers/discussion_list_info";
// import prevPageInfoReducer from "./reducers/prev_page_info";
// import agoraReducer from "./reducers/agora_info";

import authReducer from "./features/user_auth";
import noteListInfoReducer from "./features/note_list_info";
import topicListReducer from "./features/topic_list_info";
import prevPageInfoReducer from "./features/prev_page_info";
import hubListReducer from "./features/hub_list_info";
import discussionListReducer from "./features/discussion_list_info";
import agoraReducer from "./features/agora_info";
import lookaroundInfoReducer from "./features/lookaround_info";

const rootReducer = combineReducers({
    auth: authReducer
    , topicList: topicListReducer
    , noteList: noteListInfoReducer
    , hubList: hubListReducer
    , dicussionList: discussionListReducer
    , prevPageInfo: prevPageInfoReducer
    , agoraInfo: agoraReducer
    , lookaroundInfo: lookaroundInfoReducer
});

export default rootReducer;

import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import { AppDispatchType, RootStateType } from "..";

import { setLookaroundInfo, resetLookaroundInfo } from "../features/lookaround_info";

import { Row, Col, Input, Divider, Avatar, Image, Badge, Button } from "antd";

import "../css/hubs.css";


function LookaroundHubs(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();
    
    const [meE, setMeE] = useState<JSX.Element>(<div></div>);
    const [friendsE, setFriendsE] = useState<JSX.Element[]>([]);

    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const myInfo = {"userName": '토성', "profPic": '/lookaround/prof_pics/saturn_188x188.png'};

    const hubInfo1 = {"hubNo": 1, "names": ['지구', '수성'], "pics": ['/lookaround/prof_pics/earth_188x188.png', '/lookaround/prof_pics/mercury_188x188.png'], "numUnreadArticles": 0};
    const hubInfo2 = {"hubNo": 2, "names": ['수성'], "pics": ['/lookaround/prof_pics/mercury_188x188.png'], "numUnreadArticles": 0};
    const hubInfo3 = {"hubNo": 3, "names": ['목성', '지구'], "pics": ['/lookaround/prof_pics/jupiter_188x188.png', '/lookaround/prof_pics/earth_188x188.png'], "numUnreadArticles": 0};

    const lookaroundHubs = {"hubs": [hubInfo1, hubInfo2, hubInfo3], "me": myInfo};


    const { Search } = Input;


    function onClickSearchButtonHandler(searchInputVal: string){
        navigate('/user/signin');
    }

    function onChangeSearchInputHandler(event: React.ChangeEvent<HTMLInputElement>){
        setSearchInputValue(event.currentTarget.value);
    }

    function onClickMeHandler(event: React.MouseEvent<HTMLAnchorElement>){
        event.preventDefault();

        navigate('/lookaround/myinfo');
    }

    function onClickHubHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        const hubNo = event.currentTarget.dataset.hubno;

        if(hubNo){
            dispatch(setLookaroundInfo(parseInt(hubNo), null));
        }

        navigate('/lookaround/discussion');
    }

    type UserInfo = {
        profPic: string
        , userName: string
    };

    function createMeInfoDiv(userInfo: UserInfo): JSX.Element {

        let profPic: string = userInfo.profPic;
        let username: string = userInfo.userName;

        return(
            <div>
                <Row align="middle">
                    <Col id="hubs_me_img"
                        xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                    >
                        <Badge color="orange" count={"나"} size="default">
                            <Avatar
                                size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                src={<Image src={profPic} alt="Profile pic of me"/>}
                            />
                        </Badge>
                    </Col>
                    <Col className="lookaround_hubs_me_spacer"
                        xs={{span: 1}} sm={{span: 1}} md={{span: 0}}
                        lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}               
                    >
                    </Col>
                    <Col id="hubs_me_name"
                        xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                    >
                        <a onClick={onClickMeHandler}>{username}</a>
                    </Col>
                    <Col
                        xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                    ></Col>
                </Row>                
            </div>
        );
    }

    type lookaroundHubElementType = {
        hubNo: number
        , names: Array<string>
        , pics: Array<string> 
        , numUnreadArticles: number
    };

    function createHubsItems(friendInfo: lookaroundHubElementType[]): JSX.Element[] {

        // console.log('hubs.tsx.createHubsItems.friendInfo:', friendInfo);
        // console.log('hubs.tsx.createHubsItems.searchResults:', searchResults);

        const friendsE = friendInfo.map((element: lookaroundHubElementType, index: number) => {
            let resultElement: JSX.Element = <div></div>;

            // console.log('hubs.tsx.createHubsItems.doNotAddSRButton:', doNotAddSRButton);
            
            if(element.names.length > 1){
                resultElement = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
                    <Row align="middle">
                        <Col className="hubs_hub_img"
                            xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            {/* <Avatar
                                size={{xs: 40, sm: 40, md: 64, lg: 100, xl: 100, xxl: 100}}
                                src={<Image src={element.profPic} alt="Profile pic friends"/>}
                            /> */}
                            <Avatar.Group maxCount={element.names.length} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                                size={{xs: 32, sm: 32, md: 50, lg: 50, xl: 50, xxl: 50}}
                            >
                                <Avatar src={<Image src={element.pics[0]} alt="Profile pic friends"/>}/>
                                <Avatar src={<Image src={element.pics[1]} alt="Profile pic friends"/>}/>
                            </Avatar.Group>
                        </Col>
                        <Col className="lookaround_hubs_friends_spacer"
                            xs={{span: 1}} sm={{span: 1}} md={{span: 0}}
                            lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}               
                        >
                        </Col>
                        <Col className="hubs_hub_name"
                            xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                            lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                        >
                            {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>{element.userName}</a> */}
                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{element.names[0]}+</a>
                        </Col>
                    </Row>
                </div>;
            }
            else{   //  if(element.userNos.length > 1)
                resultElement = <div className="hubs_hub" key={index.toString()} data-index={index.toString()}>
                    <Row align="middle">
                        <Col className="hubs_hub_img"
                            xs={{span: 5}} sm={{span: 5}} md={{span: 5}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            <Avatar
                                size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}
                                src={<Image src={element.pics[0]} alt="Profile_pic_friends"/>}
                            />
                        </Col>
                        <Col className="lookaround_hubs_spacer"
                            xs={{span: 1}} sm={{span: 1}} md={{span: 0}}
                            lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}               
                        >
                        </Col>
                        <Col className="hubs_hub_name"
                            xs={{span: 9}} sm={{span: 9}} md={{span: 9}}
                            lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                        >
                            {/* <a onClick={onClickFriendHandler} data-friendno={element.friendNo}>{element.userName}</a> */}
                            <a onClick={onClickHubHandler} data-hubno={element.hubNo}>{element.names[0]}</a>
                        </Col>
                    </Row>
                </div>;
            }   //  if(element.userNos.length > 1){  

            return resultElement;
        });

        return friendsE;
    }

    // type gethubsResBodyType = {
    //     success: boolean
    //     , hubs: Array<lookaroundHubElementType>
    //     , me: UserInfo
    // };

    useEffect(() => {
        // fetch('/api/lookaround/gethubs', {
        //     method: 'GET'
        // }).then((response) => {
        //     return(response.json());
        // }).then((resBody: gethubsResBodyType) => {
        //     if(resBody["success"] === true){
        //         setMeE(createMeInfoDiv(resBody["me"]));
        //         setFriendsE(createHubsItems(resBody["hubs"]));
        //     }
        // }).catch((error) => {
        //     console.log('[Error]lookaround_hubs.tsx.useEffect.fetch(/api/lookaround/gethubs):', error);
        // });

        setMeE(createMeInfoDiv(lookaroundHubs["me"]));
        setFriendsE(createHubsItems(lookaroundHubs["hubs"]));
    }, []);

    return(
        <div id="hubs_top_container_div">
            <div id="hubs_me_div">{meE}</div>
            <Divider/>
            <div id="hubs_hubs_div">{friendsE}</div>
            <Divider dashed/>
            <div id="hubs_search_div">
                <Row>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                    >
                        <Search placeholder="친구찾기 - 메일주소 | 전화번호" onSearch={onClickSearchButtonHandler} onChange={onChangeSearchInputHandler} value={searchInputValue} enterButton/>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                    >
                    </Col>                            
                </Row>
            </div>
        </div>
    );
}

export default LookaroundHubs;


import React from 'react';

import '../css/md_syntax_info.css';

function MDSyntaxInfo() {
    return(
        <div id="markdown_syntax_info_div">
            <span className="syntax_title">● 제목</span>
            <pre># 가장 큰 제목</pre>
            <pre>###### 가장 작은 제목</pre>
            {/* <br> */}
            <span className="syntax_title">● 목록</span>
            <pre>* 목록 아이템 1</pre>
            <pre> * 목록 아이템 1-1</pre>
            <pre> * 목록 아이템 1-2</pre>
            <pre>  * 목록 아이템 1-2-a</pre>
            <pre>  * 목록 아이템 1-2-b</pre>
            {/* <br> */}
            <span className="syntax_title">● 수평선</span>
            <pre>---</pre>
            {/* <br> */}
            <span className="syntax_title">● 강조</span>
            <pre>_기울이기_</pre>
            <pre>__굵게__</pre>
            <pre>___기울이고 굵게__</pre>
            {/* <br> */}
            <span className="syntax_title">● 블록 강조 or 프로그램 코드 블록</span>
            <pre>```↵ 내용 블라블라↵ ```↵</pre>
            {/* <br> */}
            <span className="syntax_title">● 인용</span>
            <pre>&gt; 인용</pre>
            <pre>&gt;&gt; 인용 안의 인용</pre>
            <pre>&gt;&gt;&gt; 또 인용</pre>
            {/* <br> */}
            <span className="syntax_title">● 원숫자</span>
            <pre>(1), (2), ... (9)</pre>
            <span className="syntax_title">● 화살표 문자</span>
            <pre>--&gt;, &lt;--, &lt;--&gt;</pre>
            <span className="syntax_title">● 탈출 문자(escaping character)</span>
            <pre>\ + 마크다운 기호(#, *, _, ( 등)</pre>
            {/* <br> */}
            <span className="syntax_title">● 이미지 표시</span>
            <pre>![이미지 이름](이미지 URL)</pre>
            {/* <br> */}
            <span className="syntax_title">● (하이퍼) 링크</span>
            <pre>[링크 이름](링크 URL)</pre>
            {/* <br> */}
            <span className="syntax_title">● 수식입력</span>
            <pre>&lt;equation&gt;수식 스크립트&lt;/equation&gt;</pre>
            <pre>※수식 스크립트 작성방법 참조: https://latex.codecogs.com/</pre>
            {/* <br> */}
            <span className="syntax_title">● 이모지</span>
            <pre>윈도키 + ;</pre>
            {/* <br> */}
            😢유튭 같은 미디어는 아직 지원이 안되요🤦‍♀️🤦‍♂️
            {/* <br> */}
        </div>
    );
}

export default MDSyntaxInfo;
import React, { useState } from "react";

import { useSelector } from "react-redux";
import { RootStateType } from "..";

import { Row, Col, Avatar, Image, Button } from 'antd';

type FriendSearchResultEntryPropTypes = {
    profPic: Array<string>
    , names: Array<string>
    , noteNo: number
    , hubNo: number
    // , doneSharing: Function
    , isCurNoteShared: boolean
};

function FriendSearchResultEntry(prop: FriendSearchResultEntryPropTypes): JSX.Element {

    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const [profPicE, setProfPicE] = useState<JSX.Element>((profPic = prop.profPic): JSX.Element => {
        let _profPicE: JSX.Element = <span></span>;

        if(profPic.length > 3){
            _profPicE = <span>
                <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}>
                    <Avatar src={<Image src={prop.profPic[0]} alt="profile pic friends_1"/>}/>
                    <Avatar src={<Image src={prop.profPic[1]} alt="profile pic friends_2"/>}/>
                    <Avatar src={<Image src={prop.profPic[2]} alt="profile pic friends_3"/>}/>
                </Avatar.Group>
            </span>;
        }
        else{
            if(profPic.length > 2){
                _profPicE = <span>
                    <Avatar.Group maxCount={3} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}>
                        <Avatar src={<Image src={prop.profPic[0]} alt="profile pic friends_1"/>}/>
                        <Avatar src={<Image src={prop.profPic[1]} alt="profile pic friends_2"/>}/>
                        <Avatar src={<Image src={prop.profPic[2]} alt="profile pic friends_3"/>}/>
                    </Avatar.Group>
                </span>;
            }
            else{
                if(profPic.length > 1){
                    _profPicE = <span>
                        <Avatar.Group maxCount={2} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}} size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}}>
                            <Avatar src={<Image src={prop.profPic[0]} alt="profile pic friends_1"/>}/>
                            <Avatar src={<Image src={prop.profPic[1]} alt="profile pic friends_2"/>}/>
                        </Avatar.Group>
                    </span>;
                }
                else{
                    _profPicE = <span>
                        <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={prop.profPic[0]} alt="profile pic friends"/>}>
                        </Avatar>
                    </span>;
                }
            }
        }

        return _profPicE;
    });

    const [nameE, setNameE] = useState<JSX.Element>((names = prop.names): JSX.Element => {
        let _nameE: JSX.Element = <span></span>;

        if(names.length > 2){
            _nameE = <span>
                {names[0]},&nbsp;{names[1]}&nbsp;+{names.length - 2}
            </span>;
        }
        else{
            if(names.length > 1){
                _nameE = <span>
                    {names[0]},&nbsp;{names[1]}&nbsp;
                </span>;
            }
            else{
                _nameE = <span>
                    {names[0]}&nbsp;
                </span>;
            }
        }

        return _nameE;
    });

    // const [isShared, setIsShared] = useState<boolean>(prop.isCurNoteShared);
    const [btnE, setBtnE] = useState<JSX.Element>(prop.isCurNoteShared ? <Button danger onClick={onClickUnshareNoteBtnHandler}>{'공유 취소'}</Button> : <Button onClick={onClickShareNoteBtnHandler}>{'노트 공유'}</Button>);

    function onClickShareNoteBtnHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        if(authToken){

            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("hubNo", prop.hubNo.toString());
            formData.set("noteNo", prop.noteNo.toString());

            fetch('/api/share/sharenote', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                // prop.doneSharing();
                setBtnE(<Button danger onClick={onClickUnshareNoteBtnHandler}>{'공유 취소'}</Button>);
            // }).then((resBody) => {
            //     prop.doneSharing();
            }).catch((error) => {
                console.log('[Error]onClickShareNoteBtnHandler:', error);
            });
        }
    }

    function onClickUnshareNoteBtnHandler(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        if(authToken){

            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("hubNo", prop.hubNo.toString());
            formData.set("noteNo", prop.noteNo.toString());

            fetch('/api/share/unsharenote', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                // prop.doneSharing();
                setBtnE(<Button onClick={onClickShareNoteBtnHandler}>{'노트 공유'}</Button>);
            // }).then((resBody) => {
            //     prop.doneSharing();
            }).catch((error) => {
                console.log('[Error]onClickShareNoteBtnHandler:', error);
            });
        }
    }

    return(
        <div className="share_friend_info_div">
            <Row align="middle">
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 2}}
                    lg={{span: 2}} xl={{span: 2}} xxl={{span: 4}}
                >
                </Col>
                <Col
                    xs={{span: 8}} sm={{span: 8}} md={{span: 6}}
                    lg={{span: 6}} xl={{span: 6}} xxl={{span: 5}}
                >
                    <div className="share_friend_profpic_div">
                        {profPicE}
                    </div>
                </Col>
                <Col
                    xs={{span: 8}} sm={{span: 8}} md={{span: 10}}
                    lg={{span: 10}} xl={{span: 10}} xxl={{span: 9}}
                >
                    <div className="share_friend_name_div">
                        {nameE}
                    </div>
                </Col>
                <Col
                    xs={{span: 6}} sm={{span: 6}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 2}}
                >
                    <div className="share_friend_button_div">
                        {btnE}
                    </div>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 2}}
                    lg={{span: 2}} xl={{span: 2}} xxl={{span: 4}}
                >
                </Col>
            </Row>
        </div>
    )
}

export default FriendSearchResultEntry;

import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { RootStateType, AppDispatchType } from "..";
// import { setTopicListInfo } from "../reducers/topic_list_info";
import { setTopicListInfo } from "../features/topic_list_info";
import { useNavigate } from "react-router";

// import { setPrevPageInfo } from "../reducers/prev_page_info";
import { setPrevPageInfo } from "../features/prev_page_info";
// import { noteListState, setNoteListInfo } from "../reducers/note_list_info";
import { NoteListState, setNoteListInfo } from "../features/note_list_info";

import { Row, Col, Button } from 'antd';

// import "../css/topic.css";

type topicInputProps = {
    subjectNo: number
    ,title: string
};

function Topic(props: topicInputProps): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    const curPageNo: number = useSelector<RootStateType, number>(state => state.topicList.curPageNo);

    const [topic, setTopic] = useState<string>(props.title);
    const [subjectNo, setSubjectNo] = useState<number>(props.subjectNo);

    function onClickTopicBtnHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        let subjectNo = event.currentTarget.dataset.subjectno;

        // console.log('topic.tsx.onClickSubjectBtnHandler.subjectNo:', subjectNo);

        if(subjectNo){
            dispatch(setTopicListInfo(curPageNo, parseInt(subjectNo), topic));
        }

        dispatch(setPrevPageInfo('/topics'));
        // dispatch(setNoteListInfo({"curPageNo": 1, "selectedNoteNo": null}));
        dispatch(setNoteListInfo(1, null));

        navigate('/notelist');
    }

    return(
        <div className="topic_component_div">
            <Row>
                <Col
                    xs={{span: 0}} sm={{span: 0}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
                <Col
                    xs={{span: 24}} sm={{span: 24}} md={{span: 16}}
                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                >
                    <Row className="topic_title_and_button_div">
                        <Col
                            xs={{span: 18}} sm={{span: 18}} md={{span: 18}}
                            lg={{span: 20}} xl={{span: 20}} xxl={{span: 20}}
                        >
                            <div className="topic_title_div">
                                {topic}
                            </div>
                        </Col>
                        <Col className="topic_button_div"
                            xs={{span: 6}} sm={{span: 6}} md={{span: 6}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                        >
                            <Button type="primary" data-subjectno={subjectNo} onClick={onClickTopicBtnHandler}>노트 보기</Button>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={{span: 0}} sm={{span: 0}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
            </Row>
        </div>
    );
}

export default Topic;

import React, { ChangeEvent, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootStateType, AppDispatchType } from "..";
// import { agoraState } from "../reducers/agora_info";
import { AgoraState, setAgoraInfo } from "../features/agora_info";

import { Row, Col, Divider, Button, Input } from "antd";

import AppLayout from "../components/app_layout";

import "../css/agora.css";
// import Notice from "../components/notice";


function Agora(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();

    const isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);
    const userNo = useSelector<RootStateType, number|null>(state => state.auth.userNo);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const curPageNo: number = useSelector<RootStateType, number>((state) => {
        let _curPageNo = state.agoraInfo.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessAgoraInfo = sessionStorage.getItem("agoraInfo");
            if(sessAgoraInfo){
                const parsedSessAgoraInfo: AgoraState = JSON.parse(sessAgoraInfo);
                _curPageNo = parsedSessAgoraInfo["curPageNo"];
            }
        }

        return _curPageNo;
    });
    const itemsPerPage: number = 7;

    const [suggestion, setSuggestion] = useState<string>('');
    const [inputValid, setInputValid] = useState<boolean>(true);
    const [inputMsg, setInputMsg] = useState<string>('');

    const [items, setItems] = useState<Array<JSX.Element>>([]);
    const [pageIndex, setPageIndex] = useState<JSX.Element>(<div></div>);

    // const [numOfNoticeItems, setNumOfNoticeItems] = useState<number>(0);
    // const [showNoticeBtnLabel, setShowNoticeBtnLabel] = useState<string>('공지 보기');

    const { TextArea } = Input;

    type deleteSuggestionResBodyType = {
        success: boolean
        , suggestionNo: string  //  sent suggestionNo, which was a string.
    };

    function onClickDeleteSuggestion(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        let suggestionNo = event.currentTarget.dataset.suggestionno;

        if(authToken && suggestionNo){

            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("suggestionNo", suggestionNo);

            fetch('/api/agora/deletesuggestion', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody: deleteSuggestionResBodyType) => {
                if(resBody["success"] === true){
                    setItems((prevState) => {
                        let newState: Array<any> = [];

                        prevState.forEach((element) => {
                            if(element["key"] != resBody["suggestionNo"]){
                                newState.push(element);
                            }
                            // else{
                            //     console.log('agora.tsx.onClickDeleteSuggestion.deletedElement:', element["key"]);
                            // }
                        });

                        return newState;
                    });
                }
            }).catch((error) => {
                console.log('[Error]agora.tsx.onClickDeleteSuggestion:', error);
            });
        }
    }

    type suggestionItemType = {
        // suggestionNo: string|number
        suggestionNo: number
        // , state: string|number
        , state: number
        , opinion: string
        , timeCreated: string
        , writerNo: number
        , writer: string
    };

    function createSuggestionItemsE(itemList: Array<suggestionItemType>): Array<JSX.Element> {

        let itemEArray = itemList.map((item: suggestionItemType) => {

            // let itemKey = typeof(item["suggestionNo"]) === 'number' ? item["suggestionNo"] : parseInt(item["suggestionNo"]);
            let itemKey = item["suggestionNo"];
            // let itemState = typeof(item["state"]) === 'number' ? item["state"] : parseInt(item["state"]);
            let itemState = item["state"];
            let itemClass = itemState > 0 ? 'suggestion_answers' : 'suggestion_suggestions';
            let itemContent = item["opinion"];
            let timeCreated = item["timeCreated"];
            let writer = item["writer"];

            return(
                <div className={itemClass} key={itemKey}>
                    <Row className="suggestion_item_title_div">
                        {/* <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col> */}
                        <Col
                            xs={{span: 5}} sm={{span: 5}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                            No.&nbsp;{itemKey}
                        </Col>
                        <Col
                            xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            {writer}
                        </Col>
                        <Col
                            xs={{span: 11}} sm={{span: 11}} md={{span: 11}}
                            lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                        >
                            {timeCreated}
                        </Col>
                        <Col
                            xs={{span: 0}} sm={{span: 0}} md={{span: 2}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                        </Col>
                    </Row>
                    <Row className="suggestion_item_content_div">
                        <Col
                            xs={{span: 16}} sm={{span: 16}} md={{span: 16}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            {itemContent}
                        </Col>
                        <Col className="suggestion_item_button_div"
                            xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                        >
                            {(userNo && userNo === item["writerNo"]) && 
                            <Button danger onClick={onClickDeleteSuggestion} data-suggestionno={itemKey}>삭제</Button>
                            }
                        </Col>
                    </Row>
                </div>
            );
        });

        return itemEArray;
    }

    type pageIndexObjectType = {
        firstPage?: number
        , prevPage?: number
        , curPage: number
        , nextPage?: number
        , lastPage?: number
    };

    type getSuggestionsResBodyType = {
        success: boolean
        , itemList: Array<suggestionItemType>
        , pageIndexes: pageIndexObjectType
    };

    function onClickPageIndexHandler(event: React.MouseEvent<HTMLAnchorElement>){
        event.preventDefault();

        let newPageNo: number = event.currentTarget.dataset.pageno ? parseInt(event.currentTarget.dataset.pageno) : 1;

        let headerData = new Headers();
        let formData = new FormData();

        formData.set("base", newPageNo.toString());
        formData.set("offset", itemsPerPage.toString());

        fetch('/api/agora/getsuggestions', {
            method: 'POST'
            , headers: headerData
            , body: formData
        }).then((response) => {
            return response.json();
        }).then((resBody: getSuggestionsResBodyType) => {
            if(resBody["success"] === true){
                dispatch(setAgoraInfo(newPageNo));
                setItems(createSuggestionItemsE(resBody["itemList"]));
                setPageIndex(createPageIndexE(resBody["pageIndexes"]));
            }
        }).catch((error) => {
            console.log('[Error]agora.tsx.onClickPageIndexHandler.fetch(/api/agora/getsuggestions):', error);
        });

    }

    function createPageIndexE(pages: pageIndexObjectType): JSX.Element {

        const firstPage: number|null = pages["firstPage"] ? pages["firstPage"] : null;
        const prevPage: number|null = pages["prevPage"] ? pages["prevPage"] : null;
        const curPage: number = pages["curPage"];
        const nextPage: number|null = pages["nextPage"] ? pages["nextPage"] : null;
        const lastPage: number|null = pages["lastPage"] ? pages["lastPage"] : null;

        let prevEllipsis: string|null = null;
        let nextEllipsis: string|null = null;

        if(firstPage && prevPage){
            prevEllipsis = (prevPage - firstPage) > 1 ? '...' : null;
        }
        if(lastPage && nextPage){
            nextEllipsis = (lastPage - nextPage) > 1 ? '...' : null;
        }
        
        return(
            <>
                {firstPage && <span><a onClick={onClickPageIndexHandler} data-pageno={firstPage}>{firstPage}</a></span>}
                {prevEllipsis && <span>{prevEllipsis}</span>}
                {prevPage && <span><a onClick={onClickPageIndexHandler} data-pageno={prevPage}>{prevPage}</a></span>}
                <span id="suggestions_curpage_span">{curPage}</span>
                {nextPage && <span><a onClick={onClickPageIndexHandler} data-pageno={nextPage}>{nextPage}</a></span>}
                {nextEllipsis && <span>{nextEllipsis}</span>}
                {lastPage && <span><a onClick={onClickPageIndexHandler} data-pageno={lastPage}>{lastPage}</a></span>}
            </>
        );
    }

    function onChangeSuggestionInput(event: React.ChangeEvent<HTMLTextAreaElement>){
        event.preventDefault();

        let text = event.currentTarget.value;

        setSuggestion(text);
    }

    function checkSuggestionInput(message: string) {

        let isValid = true;

        if(message.length < 10){
            setInputMsg('의견을 10글자 이상 입력해주세요.');
            setInputValid(false);
            isValid = false;
        }
        else{
            setInputMsg('');
            setInputValid(true);
        }

        return isValid;
    }

    type createSuggestionResBodyType = {
        success: boolean
        , suggestion: suggestionItemType
    };

    function onClickSendSuggestion(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        const isInputValid = checkSuggestionInput(suggestion);

        if(isInputValid){
            if(authToken){
                let headerData = new Headers();
                let formData = new FormData();

                headerData.set("authData", authToken);
                formData.set("suggestion", suggestion);

                fetch('/api/agora/createsuggestion', {
                    method: 'POST'
                    , headers: headerData
                    , body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody: createSuggestionResBodyType) => {
                    if(resBody["success"] === true){
                        setSuggestion('');

                        const newSuggestion: suggestionItemType = resBody["suggestion"];

                        // const itemKey = typeof(newSuggestion["suggestionNo"]) === 'number' ? newSuggestion["suggestionNo"] : parseInt(newSuggestion["suggestionNo"]);
                        const itemKey = newSuggestion["suggestionNo"];
                        // const itemState = typeof(newSuggestion["state"]) === 'number' ?
                        // newSuggestion["state"] : parseInt(newSuggestion["state"]);
                        const itemState = newSuggestion["state"];
                        const itemClass = itemState > 0 ? 'suggestion_answers' : 'suggestion_suggestions';
                        const itemContent = newSuggestion["opinion"];
                        const timeCreated = newSuggestion["timeCreated"];
                        const writer = newSuggestion["writer"];
                        const writerNo = newSuggestion["writerNo"];

                        const newItemE: JSX.Element = 
                            <div className={itemClass} key={itemKey}>
                                <Row className="suggestion_item_title_div">
                                    {/* <Col
                                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                                    >
                                    </Col> */}
                                    <Col
                                        xs={{span: 3}} sm={{span: 3}} md={{span: 3}}
                                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                                    >
                                        No.&nbsp;{itemKey}
                                    </Col>
                                    <Col
                                        xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                                    >
                                        {writer}
                                    </Col>
                                    <Col
                                        xs={{span: 11}} sm={{span: 11}} md={{span: 11}}
                                        lg={{span: 9}} xl={{span: 9}} xxl={{span: 9}}               
                                    >
                                        {timeCreated}
                                    </Col>
                                    <Col
                                        xs={{span: 2}} sm={{span: 2}} md={{span: 2}}
                                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                                    >
                                    </Col>
                                </Row>
                                <Row className="suggestion_item_content_div">
                                    <Col
                                        xs={{span: 16}} sm={{span: 16}} md={{span: 16}}
                                        lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                                    >
                                        {itemContent}
                                    </Col>
                                    <Col className="suggestion_item_button_div"
                                        xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                                        lg={{span: 6}} xl={{span: 6}} xxl={{span: 6}}               
                                    >
                                        {(userNo && userNo === writerNo) && 
                                        <Button onClick={onClickDeleteSuggestion} data-suggestionno={itemKey}>삭제</Button>
                                        }
                                    </Col>
                                </Row>
                            </div>;
                    
                        setItems((prevState): Array<JSX.Element> => {
                            let newState = [newItemE, ...prevState];

                            if(newState.length > itemsPerPage){
                                newState.pop();     // remove the oldest item
                            }

                            return newState;
                        });
                    }
                }).catch((error) => {
                    console.log('[Error]agora.tsx.onClickSendSuggestion:', error);
                });
            }
        }
    }

    // function setNoticeItemLength(value: number){
    //     setNumOfNoticeItems(value);
    // }

    // function onClickShowNoticeBtnHandler() {
    //     const noticeDiv = document.getElementById('notice_div');

    //     // if(noticeDiv){
    //     //     noticeDiv.classList.toggle('display');
    //     //     noticeDiv.classList.toggle('hide');
    //     // }

    //     if(showNoticeBtnLabel === '공지 보기'){
    //         if(noticeDiv){
    //             // noticeDiv.style.display = 'block';
    //             // noticeDiv.style.height = `${noticeDiv.offsetHeight}px`;
    //             noticeDiv.style.height = `${noticeDiv.scrollHeight}px`;
    //         }
    //         setShowNoticeBtnLabel('공지 감추기');
    //     }
    //     else{
    //         if(noticeDiv){
    //             // noticeDiv.style.display = 'none';
    //             noticeDiv.style.height = '0px';
    //         }
    //         setShowNoticeBtnLabel('공지 보기');
    //     }
    // }

    useEffect(() => {
        // console.log('agora.tsx.useEffect.items.length:', items.length);

        if(items.length === 0){
            let headerData = new Headers();
            let formData = new FormData();

            // if(authToken){
            //     headerData.set("authData", authToken);
            // }

            formData.set("base", '1');
            formData.set("offset", itemsPerPage.toString());

            fetch('/api/agora/getsuggestions', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody: getSuggestionsResBodyType) => {
                if(resBody["success"] === true){
                    setItems(createSuggestionItemsE(resBody["itemList"]));
                    setPageIndex(createPageIndexE(resBody["pageIndexes"]));
                }
            }).catch((error) => {
                console.log('[Error]agora.tsx.useEffect[items.length].fetch(/api/agora/getsuggestions):', error);
            });
        }
    }, [items.length]);

    useEffect(() => {
        let headerData = new Headers();
        let formData = new FormData();

        // if(authToken){
        //     headerData.set("authData", authToken);
        // }

        formData.set("base", curPageNo.toString());
        formData.set("offset", itemsPerPage.toString());

        fetch('/api/agora/getsuggestions', {
            method: 'POST'
            , headers: headerData
            , body: formData
        }).then((response) => {
            return response.json();
        }).then((resBody: getSuggestionsResBodyType) => {
            if(resBody["success"] === true){
                setItems(createSuggestionItemsE(resBody["itemList"]));
                setPageIndex(createPageIndexE(resBody["pageIndexes"]));
            }
        }).catch((error) => {
            console.log('[Error]agora.tsx.useEffect[].fetch(/api/agora/getsuggestions):', error);
        });

    }, []);

    return(
        <AppLayout>
            <div id="agora_top_container_div">
                {/* <Row id="agora_title_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                    >
                        아직 부족한 부분이 너무 많아요. 의견 부탁드립니다 🙇‍♀️🙇‍
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    >
                    </Col>                    
                </Row> */}
                {/* <Row id="agora_notice_div">
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                        lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                    >
                        <Notice reportItemLength={setNoticeItemLength}></Notice>
                    </Col>
                </Row>
                <Row id="agora_notice_button_div">
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                        lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}               
                    >
                        {numOfNoticeItems > 0 &&
                            <div id="agora_show_notice_button_div">
                                <Button type="primary" onClick={onClickShowNoticeBtnHandler}>{showNoticeBtnLabel}</Button>
                            </div>
                        }    
                    </Col>
                </Row>
                <Row id="agora_divider_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                    >
                        <Divider></Divider>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                    >
                    </Col>
                </Row> */}
                <Row id="agora_input_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                    >
                        <TextArea rows={3} placeholder="100글자까지 입력 가능합니다" maxLength={100} showCount={true} allowClear={true} onChange={onChangeSuggestionInput} value={suggestion}/>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                </Row>
                {!inputValid &&
                    <Row>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                        >
                            <div id="agora_input_msg_div">
                                {inputMsg}
                            </div>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                        >
                        </Col>
                    </Row>
                }
                <Row id="agora_button_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                    >
                        <Button type="primary" onClick={onClickSendSuggestion}>의견 남기기</Button>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                </Row>
                <Row id="agora_contents_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                    >
                        <div id="agora_suggestion_items_div">
                            {items}
                        </div>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                </Row>
                <Row id="agora_page_div">
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                    <Col
                        xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                        lg={{span: 16}} xl={{span: 16}} xxl={{span: 16}}
                    >
                        <div id="agora_page_index_div">
                            {pageIndex}
                        </div>
                    </Col>
                    <Col
                        xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                        lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}               
                    >
                    </Col>
                </Row>

            </div>
        </AppLayout>
    );
}

export default Agora;

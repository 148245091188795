import React from 'react';

import { useEffect } from 'react';
import { RootStateType, AppDispatchType} from "..";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import { checkAuth, refreshAuth, signoutUser } from "../reducers/user_auth";
import { checkAuth, refreshAuth, signoutUser } from "../features/user_auth";

type WithCheckAuthProps = {
    children?: JSX.Element|never[],
    InputComponent: any,
    loginRequired: boolean,
    props?: any[]|{}
};

function WithCheckAuth({InputComponent, loginRequired, props}: WithCheckAuthProps) {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    let isLoggedIn: boolean = useSelector<RootStateType, boolean>(state => state.auth.isLoggedIn);

    let authToken: string|null = useSelector<RootStateType, string|null>((state) => {
        let authToken = state.auth.authToken;

        if(!authToken){
            let sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                authToken = sessAuthToken;
            }
        }

        return authToken;
    });

    useEffect(() => {
        // dispatch(checkAuth(authToken)).then(() => {
        //     if(loginRequired === true){
        //         if(isLoggedIn === false){
        //             navigate('/home');
        //         }
        //     }
        // });

        // type authData = {
        //     authenticated: boolean,
        //     authToken: string|null,
        //     userNo: string,
        //     userEmail: string,
        //     username: string,
        //     privilege: string,
        //     isLoggedIn: boolean
        // };

        //dispatch(checkAuth(authToken)).then((resBody: any) => {
        //    // console.log('WithCheckAuth.useEffect: resBody:', resBody);
        //    // console.log(`WithCheckAuth.useEffect: loginRequired=${loginRequired}, isLoggedIn=${isLoggedIn}, actionState=${actionState}`);

        //    let authenticated: boolean = resBody.authenticated;

        //    const data: authData = {
        //        authenticated: authenticated,
        //        authToken: resBody.authToken,
        //        userNo: resBody.userNo,
        //        email: resBody.email,
        //        username: resBody.username,
        //        privilege: resBody.privilege,
        //        isLoggedIn: authenticated
        //    };

        //    dispatch(refreshAuth(data));

        //    if(loginRequired === true){
        //        if(authenticated != true){
        //            navigate('/home');
        //        }
        //    }
        //}).catch((error) => {
        //    console.log('[Error]WithCheckAuth.checkAuth:', error);

        //    // const data: authData = {
        //    //     authenticated: false,
        //    //     authToken: null,
        //    //     userNo: '',
        //    //     email: '',
        //    //     username: '',
        //    //     isLoggedIn: false 
        //    // };

        //    // dispatch(refreshAuth(data));

        //    // dispatch(signoutUser(authToken));

        //    if(loginRequired === true){
        //        navigate('/home');
        //    }
        //});


        type checkSignInResBodyType = {
            authenticated: boolean
            , authToken: string
            , userNo: number
            , email: string
            , username: string
            , privilege: number
        };

        checkAuth(authToken).then((resBody: checkSignInResBodyType) => {
            const authenticated: boolean = resBody.authenticated;

            const authData = {
                authenticated: authenticated,
                authToken: resBody.authToken,
                userNo: resBody.userNo,
                userEmail: resBody.email,
                username: resBody.username,
                privilege: resBody.privilege,
                // isLoggedIn: authenticated
            };

            // console.log('check_auth.tsx.checkAuth.resBody:', resBody);

            // console.log('check_auth.tsx.useEffect().checkAuth.data.privilege:%d(%s)', authData["privilege"], typeof(authData["privilege"]));
            
            // console.log('check_auth.tsx.useEffect().checkAuth.data.userNo:%d(%s)', authData["userNo"], typeof(authData["userNo"]));

            dispatch(refreshAuth(authData));

            if(loginRequired === true){
                if(authenticated != true){
                    navigate('/home');
                }
            }
        }).catch((error) => {
            console.log('[Error]WithCheckAuth.checkAuth:', error);

            if(loginRequired === true){
                navigate('/home');
            }
        });

        console.log('check_auth.tsx.useEffect.isLoggedIn:', isLoggedIn);
    }, [isLoggedIn]);   


    return <InputComponent {...props}></InputComponent>;
}

export default WithCheckAuth;

import React from 'react';

import '../css/app_layout.css';

import { Row, Col } from 'antd';

type AppLayoutInputPropsType = {
    children?: any
}

function AppLayout(props: AppLayoutInputPropsType) {
    return(
        <div className="app_layout">
            <Row>
                <Col
                     xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                     lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                >
                </Col>
                <Col
                     xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                     lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                >
                    {props.children}
                </Col>
                <Col
                     xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                     lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}               
                >
                </Col>
            </Row>
        </div>
    );
}

export default AppLayout;

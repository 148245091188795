import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type LookaroundInfoState = {
    hubNo: number|null
    , noteNo: number|null
};

const lookaroundInfoInitialState: LookaroundInfoState = {
    hubNo: null
    , noteNo: null
};

const lookaroundInfoSlice = createSlice({
    name: 'lookaroundInfo'
    , initialState: lookaroundInfoInitialState
    , reducers: {
        setLookaroundInfo: {
            reducer: (state, action: PayloadAction<LookaroundInfoState>) => {
                state.hubNo = action.payload.hubNo;
                state.noteNo= action.payload.noteNo;
            }
            , prepare: (hubNo: number|null, noteNo: number|null) => {
                return({payload: {hubNo, noteNo}});
            }
        }
        , resetLookaroundInfo: {
            reducer: (state, action: PayloadAction<LookaroundInfoState>) => {
                state.hubNo = action.payload.hubNo;
                state.noteNo = action.payload.noteNo;
            }
            , prepare: () => {
                return({payload: lookaroundInfoInitialState});
            }
        }
    }
});

export const { setLookaroundInfo, resetLookaroundInfo } = lookaroundInfoSlice.actions;

export default lookaroundInfoSlice.reducer;

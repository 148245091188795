import React from "react";

import '../css/app_footer.css';

// import FooterInfo from '../resources/image/contact_info_footer.svg';
// import FooterInfo from '../resources/image/contact_info_footer_2023_outline.svg';

function AppFooter() {
    return (
        <div className="app_footer_div">
            {/* <img src={FooterInfo} alt="Info"></img> */}
            {/* <image href={FooterInfo}/> */}
            <span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 160 11"
                >
                    <path
                       d="m 4.9427217,8.8104671 c 1.642534,0 3.0564679,-1.2276671 3.0564679,-3.1580679 0,-1.9219341 -1.4139339,-3.1242013 -3.0564679,-3.1242013 -1.642534,0 -3.0564679,1.2022672 -3.0564679,3.1242013 0,1.9304008 1.4139339,3.1580679 3.0564679,3.1580679 z m 0,-0.3894668 c -1.4308672,0 -2.616201,-1.1176005 -2.616201,-2.7686011 0,-1.6510007 1.1853338,-2.7347344 2.616201,-2.7347344 1.4224006,0 2.6162011,1.0837337 2.6162011,2.7347344 0,1.6510006 -1.1938005,2.7686011 -2.6162011,2.7686011 z m 0.067733,-0.897467 c 0.5672669,0 0.9398003,-0.2286001 1.2784672,-0.5334002 L 6.0095221,6.5837329 C 5.730122,6.8207997 5.4507219,7.0070664 5.0443218,7.0070664 c -0.6858003,0 -1.1260672,-0.5249335 -1.1260672,-1.3546672 0,-0.745067 0.4572002,-1.2700005 1.1514672,-1.2700005 0.3386668,0 0.5672669,0.1439334 0.8297336,0.3894668 L 6.2211889,4.399332 C 5.9248554,4.0945319 5.577722,3.8659318 5.0443218,3.8659318 c -0.9144004,0 -1.7441341,0.6858003 -1.7441341,1.7864674 0,1.1684005 0.7620003,1.8711341 1.7102674,1.8711341 z"
                       />
                    <path
                       d="m 12.461137,3.0446648 h 2.904068 V 2.4604646 h -3.589868 v 5.003802 h 0.6096 c 1.532467,0 2.523068,-0.0508 3.691468,-0.2624668 l -0.0762,-0.5842002 c -1.1176,0.2116667 -2.074334,0.2540001 -3.539068,0.2540001 z M 16.804539,1.7407976 V 9.352334 h 0.694267 V 1.7407976 Z"/>
                    <path
                       d="M 25.618343,5.5592658 C 24.289076,5.3052657 22.849742,4.1537986 22.849742,2.8583981 V 2.2741978 h -0.728134 v 0.5842003 c 0,1.2954005 -1.430867,2.4384009 -2.760134,2.7008677 l 0.313267,0.5757336 c 1.143,-0.2709335 2.328334,-1.0837338 2.810934,-2.1928676 0.4826,1.1176005 1.667934,1.9304008 2.819401,2.1928676 z M 19.073607,7.7436667 V 8.3194003 H 25.95701 V 7.7436667 Z"/>
                    <path
                       d="M 33.73788,5.8301993 H 32.586413 C 32.857347,4.5855988 32.857347,3.7050651 32.857347,2.9091981 V 2.2149312 H 27.735011 V 2.782198 h 4.453469 v 0.1270001 c 0,0.3048001 0,0.6096002 -0.01693,0.9398004 l -4.656668,0.1778 0.09313,0.5842003 4.521202,-0.2201334 c -0.03387,0.4318001 -0.1016,0.897467 -0.2286,1.4393339 h -5.029202 v 0.5672669 h 3.090334 v 2.9379345 h 0.694267 V 6.3974662 h 3.081868 z"/>
                    <path
                       d="m 40.011682,4.0606652 h -4.504269 v 2.362201 h 5.359402 V 5.8555993 H 36.193213 V 4.6194654 h 4.495802 V 2.3249979 H 35.49048 v 0.5588002 h 4.521202 z m -5.367869,3.7422682 v 0.5757335 h 6.900336 V 7.8029334 Z"/>
                    <path
                       d="m 45.210224,8.7088671 h 1.591734 c 1.871134,0 2.895601,-1.1599338 2.895601,-3.1242013 0,-1.9727341 -1.024467,-3.0818679 -2.929467,-3.0818679 h -1.557868 z m 0.787401,-0.6434669 v -4.927602 h 0.702733 c 1.464734,0 2.184401,0.872067 2.184401,2.4468676 0,1.566334 -0.719667,2.4807344 -2.184401,2.4807344 z"/>
                    <path
                       d="m 50.959096,8.7088671 h 0.770466 V 4.1114652 h -0.770466 z m 0.389466,-5.5456689 c 0.3048,0 0.533401,-0.2116668 0.533401,-0.4995335 0,-0.3132668 -0.228601,-0.4995336 -0.533401,-0.4995336 -0.3048,0 -0.5334,0.1862668 -0.5334,0.4995336 0,0.2878667 0.2286,0.4995335 0.5334,0.4995335 z"/>
                    <path
                       d="m 54.489694,8.8189338 c 1.083733,0 1.6764,-0.6180669 1.6764,-1.3631339 0,-0.872067 -0.7366,-1.1430004 -1.405467,-1.3970005 -0.516467,-0.1947335 -1.016,-0.3556002 -1.016,-0.795867 0,-0.3556002 0.262466,-0.6604003 0.8382,-0.6604003 0.414867,0 0.7366,0.1693334 1.058334,0.4064002 l 0.372533,-0.4995336 c -0.364067,-0.2794001 -0.8636,-0.5164668 -1.430867,-0.5164668 -0.999067,0 -1.583267,0.5757335 -1.583267,1.3038671 0,0.7789337 0.728133,1.0922005 1.3716,1.3292672 0.508,0.1947335 1.049867,0.4064002 1.049867,0.8720671 0,0.3979334 -0.296333,0.7196669 -0.905933,0.7196669 -0.550334,0 -0.965201,-0.2201334 -1.363134,-0.5418669 l -0.381,0.5080002 c 0.440266,0.3640668 1.0668,0.6350003 1.718734,0.6350003 z"/>
                    <path
                       d="m 57.249833,8.7088671 h 0.762 V 7.4981333 l 0.8636,-1.0075338 1.346201,2.2182676 h 0.8382 L 59.3157,5.9571993 60.856634,4.1114652 H 60.0015 l -1.972734,2.4299343 h -0.01693 V 1.9693977 h -0.762 z"/>
                    <path
                       d="m 63.134175,8.8189338 c 0.635,0 1.0922,-0.3302001 1.524,-0.8297337 h 0.0254 l 0.05927,0.719667 h 0.643467 V 4.1114652 h -0.770467 v 3.259668 c -0.440267,0.5503336 -0.770467,0.7789337 -1.244601,0.7789337 -0.6096,0 -0.8636,-0.3640669 -0.8636,-1.2192005 V 4.1114652 h -0.770467 v 2.9125345 c 0,1.1684005 0.4318,1.7949341 1.397001,1.7949341 z"/>
                    <path
                       d="m 66.935711,8.7088671 h 0.770467 V 5.7539992 c 0.304801,-0.7874003 0.778934,-1.0583337 1.159934,-1.0583337 0.186267,0 0.296334,0.016933 0.448734,0.0762 l 0.143933,-0.6773336 c -0.143933,-0.0762 -0.287867,-0.1016 -0.491067,-0.1016 -0.516467,0 -0.982133,0.3725334 -1.303867,0.9567337 h -0.0254 l -0.06773,-0.8382004 h -0.635001 z"/>
                    <path
                       d="m 71.710929,8.7088671 h 3.903135 V 8.0400001 H 73.89533 c -0.313267,0 -0.694267,0.033867 -1.016001,0.059267 1.456268,-1.3800672 2.429935,-2.641601 2.429935,-3.8777348 0,-1.1091338 -0.694267,-1.8372674 -1.803401,-1.8372674 -0.7874,0 -1.3208,0.3640668 -1.828801,0.9144003 l 0.448734,0.4402669 c 0.338667,-0.4064002 0.778933,-0.719667 1.286934,-0.719667 0.770467,0 1.143,0.5164669 1.143,1.2276672 0,1.0668004 -0.897467,2.3029342 -2.844801,4.0047349 z"/>
                    <path
                       d="m 78.391127,8.8189338 c 1.176867,0 1.930401,-1.0752671 1.930401,-3.234268 0,-2.1505342 -0.753534,-3.2004012 -1.930401,-3.2004012 -1.185334,0 -1.938867,1.0414004 -1.938867,3.2004012 0,2.1590009 0.753533,3.234268 1.938867,3.234268 z m 0,-0.6180669 c -0.7112,0 -1.185334,-0.7874003 -1.185334,-2.6162011 0,-1.8118674 0.474134,-2.5738677 1.185334,-2.5738677 0.702734,0 1.176867,0.7620003 1.176867,2.5738677 0,1.8288008 -0.474133,2.6162011 -1.176867,2.6162011 z"/>
                    <path
                       d="m 81.108932,8.7088671 h 3.903135 v -0.668867 h -1.718735 c -0.313266,0 -0.694266,0.033867 -1.016,0.059267 1.456267,-1.3800672 2.429934,-2.641601 2.429934,-3.8777348 0,-1.1091338 -0.694267,-1.8372674 -1.8034,-1.8372674 -0.787401,0 -1.320801,0.3640668 -1.828801,0.9144003 l 0.448733,0.4402669 c 0.338667,-0.4064002 0.778934,-0.719667 1.286934,-0.719667 0.770467,0 1.143001,0.5164669 1.143001,1.2276672 0,1.0668004 -0.897467,2.3029342 -2.844801,4.0047349 z"/>
                    <path
                       d="m 87.670604,8.8189338 c 1.100667,0 1.989668,-0.6604003 1.989668,-1.7695341 0,-0.846667 -0.584201,-1.4054672 -1.320801,-1.5748006 v -0.033867 c 0.6604,-0.2455334 1.109134,-0.7450669 1.109134,-1.4986006 0,-0.999067 -0.762,-1.5578672 -1.803401,-1.5578672 -0.719667,0 -1.27,0.3132667 -1.735667,0.7450669 l 0.414867,0.4910669 c 0.347133,-0.3471335 0.7874,-0.6011336 1.286933,-0.6180669 0.643467,0.016933 1.049868,0.3979335 1.049868,0.999067 0,0.668867 -0.440267,1.1853338 -1.718734,1.1853338 v 0.5926669 c 1.430867,0 1.9304,0.4995336 1.9304,1.2446005 0,0.7112003 -0.524933,1.1514672 -1.261534,1.1514672 -0.702733,0 -1.176867,-0.3302002 -1.540933,-0.7112003 l -0.389467,0.5080002 c 0.4064,0.4402668 1.016,0.846667 1.989667,0.846667 z"/>
                    <path
                       d="m 90.769409,10.317534 c 0.762,-0.3217331 1.236133,-0.9567333 1.236133,-1.7695337 0,-0.5672669 -0.245533,-0.9144003 -0.651933,-0.9144003 -0.313267,0 -0.5842,0.2116667 -0.5842,0.5418669 0,0.3556001 0.270933,0.5418668 0.567267,0.5418668 0.03387,0 0.05927,0 0.09313,-0.00847 -0.0085,0.5164668 -0.321734,0.922867 -0.846667,1.1514671 z"/>
                    <path
                       d="m 97.017817,8.8189338 c 0.626534,0 1.117601,-0.2116668 1.524001,-0.4741335 l -0.2794,-0.5080002 c -0.338667,0.2286001 -0.702734,0.3640668 -1.151467,0.3640668 -0.872067,0 -1.473201,-0.6265336 -1.524001,-1.6086673 h 3.098801 c 0.01693,-0.1100667 0.03387,-0.2709335 0.03387,-0.4402669 0,-1.3123338 -0.6604,-2.1590008 -1.828801,-2.1590008 -1.0668,0 -2.074334,0.9313337 -2.074334,2.4214676 0,1.5155339 0.982134,2.4045343 2.201334,2.4045343 z M 95.578484,6.041866 c 0.09313,-0.9059337 0.668866,-1.4308672 1.3208,-1.4308672 0.719667,0 1.143001,0.4995335 1.143001,1.4308672 z"/>
                    <path
                       d="m 100.77702,8.8189338 c 0.56727,0 1.08373,-0.2963335 1.524,-0.6604003 h 0.0254 l 0.0677,0.5503336 h 0.635 V 5.8809993 c 0,-1.1345338 -0.47413,-1.8880674 -1.59173,-1.8880674 -0.7366,0 -1.38853,0.3302001 -1.811868,0.6011335 l 0.3048,0.5334002 c 0.364068,-0.2455334 0.846668,-0.4910668 1.380068,-0.4910668 0.75353,0 0.94827,0.5672669 0.94827,1.1599338 -1.9558,0.2201334 -2.827871,0.7196669 -2.827871,1.718734 0,0.8297337 0.575731,1.3038672 1.346201,1.3038672 z m 0.22013,-0.6180669 c -0.4572,0 -0.8128,-0.2116668 -0.8128,-0.7366003 0,-0.5926669 0.52494,-0.9736671 2.07434,-1.1599338 v 1.2869338 c -0.44874,0.3979335 -0.8128,0.6096003 -1.26154,0.6096003 z"/>
                    <path
                       d="m 104.49389,8.7088671 h 0.77046 V 5.7539992 c 0.3048,-0.7874003 0.77894,-1.0583337 1.15994,-1.0583337 0.18626,0 0.29633,0.016933 0.44873,0.0762 l 0.14393,-0.6773336 c -0.14393,-0.0762 -0.28786,-0.1016 -0.49106,-0.1016 -0.51647,0 -0.98214,0.3725334 -1.30387,0.9567337 h -0.0254 l -0.0677,-0.8382004 h -0.635 z"/>
                    <path
                       d="m 108.94736,8.8189338 c 1.08373,0 1.6764,-0.6180669 1.6764,-1.3631339 0,-0.872067 -0.7366,-1.1430004 -1.40547,-1.3970005 -0.51647,-0.1947335 -1.016,-0.3556002 -1.016,-0.795867 0,-0.3556002 0.26247,-0.6604003 0.8382,-0.6604003 0.41487,0 0.7366,0.1693334 1.05834,0.4064002 l 0.37253,-0.4995336 c -0.36407,-0.2794001 -0.8636,-0.5164668 -1.43087,-0.5164668 -0.99907,0 -1.58327,0.5757335 -1.58327,1.3038671 0,0.7789337 0.72814,1.0922005 1.3716,1.3292672 0.508,0.1947335 1.04987,0.4064002 1.04987,0.8720671 0,0.3979334 -0.29633,0.7196669 -0.90593,0.7196669 -0.55034,0 -0.9652,-0.2201334 -1.36314,-0.5418669 l -0.381,0.5080002 c 0.44027,0.3640668 1.0668,0.6350003 1.71874,0.6350003 z"/>
                    <path
                       d="m 114.73856,10.173601 c 0.65193,0 1.2446,-0.1524 1.79494,-0.4826002 l -0.2032,-0.4572002 c -0.42334,0.2455334 -0.96521,0.4233335 -1.54094,0.4233335 -1.60867,0 -2.81093,-1.0498671 -2.81093,-2.8956011 0,-2.2098009 1.6256,-3.6491348 3.31046,-3.6491348 1.71874,0 2.62467,1.1176004 2.62467,2.6500677 0,1.2276672 -0.67733,1.9473341 -1.27846,1.9473341 -0.51647,0 -0.69427,-0.3471335 -0.51647,-1.1091338 l 0.37253,-1.8880674 h -0.508 l -0.11007,0.3894668 h -0.0169 c -0.16933,-0.3132668 -0.4318,-0.4656668 -0.75353,-0.4656668 -1.10914,0 -1.82034,1.1938005 -1.82034,2.1928675 0,0.8636004 0.49107,1.3462006 1.13454,1.3462006 0.41486,0 0.84666,-0.2878668 1.15146,-0.6519336 h 0.0254 c 0.0593,0.4826002 0.45721,0.7196669 0.97367,0.7196669 0.85514,0 1.88807,-0.8636003 1.88807,-2.514601 0,-1.8626674 -1.20227,-3.1326679 -3.10727,-3.1326679 -2.1082,0 -3.94547,1.6594673 -3.94547,4.1910017 0,2.2013342 1.48167,3.386668 3.33587,3.386668 z m -0.16933,-2.5315344 c -0.381,0 -0.66887,-0.2455334 -0.66887,-0.8551336 0,-0.7112003 0.4572,-1.6086673 1.20227,-1.6086673 0.254,0 0.4318,0.1100667 0.6096,0.3979335 l -0.27094,1.5070672 c -0.32173,0.3894668 -0.6096,0.5588002 -0.87206,0.5588002 z"/>
                    <path
                       d="m 121.2833,8.8189338 c 0.55033,0 1.03293,-0.2963335 1.397,-0.6519336 h 0.0169 l 0.0762,0.5418669 h 0.62653 V 1.9693977 h -0.77046 v 1.7695341 l 0.0423,0.7958669 c -0.4064,-0.3386668 -0.75353,-0.5418668 -1.2954,-0.5418668 -1.04987,0 -1.98967,0.9313337 -1.98967,2.4214676 0,1.5324673 0.74507,2.4045343 1.89654,2.4045343 z m 0.16933,-0.6434669 c -0.8128,0 -1.27,-0.6604003 -1.27,-1.7695341 0,-1.0498671 0.5842,-1.7610673 1.32927,-1.7610673 0.381,0 0.72813,0.1354667 1.1176,0.4826001 v 2.413001 c -0.381,0.4233335 -0.75354,0.6350003 -1.17687,0.6350003 z"/>
                    <path
                       d="m 124.96631,8.7088671 h 0.77046 V 4.1114652 h -0.77046 z m 0.38946,-5.5456689 c 0.3048,0 0.5334,-0.2116668 0.5334,-0.4995335 0,-0.3132668 -0.2286,-0.4995336 -0.5334,-0.4995336 -0.3048,0 -0.5334,0.1862668 -0.5334,0.4995336 0,0.2878667 0.2286,0.4995335 0.5334,0.4995335 z"/>
                    <path
                       d="m 128.4969,8.8189338 c 1.08374,0 1.6764,-0.6180669 1.6764,-1.3631339 0,-0.872067 -0.7366,-1.1430004 -1.40546,-1.3970005 -0.51647,-0.1947335 -1.016,-0.3556002 -1.016,-0.795867 0,-0.3556002 0.26246,-0.6604003 0.8382,-0.6604003 0.41486,0 0.7366,0.1693334 1.05833,0.4064002 l 0.37253,-0.4995336 c -0.36406,-0.2794001 -0.8636,-0.5164668 -1.43086,-0.5164668 -0.99907,0 -1.58327,0.5757335 -1.58327,1.3038671 0,0.7789337 0.72813,1.0922005 1.3716,1.3292672 0.508,0.1947335 1.04987,0.4064002 1.04987,0.8720671 0,0.3979334 -0.29634,0.7196669 -0.90594,0.7196669 -0.55033,0 -0.9652,-0.2201334 -1.36313,-0.5418669 l -0.381,0.5080002 c 0.44027,0.3640668 1.0668,0.6350003 1.71873,0.6350003 z"/>
                    <path
                       d="m 131.25703,8.7088671 h 0.762 V 7.4981333 l 0.8636,-1.0075338 1.3462,2.2182676 h 0.8382 l -1.74413,-2.7516678 1.54093,-1.8457341 h -0.85513 l -1.97274,2.4299343 h -0.0169 V 1.9693977 h -0.762 z"/>
                    <path
                       d="m 137.14138,8.8189338 c 0.635,0 1.0922,-0.3302001 1.524,-0.8297337 h 0.0254 l 0.0593,0.719667 h 0.64346 V 4.1114652 h -0.77046 v 3.259668 c -0.44027,0.5503336 -0.77047,0.7789337 -1.2446,0.7789337 -0.6096,0 -0.8636,-0.3640669 -0.8636,-1.2192005 V 4.1114652 h -0.77047 v 2.9125345 c 0,1.1684005 0.4318,1.7949341 1.397,1.7949341 z"/>
                    <path
                       d="m 140.94291,8.7088671 h 0.77047 V 5.7539992 c 0.3048,-0.7874003 0.77893,-1.0583337 1.15993,-1.0583337 0.18627,0 0.29634,0.016933 0.44874,0.0762 l 0.14393,-0.6773336 c -0.14393,-0.0762 -0.28787,-0.1016 -0.49107,-0.1016 -0.51646,0 -0.98213,0.3725334 -1.30386,0.9567337 h -0.0254 l -0.0677,-0.8382004 h -0.635 z"/>
                    <path
                       d="m 144.09253,8.8189338 c 0.3048,0 0.5588,-0.2370668 0.5588,-0.5842002 0,-0.3471335 -0.254,-0.6011336 -0.5588,-0.6011336 -0.3048,0 -0.5588,0.2540001 -0.5588,0.6011336 0,0.3471334 0.254,0.5842002 0.5588,0.5842002 z"/>
                    <path
                       d="m 146.04832,8.7088671 h 0.77047 v -3.335868 c 0.46567,-0.4656669 0.7874,-0.7027336 1.26153,-0.7027336 0.6096,0 0.87207,0.3640668 0.87207,1.2276671 v 2.8109345 h 0.77047 V 5.7963326 c 0,-1.1768672 -0.44027,-1.8034007 -1.40547,-1.8034007 -0.62653,0 -1.10067,0.3471334 -1.54094,0.7789336 h -0.0254 l -0.0677,-0.6604003 h -0.635 z"/>
                    <path
                       d="m 153.07566,8.8189338 c 0.62654,0 1.1176,-0.2116668 1.524,-0.4741335 l -0.2794,-0.5080002 c -0.33866,0.2286001 -0.70273,0.3640668 -1.15146,0.3640668 -0.87207,0 -1.4732,-0.6265336 -1.524,-1.6086673 h 3.0988 c 0.0169,-0.1100667 0.0339,-0.2709335 0.0339,-0.4402669 0,-1.3123338 -0.6604,-2.1590008 -1.8288,-2.1590008 -1.0668,0 -2.07433,0.9313337 -2.07433,2.4214676 0,1.5155339 0.98213,2.4045343 2.20133,2.4045343 z M 151.63633,6.041866 c 0.0931,-0.9059337 0.66887,-1.4308672 1.3208,-1.4308672 0.71967,0 1.143,0.4995335 1.143,1.4308672 z"/>
                    <path
                       d="m 157.26668,8.8189338 c 0.2794,0 0.5842,-0.084667 0.84666,-0.1693334 l -0.1524,-0.5757336 c -0.1524,0.059267 -0.36406,0.1185334 -0.52493,0.1185334 -0.5334,0 -0.7112,-0.3217335 -0.7112,-0.8890004 v -2.565401 h 1.2446 V 4.1114652 h -1.2446 V 2.8245314 h -0.64347 l -0.0931,1.2869338 -0.71967,0.042333 v 0.5842002 h 0.6858 v 2.5484677 c 0,0.922867 0.3302,1.5324673 1.31234,1.5324673 z"/>
                </svg>
            </span>
        </div>
    );
}

export default AppFooter;

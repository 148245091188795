import React, { useEffect, useState, useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootStateType } from "..";


import { Button, Collapse, Divider, Input, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import AppLayout from "../components/app_layout";

import MDSyntaxInfo from "../components/md_syntax_info";
import MarkingDown from "../components/md_editor3p1_ems.js";
// import AudioManager from "../components/audio_manager";

import "../css/create_note.css";
import "../css/md_editor3.css";


function CreateNote(): JSX.Element {

    const navigate = useNavigate();

    const { TextArea } = Input;
    const { Panel } = Collapse;

    const authToken: string|null = useSelector<RootStateType, string|null>(state => state.auth.authToken);
    const topicNo: number|null = useSelector<RootStateType, number|null>(state => state.topicList.selectedTopicNo);

    // // for audio
    // const audioState = useSelector<RootState, string>(state => state.audio.audioState);

    const [title, setTitle] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [MDOutput, setMDOutput] = useState<{__html: string}>({__html: ''});

    const [titleMsg, setTitleMsg] = useState<string>('');
    const [submitMsg, setSubmitMsg] = useState<string|JSX.Element>('저장');

    const [disable, setDisable] = useState<boolean>(false); //  for disabling UI components
    const [onUploading, setOnUploading] = useState<boolean>(false);

    // // for audio
    // const [recordedBlob, setRecordedBlob] = useState<Blob|null>(null);

    const [updateMsg, setUpdateMsg] = useState<string>('');

    const [periodicAutoUpdate, setPAU] = useState<boolean>(false);
    const autoUpdateTimerID = useRef<number>(-1);
    const autoUpdateCallback = useRef<Function>(autoUpdate);
    const [noteNo, setNoteNo] = useState<number|null>(null);


    function onChangeTitleInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setTitle(event.currentTarget.value);
        setTitleMsg('');
    }

    function onChangeDescInputHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        setDesc(event.currentTarget.value);
    }

    // // for audio
    // function transferRecordedData(blob: Blob|null) {
    //     console.log('CreateArticle.transferRecordedData.blob', blob);
    //     setRecordedBlob(blob);
    // }

    // // for audio
    // function removeRecordedData(){
    //     console.log('CreateArticle.removeRecordedData()');
    //     setRecordedBlob(null);
    // }

    function checkInputData(): boolean {
        let chkResult = false;

        // check title length
        if(title.length > 0 && title.length <= 40){
            setTitleMsg('');

            chkResult = true;

            // location.hash = "#create_note_title_div"; 
        }
        else{
            setTitleMsg('제목을 1~40글자 사이로 입력해주세요.');

            let targetE = document.getElementById('create_note_title_div');
            targetE?.scrollIntoView();
        }

        return chkResult;
    }

    type postNoteResBodyType = {
        success: boolean
        , noteNo: number
    }

    function onClickSubmitBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        let chkResult= checkInputData();

        if(chkResult){
            if(authToken){
                if(noteNo){
                    let headerData = new Headers();
                    let formData = new FormData();

                    headerData.set("authData", authToken);
                
                    formData.set("noteNo", noteNo.toString());
                    formData.set("title", title);
                    formData.set("text", encodeURIComponent(desc));

                    // console.log('create_note.tsx.onClickSubmitBtnHandler.desc:', desc);

                    fetch('/api/note/update', {
                       method: 'POST'
                       , headers: headerData
                       , body: formData
                    }).then((response) => {
                       // navigate('/note');
                       return response.json();
                    }).then((resBody) => {
                       if(resBody["success"] === true){
                           const currentDate = new Date();
                           const currentHour = currentDate.getHours();
                           const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                           const currentMin = currentDate.getMinutes();
                           const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                           const currentSec = currentDate.getSeconds();
                           const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                           const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 저장되었습니다.`;
                           setUpdateMsg(currentTimeMsg);
                       }
                    }).catch((error) => {
                       console.log('[Error]create_note.tsx.onClickSubmitBtnHandler.fetch(/api/note/update):', error);
                    });
                }
                else{
                    if(topicNo){
                        let headerData = new Headers();
                        let formData = new FormData();

                        headerData.set("authData", authToken);

                        formData.set("topicNo", topicNo.toString());
                        formData.set("title", title);
                        formData.set("text", encodeURIComponent(desc));

                        // //  for audio
                        // if(recordedBlob){
                        //     let todayISOString = new Date().toISOString().substring(0, 10);
                        //     let min = 10000000;
                        //     let max = 99999999;
                        //     let rn = (Math.floor(Math.random()*(max - min + 1)) + min).toString();
                        //     let defaultClipName = `recording_${rn}_${todayISOString}`;
        
                        //     let fileName = oggSupported ? `${defaultClipName}.ogg` : `${defaultClipName}.webm`;
                        //     // audioFileName.current = fileName;
        
                        //     formData.current.set("isAudioDeleted", 'false');
                        //     formData.current.set("audio_data", recordedBlob, fileName);
                        //     formData.current.set("audioFileName", fileName);
                        // }
                        // else{
                        //     formData.current.set("isAudioDeleted", 'true');
                        // }
        
                        // isUploading.current = true;
                        setSubmitMsg(<>&nbsp;&nbsp;<LoadingOutlined/>&nbsp;&nbsp;</>);
                        setDisable(true);
                        setOnUploading(true);

                        fetch('/api/note/post', {
                            method: 'POST'
                            , headers: headerData
                            , body: formData
                        }).then((response) => {
                            setSubmitMsg('저장');
                            setDisable(false);
                            setOnUploading(false);
                            return response.json();
                        }).then((resBody: postNoteResBodyType) => {
                            if(resBody["success"] === true){
                                setNoteNo(resBody["noteNo"]);

                                const currentDate = new Date();
                                const currentHour = currentDate.getHours();
                                const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                                const currentMin = currentDate.getMinutes();
                                const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                                const currentSec = currentDate.getSeconds();
                                const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                                const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 저장되었습니다.`;
                                setUpdateMsg(currentTimeMsg);
                            }
                            // navigate('/notelist');
                        }).catch((error) => {
                            console.log('[Error]create_note.tsx.onClickSubmitBtnHandler.fetch(/api/note/post):', error);
                        });
                    }
                }
            }
        }
    }   // function onClickSubmitBtnHandler(event: React.MouseEvent<HTMLButtonElement>){  

    function onClickCancelBtnHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        navigate('/notelist');
    }

    function renderMDOutput() {
        const md = new MarkingDown();
        md.getData(desc);
        let mdResult = md.doMarkingDown();

        setMDOutput({__html: mdResult});
    }

    function onChangePAUSwitch(checked: boolean){
        setPAU(checked);
    }

    function autoUpdate() {
        let chkResult= checkInputData();

        if(chkResult){
            if(authToken){
                if(noteNo){
                    let headerData = new Headers();
                    let formData = new FormData();

                    headerData.set("authData", authToken);
                
                    formData.set("noteNo", noteNo.toString());
                    formData.set("title", title);
                    // formData.set("text", desc);
                    formData.set("text", encodeURIComponent(desc));

                    // console.log('create_note.tsx.uesEffect.periodicAutoUpdate.desc:', desc);

                    fetch('/api/note/update', {
                       method: 'POST'
                       , headers: headerData
                       , body: formData
                    }).then((response) => {
                       // navigate('/note');
                       return response.json();
                    }).then((resBody) => {
                       if(resBody["success"] === true){
                           const currentDate = new Date();
                           const currentHour = currentDate.getHours();
                           const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                           const currentMin = currentDate.getMinutes();
                           const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                           const currentSec = currentDate.getSeconds();
                           const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                           const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 자동 저장되었습니다.`;
                           setUpdateMsg(currentTimeMsg);
                       }
                    }).catch((error) => {
                       console.log('[Error]create_note.tsx.useEffect.periodicAutoUpdate:', error);
                    });
                    
                    // const currentDate = new Date();
                    // const currentHour = currentDate.getHours();
                    // const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                    // const currentMin = currentDate.getMinutes();
                    // const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                    // const currentSec = currentDate.getSeconds();
                    // const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                    // const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 자동 저장되었습니다.`;
                    // setUpdateMsg(currentTimeMsg);
                }
                else{   // if(noteNo)
                    if(topicNo){
                        let headerData = new Headers();
                        let formData = new FormData();

                        headerData.set("authData", authToken);

                        formData.set("topicNo", topicNo.toString());
                        formData.set("title", title);
                        formData.set("text", encodeURIComponent(desc));

                        // //  for audio
                        // if(recordedBlob){
                        //     let todayISOString = new Date().toISOString().substring(0, 10);
                        //     let min = 10000000;
                        //     let max = 99999999;
                        //     let rn = (Math.floor(Math.random()*(max - min + 1)) + min).toString();
                        //     let defaultClipName = `recording_${rn}_${todayISOString}`;
        
                        //     let fileName = oggSupported ? `${defaultClipName}.ogg` : `${defaultClipName}.webm`;
                        //     // audioFileName.current = fileName;
        
                        //     formData.current.set("isAudioDeleted", 'false');
                        //     formData.current.set("audio_data", recordedBlob, fileName);
                        //     formData.current.set("audioFileName", fileName);
                        // }
                        // else{
                        //     formData.current.set("isAudioDeleted", 'true');
                        // }
        
                        // isUploading.current = true;
                        setSubmitMsg(<>&nbsp;&nbsp;<LoadingOutlined/>&nbsp;&nbsp;</>);
                        setDisable(true);
                        setOnUploading(true);

                        fetch('/api/note/post', {
                            method: 'POST'
                            , headers: headerData
                            , body: formData
                        }).then((response) => {
                            setSubmitMsg('저장');
                            setDisable(false);
                            setOnUploading(false);
                            return response.json();
                        }).then((resBody: postNoteResBodyType) => {
                            if(resBody["success"] === true){
                                setNoteNo(resBody["noteNo"]);

                                const currentDate = new Date();
                                const currentHour = currentDate.getHours();
                                const twoDigitCurHour = currentHour < 10 ? `0${currentHour}` : currentHour;
                                const currentMin = currentDate.getMinutes();
                                const twoDigitCurMin = currentMin < 10 ? `0${currentMin}` : currentMin;
                                const currentSec = currentDate.getSeconds();
                                const twoDigitCurSec = currentSec < 10 ? `0${currentSec}` : currentSec;
                                const currentTimeMsg = `${twoDigitCurHour}:${twoDigitCurMin}:${twoDigitCurSec}에 자동 저장되었습니다.`;
                                setUpdateMsg(currentTimeMsg);
                            }
                            // navigate('/notelist');
                        }).catch((error) => {
                            console.log('[Error]create_note.tsx.useEffect.periodicAutoUpdate.fetch(/api/note/post):', error);
                        });
                    }   // if(topicNo)
                }   // if(noteNo)  
            }   // if(authToken) 
        }   // if(chkResult)
    }   // function autoUpdate()

    useEffect(() => {
        renderMDOutput();
    }, [desc]);

    // // for audio
    // useEffect(() => {
    //     console.log('CreateArticle.useEffect().audioState:', audioState);

    //     let disabled = (audioState === AUDIO_STATE_STOP_RECORDING || audioState === AUDIO_STATE_PLAYING_READY || audioState === AUDIO_STATE_STOP_PLAYING || audioState === AUDIO_STATE_RECORDING_READY) ? false : true;
    //     disabled = onUploading === true ? true : disabled;
    //     setDisable(disabled);
    // }, [audioState, onUploading]);

    useEffect(() => {
        autoUpdateCallback.current = autoUpdate;
        // console.log('create_note.tsx.useEffect.autoUpdateCallback:', autoUpdate);
    }, [autoUpdate]);

    useEffect(() => {
        function autoUpdater() {
            // autoUpdate function is updated(regenerated) whenever title and desc contents are updated.
            // Thus, the function should be called with its most recent immutable reference.

            autoUpdateCallback.current();
        }

        if(periodicAutoUpdate === true) {
            setUpdateMsg('1분 주기로 노트를 저장합니다.');

            const autoUpdateInterval = 60000;  //  1min in ms
            // const autoUpdateInterval = 5000;  //  5sec in ms - for test

            // autoUpdateTimerID.current = window.setInterval(() => {
            //     if(autoUpdateCallback.current){
            //         autoUpdateCallback.current();
            //     }
            // }, autoUpdateInterval);

            autoUpdateTimerID.current = window.setInterval(autoUpdater, autoUpdateInterval);
        }
        else{
            if(autoUpdateTimerID.current > 0){
                window.clearInterval(autoUpdateTimerID.current);
                autoUpdateTimerID.current = -1;
                setUpdateMsg('자동 저장이 해제되었습니다.');
            }
        }

        return function autoUpdateNoteCleanUp() {
            console.log('create_note.tsx.autoUpdateNoteCleanUp() called.');

            if(autoUpdateTimerID.current > 0){
                window.clearInterval(autoUpdateTimerID.current);
                console.log('create_note.tsx.autoUpdateNoteCleanUp().autoUpdateTimerID.current:', autoUpdateTimerID.current);
            }
        }
    }, [periodicAutoUpdate]);


    return(
        <AppLayout>
            <div id="create_note_top_container_div">
                <div id="create_note_title_div">
                    <Input placeholder="제목" maxLength={40} showCount={true} allowClear value={title} onChange={onChangeTitleInputHandler}></Input>
                </div>
                <div id="create_note_title_msg_div">{titleMsg}</div>
                <Divider></Divider>
                <div id="create_note_desc_input_div">
                    <TextArea showCount maxLength={5000} onChange={onChangeDescInputHandler} autoSize={true}></TextArea>
                </div>
                <div id="create_note_desc_auxiliary_div">
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="마크다운 결과 보기" key="1">
                        {/* <Panel header="마크다운 결과 보기" id="editor_output" key="1"> */}
                            {/* <p id="create_note_MDOutput_p" dangerouslySetInnerHTML={MDOutput}></p> */}
                            <p id="editor_output" dangerouslySetInnerHTML={MDOutput}></p>
                        </Panel>
                        <Panel header="마크다운 문법 참고" key="2">
                            <MDSyntaxInfo></MDSyntaxInfo>
                        </Panel>
                    </Collapse>
                </div>
                <Divider></Divider>
                {/* <AudioManager transferRecordedData={transferRecordedData} removeRecordedData={removeRecordedData} isPlayOnly={false} onHold={onUploading} extAudioBlobURL={''}></AudioManager>
                <Divider></Divider> */}
                <div id="create_note_update_msg_div">
                    {updateMsg.length === 0 ? <span>&nbsp;</span> : updateMsg}
                </div>
                <div id="create_note_button_div">
                    자동 저장&nbsp;<Switch onChange={onChangePAUSwitch}/>
                    <Button type="primary" disabled={disable} htmlType="submit" onClick={onClickSubmitBtnHandler}>{submitMsg}</Button>
                    <Button onClick={onClickCancelBtnHandler}>나가기</Button>
                </div>
            </div>
        </AppLayout>
    );

}

export default CreateNote;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatchType, RootStateType } from "..";
import AppLayout from "../components/app_layout";
// import { signoutUser } from "../reducers/user_auth";
import { signoutUser, removeAuth } from "../features/user_auth";
import { resetTopicListInfo } from '../features/topic_list_info';
import { resetNoteListInfo } from '../features/note_list_info';
import { resetHubListInfo } from '../features/hub_list_info';
import { resetDiscussionListInfo } from '../features/discussion_list_info';
import { resetPrevPageInfo } from '../features/prev_page_info';
import { resetAgoraInfo } from '../features/agora_info';

import { UploadFile } from "antd/lib/upload/interface";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile } from "rc-upload/lib/interface";

import { Avatar, Button, Input, Image, Row, Col, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { ImageResizer } from "../components/utils";

import '../css/my_info.css';


type fileListObjType = UploadFile<unknown>;

function MyInfo(): JSX.Element {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatchType>();

    // const authToken = useSelector<RootStateType, string|null>(state => state.auth.authToken);
    const authToken: string|null = useSelector<RootStateType, string|null>(state => {
        let _authToken = state.auth.authToken;

        if(_authToken === null || _authToken === undefined){
            const sessAuthToken = sessionStorage.getItem("authToken");
            if(sessAuthToken){
                _authToken = sessAuthToken;
            }
        }

        return _authToken;
    });

    const email = useSelector<RootStateType, string>(state => state.auth.userEmail);
    const username = useSelector<RootStateType, string>(state => state.auth.username);

    let [authenticated, setAuthenticated] = useState<boolean>(false);

    let [checkPassword, setCheckPassword] = useState<string>('');

    // let [username, setUsername] = useState<string>('');
    let [mobile, setMobile] = useState<string>('');
    let [profPicURL, setProfPicURL] = useState<string>('');
    let [password, setPassword] = useState<string>('');
    let [confirmPW, setConfirmPW] = useState<string>('');
    let [profPicFilename, setProficFilename] = useState<string>('');
    let [oldMobile, setOldMobile] = useState<string>('');

    // const profPic = useRef<HTMLInputElement>(null);
    // const profPic = useRef<Input>(null);

    //-- Update image
    const [fileList, setFileList] = useState<Array<fileListObjType>>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const isImageValid = useRef(true);
    const [imageMessage, setImageMessage] = useState('');
    const [removedFiles, setRemovedFiles] = useState<Array<fileListObjType>>([]);

    // let [usernameMessage, setUsernameMessage] = useState<string>('');
    let [passwordMessage, setPasswordMessage] = useState<string>('');
    let [confirmPWMessage, setConfirmPWMessage] = useState<string>('');
    let [mobileMessage, setMobileMessage] = useState<string>('');

    let [confirmDeleteUser, setConfirmDeleteUser] = useState<boolean>(false);

    let [resizedImage, setResizedImage] = useState<JSX.Element>(<></>);

    const onChangeCheckPWHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setCheckPassword(event.currentTarget.value);
    };

    type reqmyinfoResBodyType = {
        authenticated: boolean
        , email: string
        , username: string
        , mobile: string
        , profPicURL: string
    };

    const onCheckPWHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // let data = {
        //     email: email,
        //     password: checkPassword
        // }

        // fetch('/api/user/reqmyinfo', {
        //     method: 'POST',
        //     headers: {"content-type": 'application/json'},
        //     body: JSON.stringify(data)
        // }).then((response) => {
        //     return response.json();
        // }).then((resBody) => {
        //     console.log('Myinfo.onCheckPWHandler().authenticated:', resBody.authenticated);

        //     // setUsername(resBody.username);
        //     setMobile(resBody.mobile);
        //     // setProfPicURL(resBody.profPicURL);
        //     setAuthenticated(resBody.authenticated);
        //     setOldMobile(resBody.mobile);

        //     const profPic = resBody.profPicURL;
        //     const profPicPath = profPic.split('/');
        //     const profPicFile = profPicPath[profPicPath.length - 1];
        //     const profPicFileName = profPicFile.split('.')[0];
        //     const uid = `${Date.now().toString()}-${profPicFileName}`;

        //     const profPicFileObj: fileListObjType = {
        //         uid: uid,
        //         size: 1,
        //         name: profPicFile,
        //         url: profPic,
        //         type: 'image/url'
        //     };

        //     setFileList((prevState) => {
        //         return([profPicFileObj]);
        //     });

        // }).catch((error) => {
        //     console.log('[Error]Myinfo.onCheckPWHandler():', error);
        // });

        if(authToken){
            let headerData = new Headers();
            let formData = new FormData();

            headerData.set("authData", authToken);

            formData.set("email", email);
            formData.set("password", checkPassword);

            // console.log('myinfo.tsx.onCheckPWHandler().email: %s, username: %s', email, username);

            fetch('/api/user/reqmyinfo', {
                method: 'POST'
                , headers: headerData
                , body: formData
            }).then((response) => {
                return response.json();
            }).then((resBody: reqmyinfoResBodyType) => {
                // console.log('Myinfo.onCheckPWHandler().authenticated:', resBody.authenticated);

                if(resBody["authenticated"] === true){
                    // setUsername(resBody.username);
                    setMobile(resBody.mobile);
                    // setProfPicURL(resBody.profPicURL);
                    setAuthenticated(resBody.authenticated);
                    setOldMobile(resBody.mobile);

                    const profPic = resBody.profPicURL;
                    const profPicPath = profPic.split('/');
                    const profPicFile = profPicPath[profPicPath.length - 1];
                    const profPicFileName = profPicFile.split('.')[0];
                    const uid = `${Date.now().toString()}-${profPicFileName}`;

                    const profPicFileObj: fileListObjType = {
                        uid: uid,
                        size: 1,
                        name: profPicFile,
                        url: profPic,
                        type: 'image/url'
                    };

                    setFileList((prevState) => {
                        return([profPicFileObj]);
                    });
                }
            }).catch((error) => {
                console.log('[Error]Myinfo.onCheckPWHandler():', error);
            });
        }
    };

    const onClickCancelHancler = (event: React.MouseEvent<HTMLButtonElement>) => {
        // navigate('/home');
        setAuthenticated(false);
        navigate(-1);
    };


    // const onChangeUsernameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.preventDefault();
    //     setUsername(event.currentTarget.value);
    //     if(event.currentTarget.value.length < 5){
    //         setUsernameMessage('5글자 이상 입력해주세요.');
    //     }
    //     else{
    //         setUsernameMessage('');
    //     }
    // }

    const onChangeMobileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        var pat0 = /[^0-9]+/g;
        let rawVal = event.currentTarget.value;
        let numVal = rawVal.replace(pat0, '');
        setMobile(numVal);
        setMobileMessage('');
    };

    // const onChangeFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // // const onChangeFileInput = (info: any) => {
    //     event.preventDefault();
    //     if(event.currentTarget.files){
    //         setProficFilename(event.currentTarget.files[0].name);
    //     }

    //     // if(info.file.state == 'done')
    // }

    // const onDeleteProfPic = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    //     setProficFilename('');
    //     if(profPic.current){
    //         profPic.current.value = '';
    //         profPic.current.files = null;
    //     }
    // }

    const onChangePWHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let newPassword = event.currentTarget.value;

        setPassword(newPassword);

        if(newPassword.length < 8){
            setPasswordMessage('비밀번호를 8글자 이상 15글자 이하로 입력해주세요.');
        }
        else{
            setPasswordMessage('');
        }
    };

    const onChangeConfirmPWHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setConfirmPW(event.currentTarget.value);
        if(event.currentTarget.value === password){
            setConfirmPWMessage('');
        }
        else{
            setConfirmPWMessage('입력한 비밀번호가 일치하지 않습니다.');
        }
    };

    const checkInputValid = (): boolean => {
        let retVal: boolean = true;

        // if(username.length < 5){
        //     retVal = false;
        // }
        if(password != confirmPW){
            retVal = false;
        }
        if(password.length !=0 && password.length < 8){
            retVal = false;
        }
        if(password.length !=0 && password.length > 15){
            retVal = false;
        }

        return retVal;
    };

    const checkDuplicateData = async (email: string, username: string, mobile: string): Promise<any> => {
        let data = {
            email: email,
            username: username,
            mobile: mobile
        };

        const checkResponse = await fetch('/api/user/checkregdata', {
            method: 'POST',
            headers: {"content-type": 'application/json'},
            body: JSON.stringify(data)
        });
       
        return checkResponse.json();
    };

    const previewResizedImage = (imageBlob: Blob) => {
        // console.log('Myinfo.previewResizedImage:', imageBlob);

        let reader = new FileReader();

        reader.onload = (event) => {
            if(event.target && typeof event.target.result === 'string'){
                setResizedImage(<img src={event.target.result}></img>);
            }
        };

        reader.readAsDataURL(imageBlob);
    };

    // const onClickUpdateHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const onClickUpdateHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let isInputValid: boolean = checkInputValid();

        if(isInputValid === true){
            if(mobile === oldMobile){
                let formData = new FormData();
                let _authToken: string = authToken ? authToken : '';

                formData.append("authToken", _authToken);
                formData.append("email", email);
                formData.append("username", username);
                formData.append("mobile", oldMobile);
                formData.append("password", password);
                formData.append("profPicFilename", profPicFilename);
                // if(profPic.current && profPic.current.files){
                //     formData.append("profPicFile", profPic.current.files[0]);
                // }

                // fileList.forEach((item: fileListObjType, index) => {
                //     if(item.originFileObj){
                //         // formData.append(`imagefile${index}`, item.originFileObj as Blob);
                //         console.log('MyInfo.onClickUpdateHandler.fileList.formData.append:', item);
                //     }
                // });
        
                // removedFiles.forEach((item: fileListObjType, index) => {
                //     if(!item.originFileObj){
                //         // formData.append(`removedfile${index}`, item.name);
                //         console.log('MyInfo.onClickUpdateHandler.removedFile.formData.append:', item);
                //     }
                // });

                let imageFileList: Array<{}> = [];

                // fileList.forEach((item: fileListObjType, index) => {
                //     imageFileList.push({"name": item.name, "type": item.type});
                //     if(item.originFileObj){
                //         formData.append(`imagefile${index}`, item.originFileObj as Blob);
                //     }
                // });

                let index = 0;

                // for (let [index, item] of fileList.entries()){
                for (let item of fileList){
                    imageFileList.push({"name": item.name, "type": item.type});

                    let {originFileObj} = item;

                    if(originFileObj){
                        try{
                            let result = await ImageResizer.resize(originFileObj, 235, 235);
                            // previewResizedImage(result);
                            // console.log('Myinfo.onClickUpdateHandler.ImageResizer.result:', result);

                            let resizedFile = new File([result], originFileObj.name, {type: originFileObj.type, lastModified: originFileObj.lastModified});
                            // console.log('Myinfo.onClickUpdateHandler.ImageResizer.resizedFile:', resizedFile);

                            // formData.append("profPicFile", resizedFile as Blob);
                            formData.append(`imagefile${index}`, resizedFile as Blob);
                            // console.log('Myinfo.onClickUpdateHandler.imagefile-%d', index);
                        }
                        catch(error){
                            console.log('[Error]Myinfo.onClickUpdateHandler.ImageResizer.resize:', error)
                        }
                    }

                    index = index + 1;
                }
                formData.set("imageFileList", JSON.stringify(imageFileList));
    
                let removedFileList: Array<string> = [];
    
                removedFiles.forEach((item: fileListObjType, index) => {
                    if(!item.originFileObj){
                        removedFileList.push(item.name);
                    }
                });
                formData.set("removedFileList", JSON.stringify(removedFileList));

                fetch('/api/user/updatemyinfo', {
                    method: 'POST',
                    body: formData
                }).then((response) => {
                    return response.json();
                }).then((resBody) => {
                    // navigate('/home');
                    // navigate(-1, {replace: true});
                    navigate(-1);
                }).catch((error) => {
                    console.log('[Error]Myinfo.onClickUpdateHandler():', error);
                });
            }   // if(mobile === oldMobile){
            else{
                checkDuplicateData('', '', mobile).then((resBody) => {
                    let isValid: boolean = resBody.isValid;
                    setMobileMessage(resBody.mobileMsg);

                    if(isValid === true){
                        let formData = new FormData();
                        let _authToken: string = authToken ? authToken : '';

                        formData.append("authToken", _authToken);
                        formData.append("email", email);
                        formData.append("username", username);
                        formData.append("mobile", mobile);
                        formData.append("password", password);
                        formData.append("profPicFilename", profPicFilename);
                        // if(profPic.current && profPic.current.files){
                        //     formData.append("profPicFile", profPic.current.files[0]);
                        // }

                        let imageFileList: Array<{}> = [];

                        fileList.forEach((item: fileListObjType, index) => {
                            imageFileList.push({"name": item.name, "type": item.type});
                            if(item.originFileObj){
                                formData.append(`imagefile${index}`, item.originFileObj as Blob);
                                // console.log('MyInfo.onClickUpdateHandler.formData.imagefile.append:', item);
                            }
                        });
                        formData.set("imageFileList", JSON.stringify(imageFileList));
    
                        let removedFileList: Array<string> = [];
    
                        removedFiles.forEach((item: fileListObjType, index) => {
                            if(!item.originFileObj){
                                removedFileList.push(item.name);
                                // console.log('MyInfo.onClickUpdateHandler.removedFileList.append:', item);
                            }
                        });
                        formData.set("removedFileList", JSON.stringify(removedFileList));

                        fetch('/api/user/updatemyinfo', {
                            method: 'POST',
                            body: formData
                        }).then((response) => {
                            return response.json();
                        }).then((resBody) => {
                            // navigate('/home');
                            // navigate(-1, {replace: true});
                            navigate(-1);
                        }).catch((error) => {
                            console.log('[Error]Myinfo.onClickUpdateHandler():', error);
                        });
                    }
                    else{
                        setMobileMessage(resBody.mobileMsg);
                    }
                }).catch((error) => {
                    console.log('[Error]Myinfo.checkDuplicateData():', error);
                });
            }   // if(mobile === oldMobile){} else{
        }   // if(isInputValid === true){  
    };

    const onClickDeleteUserHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setConfirmDeleteUser(true);
    };

    const onConfirmDeletionHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let _authToken: string = authToken ? authToken : '';

        // const data = {
        //     authToken: _authToken
        // }

        fetch('/api/user/delete', {
            method: 'POST',
            headers: {"content-type": 'application/json'},
            body: JSON.stringify(_authToken)
        }).then((response) => {
            // dispatch(signoutUser(null));
            dispatch(signoutUser());
            removeAuth(authToken);
            dispatch(resetTopicListInfo());
            dispatch(resetNoteListInfo());
            dispatch(resetHubListInfo());
            dispatch(resetDiscussionListInfo());
            dispatch(resetPrevPageInfo());
            dispatch(resetAgoraInfo());
            navigate('/home');
        }).catch((error) => {
            console.log('[Error]Myinfo.onConfirmDeletionHandler():', error);
        });
    };
    
    const onCancelDeletionHandler =(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setConfirmDeleteUser(false);
    };


    //-- Update profile picture - functions

    const uploadButton = (
        <div>
            <div style={{ marginTop: 24 }}>
                <PlusOutlined/>
            </div>
            <div style={{ marginTop: 4 }}>
                사진
            </div>
        </div>
    );

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    // const handleBeforeUpload = (file: RcFile, fileList: Array<fileListObjType>) => {
        // console.log('createArticle.handleBeforeUpload.file:', file);
    
        const isJpgOrPng = file ? (file.type === 'image/jpeg' || file.type === 'image/png') : true;
        // if(!isJpgOrPng){
        //     setImageMessage('JPG 또는 PNG 이미지인지 확인해주세요.')
        // }
        const isSizeOk = file ? ((file.size>>20) < 10) : true; //  approximation of /(1024*1024)
        // if(!isSizeOk){
        //     setImageMessage('크기 10M 이하의 파일만 업로드 가능합니다.')
        // }
    
        // console.log('handleBeforeUpload.file.size:%d', file.size);
        // console.log('handleBeforeUpload.(isJpgOrPng:%s, isSizeOk:%s)', isJpgOrPng, isSizeOk);
    
        return (isJpgOrPng&&isSizeOk);
    };
    
    const handleUpload = (reqOpt: UploadRequestOption<unknown>) => {
        let { onSuccess } = reqOpt;

        let fileObj: RcFile = reqOpt.file as RcFile;
        let uid = fileObj.uid;
        // console.log('MyInfo.handleUpload.file.uid:', uid);

        //  onSuccess should be called somewhere outside the current run loop.
        setTimeout(() => {
            if(onSuccess){
                onSuccess(uid);
            }
        }, 0);
    };

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handlePreview = async (file: any) => {
        if(!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({fileList}: {fileList: Array<fileListObjType>}) => {
        setFileList(fileList);
        // console.log('MyInfo.handleChange.fileList:', fileList);

        let areJpgsOrPngs = true;
        let areSizesOk = true;

        fileList.forEach((candidate) => {
            areJpgsOrPngs = candidate.type ? areJpgsOrPngs && (candidate.type === 'image/jpeg' || candidate.type === 'image/png' || candidate.type === 'image/url') : false;
            areSizesOk = candidate.size ? areSizesOk && ((candidate.size>>20) < 10) : false;
        });

        // console.log('handleChange.(areJpgsOrPngs:%s, areSizesOk:%s)', areJpgsOrPngs, areSizesOk);

        if(!areJpgsOrPngs){
            setImageMessage('JPG 또는 PNG 이미지인지 확인해주세요.')
        }
        if(!areSizesOk){
            setImageMessage('크기 10M 이하의 파일만 업로드 가능합니다.')
        }
        if(areJpgsOrPngs&&areSizesOk){
            setImageMessage('');
        }

        isImageValid.current = areJpgsOrPngs&&areSizesOk;
    };

    const handleRemove = (file: UploadFile<unknown>) => {
    // const handleRemove = ({fileList}: {fileList: Array<fileListObjType>}) => {
        setRemovedFiles((prevState) => {
            return([...prevState, file]);
        })
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };


    useEffect(() => {
        let path: string = profPicURL;
	    let startIndex: number = path.indexOf('\\') >= 0 ? path.lastIndexOf('\\'): path.lastIndexOf('/');
	    let fileName: string = path.substring(startIndex);
	    if(fileName.indexOf('\\') === 0 || fileName.indexOf('/') === 0) {
		    fileName = fileName.substring(1);
	    }
        setProficFilename(fileName);
    }, [authenticated]);

    return(
        <AppLayout>
            <div id="my_info_content_div">
                {!authenticated &&
                <div id="my_info_pw_check_div">
                   <Row className="my_info_info_pw_check_input_field" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <form onSubmit={onCheckPWHandler}>
                                <Input.Group compact={true}>
                                    <label>비밀번호 확인
                                        <Input type="password" onChange={onChangeCheckPWHandler}></Input>
                                        <div id="my_info_pw_check_button_div">
                                            <Button type="primary" htmlType="submit">확인</Button>
                                            <Button onClick={onClickCancelHancler}>취소</Button>
                                        </div>
                                    </label>
                                </Input.Group>
                            </form>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        ></Col>
                    </Row>

                </div>}
                {authenticated &&
                <div id="my_info_info_display_update_input_div">
                    {/* <Row id="my_info_info_display_update_prof_pic" align="middle">
                        <Col span={6}></Col>
                        <Col id="my_info_me_img" span={12}>
                            <Avatar
                                size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}}
                                src={<Image src={profPicURL} alt="Profile pic me"/>}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row> */}

                    <Row id="my_info_info_display_update_prof_pic" align="middle">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col id="my_info_me_img"
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <Upload 
                                // action="/api/user/uploadProfilePic"
                                customRequest={handleUpload}
                                listType="picture-card"
                                fileList={fileList}
                                // fileList={uploadFileList.current}
                                beforeUpload={handleBeforeUpload}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                onRemove={handleRemove}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal
                                // visible={previewVisible}
                                open={previewVisible}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="image_preview" style={{ width: '100%' }} src={previewImage}/>
                            </Modal>
                            <div id="my_info_image_upload_msg_div" className="my_info_image_msg_div">
                                {imageMessage}
                            </div>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        ></Col>
                    </Row>
                    <Row className="my_info_info_display_update_input_field" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <div><span>Email: {email}</span></div>
                            <div><span>Username: {username}</span></div>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        ></Col>
                    </Row>
                    <Row className="my_info_info_display_update_input_field" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 8}}               
                        >
                            <Input addonBefore="Mobile" onChange={onChangeMobileHandler} value={mobile}></Input>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                    </Row>
                    <Row className="my_info_info_display_update_input_field" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 8}}               
                        >
                            <Input type="password" addonBefore="새 비밀번호" onChange={onChangePWHandler} value={password}></Input>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                    </Row>
                    <Row className="my_info_info_display_update_input_field" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 8}}               
                        >
                            <Input type="password" addonBefore="새 비밀번호 확인" onChange={onChangeConfirmPWHandler} value={confirmPW}></Input>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 6}} xl={{span: 6}} xxl={{span: 8}}               
                        >
                        </Col>
                    </Row>
                    <Row className="my_info_info_display_update_input_field" id="my_info_message_row" >
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            {(mobileMessage.length > 0) && mobileMessage}
                            {(mobileMessage.length == 0) && (passwordMessage.length > 0) && passwordMessage}
                            {(mobileMessage.length == 0) && (passwordMessage.length == 0) && (confirmPWMessage.length > 0) && confirmPWMessage}
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                    {/* <Row id="my_info_info_display_update_prof_pic" align="middle" justify="center">
                        <Col span={6}></Col>
                        <Col span={12}>
                            <input type="file" onChange={onChangeFileInput} ref={profPic}>
                            </input>
                            {profPicFilename}
                            <button onClick={onDeleteProfPic}>사진삭제</button>

                            <Input type="file" onChange={onChangeFileInput} ref={profPic}></Input>
                            {profPicFilename}
                            <Button onClick={onDeleteProfPic}>사진삭제</Button>

                            <Upload name="file" onChange={onChangeFileInput}>
                                <Button></Button>
                            </Upload>
                        </Col>
                        <Col span={6}></Col>
                    </Row> */}
                    <Row id="my_info_info_display_update_button_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <Button type="primary" onClick={onClickUpdateHandler}>변경</Button>
                            <Button onClick={onClickCancelHancler}>취소</Button>
                            <Button type="primary" danger onClick={onClickDeleteUserHandler}>탈퇴</Button>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                </div>}
                {/* <div>
                    {resizedImage}
                </div> */}
                {confirmDeleteUser&&
                <div id="my_info_delete_account_confirm_div">
                    <Row id="my_info_delete_account_notice_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <p>계정을 삭제합니다.</p>
                            <p>작성하신 내용들도 함께 삭제됩니다.</p>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                    <Row id="my_info_delete_account_button_div">
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <Button type="primary" danger onClick={onConfirmDeletionHandler}>확인</Button>
                            <Button onClick={onCancelDeletionHandler}>취소</Button>
                        </Col>
                        <Col
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                </div>
                }
            </div>
        </AppLayout>
    );
}

export default MyInfo;

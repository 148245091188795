import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AppDispatchType, RootStateType } from "..";
import { useNavigate } from "react-router";

import { setLookaroundInfo } from "../features/lookaround_info";

import AppLayout from "../components/app_layout";

import { Row, Col, Collapse, Divider, Button, Avatar, Image } from "antd";

import "../css/discussion.css";

export const noteArray = [
    {
        "noteNo": 1
        , "writerName": '토성'
        , "isAuthor": true
        , "title": '웹 서비스와 데이터 모델'
        , "topic": '데이터 모델과 데이터 활용'
        , "timeCreated": '2023-01-06 16:58'
        , "lastUpdate": '2023-01-06 16:58'
    }
    ,{
        "noteNo": 2
        , "writerName": '지구'
        , "isAuthor": false
        , "title": '관계형 데이터베이스와 테이블'
        , "topic": '데이터 모델과 데이터 활용'
        , "timeCreated": '2023-01-06 19:51'
        , "lastUpdate": '2023-01-06 19:51'
    }
    ,{
        "noteNo": 3
        , "writerName": '수성'
        , "isAuthor": false
        , "title": '집합연산'
        , "topic": '데이터 모델과 데이터 활용'
        , "timeCreated": '2023-01-07 14:02'
        , "lastUpdate": '2023-01-07 14:02'
    }
    , {
        "noteNo": 4
        , "writerName": '수성'
        , "isAuthor": false
        , "title": 'JOIN 연산 - INNER JOIN'
        , "topic": '데이터 모델과 데이터 활용'
        , "timeCreated": '2023-01-09 19:02'
        , "lastUpdate": '2023-01-09 19:02'
    }
    , {
        "noteNo": 5
        , "writerName": '수성'
        , "isAuthor": false
        , "title": 'JOIN 연산 - OUTER JOIN'
        , "topic": '데이터 모델과 데이터 활용'
        , "timeCreated": '2023-01-10 20:42'
        , "lastUpdate": '2023-01-10 20:42'
    }
    ,{
        "noteNo": 6
        , "writerName": '토성'
        , "isAuthor": true
        , "title": '민주주의의 위기 1/2'
        , "topic": '미국 현대 정치론'
        , "timeCreated": '2023-01-05 09:39'
        , "lastUpdate": '2023-01-05 09:39'
    }
    ,{
        "noteNo": 7
        , "writerName": '토성'
        , "isAuthor": true
        , "title": '민주주의의 위기 2/2'
        , "topic": '미국 현대 정치론'
        , "timeCreated": '2023-01-06 11:04'
        , "lastUpdate": '2023-01-06 11:04'
    }
    ,{
        "noteNo": 8
        , "writerName": '수성'
        , "isAuthor": false
        , "title": '미국의 총기 이슈와 정치역학'
        , "topic": '미국 현대 정치론'
        , "timeCreated": '2023-01-07 10:49'
        , "lastUpdate": '2023-01-07 10:49'
    }
    ,{
        "noteNo": 9
        , "writerName": '목성'
        , "isAuthor": false
        , "title": '산업 조직론'
        , "topic": '경영 전략'
        , "timeCreated": '2023-01-05 10:22'
        , "lastUpdate": '2023-01-05 10:22'
    }
    ,{
        "noteNo": 10
        , "writerName": '지구'
        , "isAuthor": false
        , "title": '산업조직론에 따른 전략의 구분'
        , "topic": '경영 전략'
        , "timeCreated": '2023-01-05 11:48'
        , "lastUpdate": '2023-01-05 11:48'
    }
    ,{
        "noteNo": 11
        , "writerName": '토성'
        , "isAuthor": true
        , "title": 'Strategic Group Analysis(SGA)'
        , "topic": '경영 전략'
        , "timeCreated": '2023-01-10 11:55'
        , "lastUpdate": '2023-01-10 11:55'
    }
    ,{
        "noteNo": 12
        , "writerName": '토성'
        , "isAuthor": true
        , "title": '기업의 자원과 역량 - Resource Based View(RBV)'
        , "topic": '경영 전략'
        , "timeCreated": '2023-01-12 11:51'
        , "lastUpdate": '2023-01-12 11:51'
    }
    ,{
        "noteNo": 13
        , "writerName": '토성'
        , "isAuthor": true
        , "title": '전략적 혁신 - Strategic Innovation'
        , "topic": '경영 전략'
        , "timeCreated": '2023-01-17 11:42'
        , "lastUpdate": '2023-01-17 11:42'
    }
];

function LookaroundDiscussion(): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();

    const { Panel } = Collapse;

    const hubNo: number|null = useSelector<RootStateType, number|null>(state => {
        return state.lookaroundInfo.hubNo;
    })

    const [friendsE, setFriendsE] = useState<JSX.Element>(<div></div>);
    const [friendsETitle, setFriendsETitle] = useState<string>("이 방의 친구들");

    const [notesE, setNotesE] = useState<JSX.Element[]>([]);


    function onClickBackToHomeHandler(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        navigate('/home');
    }

    function onClickNoteHandler(event: React.MouseEvent<HTMLDivElement>){
        event.preventDefault();

        const noteNo = event.currentTarget.dataset.noteno;

        // console.log('lookaround_discussion.tsx.onClickNoteHandler.noteNo:', noteNo);

        if(noteNo){
            dispatch(setLookaroundInfo(hubNo, parseInt(noteNo)))
            navigate('/lookaround/note');
        }
    }

    type NoteItemType = {
        noteNo: number
        , isAuthor: boolean
        , subjectTitle: string
        , articleTitle: string
        , writerName: string
        , profPic: string
        , timeCreated: string
    };

    function createItemsElement(notes: NoteItemType[]) {
            
        let items = notes.map((item: NoteItemType) => {

            if(item.isAuthor === true){
                // return(
                //     <div className="discussion_note_right_align" key={item.noteNo}>
                //         <div className="discussion_note_left_spacer"></div>
                //         <div className="True" onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                //             {/* {item.title} */}
                //             {item.title ? item.title : '제목이 없습니다'}
                //         </div>
                //     </div>
                // );
                return(
                    // <div className="discussion_note_mine" key={item.noteNo} onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                    //     <Row className="discussion_mine_header_row">
                    //         <Col className="discussion_note_writer_div"
                    //             xs={{span: 12}} sm={{span: 12}} md={{span: 12}}
                    //             lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                    //         >
                    //             작성자: {item.writerName}
                    //         </Col>
                    //         <Col className="discussion_note_timecreated_div"
                    //             xs={{span: 12}} sm={{span: 12}} md={{span: 12}}
                    //             lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                    //         >
                    //             {item.timeCreated}
                    //         </Col>
                    //     </Row>
                    //     <Row className="discussion_mine_title_row">
                    //         <Col className="discussion_note_subject_title_div"
                    //             xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                    //             lg={{span: 5}} xl={{span: 5}} xxl={{span: 5}}               
                    //         >   
                    //             {item.subjectTitle ? item.subjectTitle : '노트 주제가 없습니다'}
                    //         </Col>
                    //         <Col className="discussion_note_title_hypen_div"
                    //             xs={{span: 2}} sm={{span: 2}} md={{span: 2}}
                    //             lg={{span: 1}} xl={{span: 1}} xxl={{span: 1}}               
                    //         >   
                    //             &nbsp;-&nbsp;
                    //         </Col>
                    //         <Col className="discussion_note_article_title_div"
                    //             xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                    //             lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    //         >   
                    //             {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                    //         </Col>
                    //     </Row>
                    // </div>
                    <Row className="discussion_note_right_align" key={item.noteNo}>
                        <Col className="discussion_note_left_spacer"
                            xs={{span: 2}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                        <Col className="True" onClick={onClickNoteHandler} data-noteno={item.noteNo}
                            xs={{span: 18}} sm={{span: 18}} md={{span: 18}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <div className="title_div">
                                [{item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}]
                                &nbsp;
                                {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                            </div>
                            <div className="time_div">
                                {item.timeCreated}
                            </div>
                        </Col>
                        <Col className="discussion_note_profile_pic"
                            xs={{span: 4}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        <span>
                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={item.profPic} alt="profile pic author"/>}>
                            </Avatar>
                        </span>
                        </Col>
                    </Row>

                );
            }
            else{
                // return(
                //     <div className="discussion_note_left_align" key={item.noteNo}>
                //         <div className="False" onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                //             {/* {item.title} */}
                //             {item.title ? item.title : '제목이 없습니다'}
                //         </div>
                //         <div className="discussion_note_right_spacer"></div>
                //     </div>
                // );
                return(
                    // <div className="discussion_note_friends" key={item.noteNo} onClick={onClickNoteHandler} data-noteno={item.noteNo}>
                    //     <Row className="discussion_friends_header_row">
                    //         <Col className="discussion_note_writer_div"
                    //             xs={{span: 12}} sm={{span: 12}} md={{span: 12}}
                    //             lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                    //         >
                    //             작성자: {item.writerName}
                    //         </Col>
                    //         <Col className="discussion_note_timecreated_div"
                    //             xs={{span: 12}} sm={{span: 12}} md={{span: 12}}
                    //             lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}               
                    //         >
                    //             {item.timeCreated}
                    //         </Col>
                    //     </Row>
                    //     <Row className="discussion_friends_title_row">
                    //         <Col className="discussion_note_subject_title_div"
                    //             xs={{span: 8}} sm={{span: 8}} md={{span: 8}}
                    //             lg={{span: 5}} xl={{span: 5}} xxl={{span: 5}}               
                    //         >   
                    //             {item.subjectTitle ? item.subjectTitle : '노트 주제가 없습니다'}
                    //         </Col>
                    //         <Col className="discussion_note_title_hypen_div"
                    //             xs={{span: 2}} sm={{span: 2}} md={{span: 2}}
                    //             lg={{span: 1}} xl={{span: 1}} xxl={{span: 1}}               
                    //         >   
                    //             &nbsp;-&nbsp;
                    //         </Col>
                    //         <Col className="discussion_note_article_title_div"
                    //             xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                    //             lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    //         >   
                    //             {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                    //         </Col>
                    //     </Row>
                    // </div>
                    <Row className="discussion_note_left_align" key={item.noteNo}>
                        <Col className="discussion_note_profile_pic"
                            xs={{span: 4}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        <span>
                            <Avatar size={{xs: 40, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64}} src={<Image src={item.profPic} alt="profile pic author"/>}>
                            </Avatar>
                        </span>
                        </Col>
                        <Col className="False" onClick={onClickNoteHandler} data-noteno={item.noteNo}
                            xs={{span: 18}} sm={{span: 18}} md={{span: 18}}
                            lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                        >
                            <div className="title_div">
                                [{item.subjectTitle ? item.subjectTitle : '제목이 없습니다'}]
                                &nbsp;
                                {item.articleTitle ? item.articleTitle : '제목이 없습니다'}
                            </div>
                            <div className="time_div">
                                {item.timeCreated}
                            </div>
                        </Col>
                        <Col className="discussion_note_right_spacer"
                            xs={{span: 2}} sm={{span: 3}} md={{span: 3}}
                            lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                        >
                        </Col>
                    </Row>
                );
            }
        });

        return items;
    }

    function getProfPic(userName: string){
        let fn = '/lookaround/prof_pics/saturn_188x188.png';

        switch(userName){
            case '수성':
                fn = '/lookaround/prof_pics/mercury_188x188.png';
                break;
            case '지구':
                fn = '/lookaround/prof_pics/earth_188x188.png';
                break;
            case '목성':
                fn = '/lookaround/prof_pics/jupiter_188x188.png';
                break;
            default:
                fn = '/lookaround/prof_pics/saturn_188x188.png';
                break;
        }

        return fn;
    }

    function createLookAroundSharedNotes(){
        let notes: Array<NoteItemType> = [];

        if(hubNo){
            switch(hubNo){
                case 1:
                    for(let i=4; i>=0; i--){
                        let note: NoteItemType = {
                            "noteNo": noteArray[i]["noteNo"]
                            , "writerName": noteArray[i]["writerName"]
                            , "isAuthor": noteArray[i]["isAuthor"]
                            , "articleTitle": noteArray[i]["title"]
                            , "subjectTitle": noteArray[i]["topic"]
                            , "profPic": getProfPic(noteArray[i]["writerName"])
                            , "timeCreated": noteArray[i]["timeCreated"]
                        };

                        notes.push(note);
                    }
                    break;
                case 2:
                    for(let i=7; i>=5; i--){
                        let note: NoteItemType = {
                            "noteNo": noteArray[i]["noteNo"]
                            , "writerName": noteArray[i]["writerName"]
                            , "isAuthor": noteArray[i]["isAuthor"]
                            , "articleTitle": noteArray[i]["title"]
                            , "subjectTitle": noteArray[i]["topic"]
                            , "profPic": getProfPic(noteArray[i]["writerName"])
                            , "timeCreated": noteArray[i]["timeCreated"]
                        };

                        notes.push(note);
                    }
                    break;
                case 3:
                    for(let i=12; i>=8; i--){
                        let note: NoteItemType = {
                            "noteNo": noteArray[i]["noteNo"]
                            , "writerName": noteArray[i]["writerName"]
                            , "isAuthor": noteArray[i]["isAuthor"]
                            , "articleTitle": noteArray[i]["title"]
                            , "subjectTitle": noteArray[i]["topic"]
                            , "profPic": getProfPic(noteArray[i]["writerName"])
                            , "timeCreated": noteArray[i]["timeCreated"]
                        };

                        notes.push(note);
                    }
                    break;
                default:
                    break;
            }   // switch(hubNo)
        }

        return notes;
    }

    // type lookaroundgetsharednotesResBodyType = {
    //     success: boolean
    //     , notes: Array<NoteItemType>
    // };

    useEffect(() => {
        let formData = new FormData();

        // console.log('lookaround_discussion.tsx.useEffect.hubNo:', hubNo);

        if(hubNo){
            formData.set('hubNo', hubNo.toString());

            switch(hubNo){
                case 1:
                    setFriendsE(<div>{'토성, 지구, 수성'}</div>)
                    break;
                case 2:
                    setFriendsE(<div>{'토성, 수성'}</div>)
                    break;
                case 3:
                    setFriendsE(<div>{'토성, 목성, 지구'}</div>)
                    break;
                default:
                    setFriendsE(<div></div>)
            }

            // fetch('/api/lookaround/getsharednotes', {
            //     method: 'POST'
            //     , body: formData
            // }).then((response) => {
            //     return(response.json());
            // }).then((resBody: lookaroundgetsharednotesResBodyType) => {
            //     // console.log('lookaround_discussion.tsx.useEffect.fetch(/api/lookaround/getsharednotes).resBody:', resBody);
            //     setNotesE(createItemsElement(resBody["notes"]));
            // }).catch((error) => {
            //     console.log('[Error]lookaround_discussion.tsx.useEffect.fetch(/api/lookaround/getsharednotes):', error);
            // });

            setNotesE(createItemsElement(createLookAroundSharedNotes()));
        }
    }, []);

    return(
        <AppLayout>
            <div id="lookaround_discussion_top_container_div">
                <Row>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 22}}
                        lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    >
                        <div id="discussion_friend_list_div">
                            <Collapse>
                                <Panel header={friendsETitle} key={0}>
                                    {friendsE}
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                </Row>
                <Row>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                    <Col
                        xs={{span: 24}} sm={{span: 24}} md={{span: 22}}
                        lg={{span: 18}} xl={{span: 18}} xxl={{span: 18}}               
                    >
                        <div id="discussion_note_list_div">
                            {notesE}
                        </div>
                    </Col>
                    <Col
                        xs={{span: 0}} sm={{span: 0}} md={{span: 1}}
                        lg={{span: 3}} xl={{span: 3}} xxl={{span: 3}}               
                    ></Col>
                </Row>
                <Divider/>
                <div id="discussion_buttons_div">
                    <Button type="default" onClick={onClickBackToHomeHandler}>홈으로</Button> 
                </div>

            </div>
        </AppLayout>
    )
}

export default LookaroundDiscussion;

///////////////////////////////////////////////////////////////////////////
//
//  version 3.1
//
//  Whole code is reviewed at 2023-03-14.
//  Bold, italic test patterns are modified.
//  Bold and italic pattern is added.
//  Bold, italic, and boldItalic pattern processing is modified to support multiple patterns in a line
//  Circled numbers are supported.
//  Arrow characters are supported.
//
///////////////////////////////////////////////////////////////////////////

class MarkingDown {

    targetString;
    targetStringLines;
    lineBuffer;
    evaluationBuffer;
    outputBuffer;
    outputString;

    listTagStack;
    bquoteTagStack;
    codeBlockFlag;
    eq2Flag;
    eq2FIFO;

    boldTagStack;
    italicTagStack;
    boldItalicTagStack;

    constructor() {
        this.targetString = '';
        this.targetStringLines = [];
        this.lineBuffer = '';
        this.evaluationBuffer = [];
        this.outputBuffer = [];
        this.outputString = '';

        this.listTagStack = [];
        this.bquoteTagStack = [];
        this.codeBlockFlag = false;
        this.eq2Flag = false;
        this.eq2FIFO = [];

        this.boldTagStack = [];
        this.italicTagStack = [];
        this.boldItalicTagStack = [];
    }

    getData(data) {
        this.targetString = data;
        this.targetStringLines.splice(0);
        this.evaluationBuffer.splice(0);
        this.outputBuffer.splice(0);
        this.listTagStack.splice(0);
        this.bquoteTagStack.splice(0);
        this.codeBlockFlag = false;
        this.outputString = '';
        this.eq2Flag = false;
        this.eq2FIFO.splice(0);

        this.boldTagStack.splice(0);
        this.italicTagStack.splice(0);
        this.boldItalicTagStack.splice(0);
    }

    splitStrings() {
        this.targetString.split('\n').forEach((line) => {

            this.targetStringLines.push(line + '\n');
        }); 
    }

    readLine(data) {
        this.lineBuffer = data; 
    }

    testUListCond(data) {
        //  Unordered List
        var pat0101 = /^\*{1}[^\*.]+/;  // [^: not, \*: character *, .: any character]: character class, +: one or more
		var pat0102 = /^[\t\s]{1}\*{1}.+/;
		var pat0103 = /^[\t\s]{2}\*{1}.+/;

        if(pat0101.test(data)) {
            var type = 'ul';
            var level = 0;
        }
        else if(pat0102.test(data)) {
            var type = 'ul';
            var level = 1;
        }
        else if(pat0103.test(data)) {
            var type = 'ul';
            var level = 2;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testBQuoteCond(data) {
        //  Block quotation
		var pat0201 = /^>{1}(?!>).+/;
		var pat0202 = /^>{2}(?!>).+/;
		var pat0203 = /^>{3}(?!>).+/;

        if(pat0201.test(data)){
            var type = 'bquote';
            var level = 0;
        }
        else if(pat0202.test(data)){
            var type = 'bquote';
            var level = 1;
        }
        else if(pat0203.test(data)){
            var type = 'bquote';
            var level = 2;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testCodeBlockCond(data) {
        //  Code block
        var pat0301 = /^`{3}\n$/;

        if(pat0301.test(data)) {
            var type = 'codeBlock';
            var level = 0;

            this.codeBlockFlag = !this.codeBlockFlag;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testHeadingCond(data) {
        //  Headings
		var pat0401 = /^#{1}(?!#).+/;   // (?!#): look ahead condition, # but not followed by other #
		var pat0402 = /^#{2}(?!#).+/;
		var pat0403 = /^#{3}(?!#).+/;
		var pat0404 = /^#{4}(?!#).+/;
		var pat0405 = /^#{5}(?!#).+/;
		var pat0406 = /^#{6}(?!#).+/;

        if(pat0401.test(data)) {
            var type = 'heading1';
            var level = 0;
        }
        else if(pat0402.test(data)) {
            var type = 'heading2';
            var level = 0;
        }
        else if(pat0403.test(data)) {
            var type = 'heading3';
            var level = 0;
        }
        else if(pat0404.test(data)) {
            var type = 'heading4';
            var level = 0;
        }
        else if(pat0405.test(data)) {
            var type = 'heading5';
            var level = 0;
        }
        else if(pat0406.test(data)) {
            var type = 'heading6';
            var level = 0;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testImageCond(data) {
        //  Image from web
        var pat0501 = /^\!\[\S+/;

        if(pat0501.test(data)) {
            var type = 'webImage';
            level = 0;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testHyperlinkCond(data) {
        //  Hyperlink
        // var pat0601 = /^\[\S+/;
        var pat0601 = /^\[\S.+/;

        if(pat0601.test(data)) {
            var type = 'hyperlink';
            level = 0;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testHorizontalRuleCond(data) {
        //  Horizontal Rule
        var pat0701 = /^-{3,}\n/;
        var pat0702 = /^\*{3,}\n/;

        if(pat0701.test(data)) {
            var type = 'hr';
            var level = 0;
        }
        else if(pat0702.test(data)) {
            var type = 'hr';
            var level = 0;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }

    testEquationCond(data) {
        //  Equation
		var pat0801 = /<equation>((.*?\n)*?.*?)<\/equation>/ig;     //  inline
        var pat0802 = /^<equation>\s*$/;        //  multiline
        var pat0803 = /^<\/equation>\s*$/;      //  multiline


        if(pat0801.test(data)) {
            var type = 'eq';
            var level = 0;
        }
        else if(pat0802.test(data)){
            var type = 'eq2Begin';
            var level = 0;
            this.eq2Flag = !this.eq2Flag;
        }
        else if(pat0803.test(data)){
            var type = 'eq2End';
            var level = 0;
            this.eq2Flag = !this.eq2Flag;
        }
        else {
            var type = '';
            var level = 0;
        }

        return {"type": type, "level": level};
    }


    evaluateLine() {
        var retVal = {"linedata": this.lineBuffer, 'type': -1, 'level': 0};

        var testResult = {"type": '', "level": 0};

        if (testResult["type"].length == 0){
            testResult = this.testUListCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testBQuoteCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testCodeBlockCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testHeadingCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testImageCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testHyperlinkCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testHorizontalRuleCond(this.lineBuffer);
        }

        if (testResult["type"].length == 0){
            testResult = this.testEquationCond(this.lineBuffer);
        }

        retVal["type"] = testResult["type"];
        retVal["level"] = testResult["level"];

        return retVal;
    }

    getPrevLineEvalResult() {
        var previousLineEvalResult = {"data": '', "type": '', "level": 0};

        if(this.evaluationBuffer.length > 1) {
            //  this.evaluationBuffer.length - 1 = the last result. Thus, 
            previousLineEvalResult = this.evaluationBuffer[this.evaluationBuffer.length - 2];
        }

        return previousLineEvalResult;
    }

    closeList(prevType, type, levelDiff) {
        var listLineCloseCond = prevType == 'ul' && type == 'ul' && levelDiff == 0;
        var listBlockCloseCond = prevType == 'ul' && type == 'ul' && levelDiff < 0;
        var listCloseCond = prevType == 'ul' && type != 'ul';

        // console.log('lineBuf = %s, prevType = %s, type = %s, levelDiff = %d, listLineCloseCond = %s', this.lineBuffer, prevType, type, levelDiff, listLineCloseCond.toString());

        // console.log('lineBuf = %s, prevType = %s, type = %s, levelDiff = %d, listBlockCloseCond = %s', this.lineBuffer, prevType, type, levelDiff, listBlockCloseCond.toString());

        // console.log('lineBuf = %s, prevType = %s, type = %s, levelDiff = %d, listCloseCond = %s', this.lineBuffer, prevType, type, levelDiff, listCloseCond.toString());

        if(listLineCloseCond){
            // close a line - </li>
            var item = this.listTagStack.pop();
            this.outputBuffer.push({"modifiedData": item["tag"], 
                                    "type": 'ul', 
                                    "level": item["level"]});
        }

        if(listBlockCloseCond) {
            // close a line - </li>
            var item = this.listTagStack.pop();
            this.outputBuffer.push({"modifiedData": item["tag"], 
                                    "type": 'ul', 
                                    "level": item["level"]});

            var i = 0;
            do {
                // close a block - </ul>
                item = this.listTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                        "type": 'ul', 
                                        "level": item["level"]});

                // close a line having the block - </li>
                item = this.listTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                        "type": 'ul', 
                                        "level": item["level"]});
                i = i + 1;
            } while(i<Math.abs(levelDiff));
        }

        if(listCloseCond){
            if(this.listTagStack.length > 0){
                // close a line - </li>
                var item = this.listTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                       "type": 'ul', 
                                       "level": item["level"]});
            }

            for(var i=0; i<Math.abs(levelDiff); i++){
                // close a block - </ul>
                if(this.listTagStack.length > 0){
                    item = this.listTagStack.pop();
                    this.outputBuffer.push({"modifiedData": item["tag"], 
                                            "type": 'ul', 
                                            "level": item["level"]});
                }

                // close a line having the block - </li>
                if(this.listTagStack.length > 0){
                    item = this.listTagStack.pop();
                    this.outputBuffer.push({"modifiedData": item["tag"], 
                                            "type": 'ul', 
                                            "level": item["level"]});
                }
            }

            // close the outer most block - </ul>
            if(this.listTagStack.length > 0){
                item = this.listTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                       "type": 'ul', 
                                       "level": item["level"]});
            }
        }
    }

    openList(prevType, type, level, levelDiff, data) {
        var listOpenCond = prevType !='ul' && type == 'ul';
        var listLineOpenCond = prevType == 'ul' && type == 'ul' && levelDiff <= 0;
        var listBlockOpenCond = type == 'ul' && levelDiff > 0;

        if(listOpenCond) {
            this.outputBuffer.push({"modifiedData": '<ul>', "type": 'ul', 
                                "level": level});
            this.listTagStack.push({"tag": '</ul>', "level": level});

            this.outputBuffer.push({"modifiedData": '<li>', "type": 'ul', "level": level});
            this.listTagStack.push({"tag": '</li>', "level": level});
        }

        if(listBlockOpenCond) {
            var i = 0;
            do {
                this.outputBuffer.push({"modifiedData": '<ul>', "type": 'ul', 
                                    "level": level});
                this.listTagStack.push({"tag": '</ul>', "level": level});

                this.outputBuffer.push({"modifiedData": '<li>', "type": 'ul', "level": level});
                this.listTagStack.push({"tag": '</li>', "level": level});
                i = i + 1;
            } while(i<levelDiff);
        }

        if(listLineOpenCond) {
            this.outputBuffer.push({"modifiedData": '<li>', "type": 'ul', "level": level});
            this.listTagStack.push({"tag": '</li>', "level": level});
        }

        if(listOpenCond || listLineOpenCond || listBlockOpenCond) {
            var listStartingPat = /^[\t\s]*\*{1}/;
            var content = data.replace(listStartingPat, '');

            this.outputBuffer.push({"modifiedData": content, "type": 'ul', "level": level});
        }
    }

    closeBQuote(prevType, type, levelDiff) {
        var bquoteCloseCond = prevType == 'bquote' && type != 'bquote';

        var bquotelineCloseCond = prevType == 'bquote' && type == 'bquote';

        var bquoteBlockCloseCond = (prevType == 'bquote' && type == 'bquote' && levelDiff < 0);

        if(bquotelineCloseCond) {
            var item = this.bquoteTagStack.pop();
            this.outputBuffer.push({"modifiedData": item["tag"], 
                                    "type": 'bquote', 
                                    "level": item["level"]});
        }

        if(bquoteBlockCloseCond) {
            var i = 0;
            do {
                var item = this.bquoteTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                        "type": 'bquote', 
                                        "level": item["level"]});
                i = i + 1;
            } while(i<Math.abs(levelDiff));
        }

        if(bquoteCloseCond) {
            var bquoteTagStackLen = this.bquoteTagStack.length;
            for(var i=0; i<bquoteTagStackLen; i++){
                var item = this.bquoteTagStack.pop();
                this.outputBuffer.push({"modifiedData": item["tag"], 
                                        "type": 'bquote', 
                                        "level": item["level"]});
            }
        }
    }

    openBQuote(prevType, type, level, levelDiff, data) {
        var bquoteOpenCond = prevType != 'bquote' && type == 'bquote';

        var bquoteLineOpenCond = type == 'bquote' && data.length > 0;

        var bquoteBlockOpenCond = type == 'bquote' && levelDiff > 0;

        if(bquoteOpenCond) {
            this.outputBuffer.push({"modifiedData": '<blockquote>', "type": 'bquote', 
                                "level": level});
            this.bquoteTagStack.push({"tag": '</blockquote>', "level": level});
        }

        if(bquoteBlockOpenCond) {
            var i = 0;
            do {
                this.outputBuffer.push({"modifiedData": '<blockquote>', "type": 'bquote', 
                                    "level": level});
                this.bquoteTagStack.push({"tag": '</blockquote>', "level": level});
                i = i + 1;
            } while(i<levelDiff)
        }

        if(bquoteLineOpenCond) {
            this.outputBuffer.push({"modifiedData": '<p>', "type": 'bquote', 
                                "level": level});
            this.bquoteTagStack.push({"tag": '</p>', "level": level});

            var bquoteStartingPat = /^>+/;
            var content = data.replace(bquoteStartingPat, '');

            this.outputBuffer.push({"modifiedData": content, "type": 'bquote', "level": level});
        }
    }

    closeCodeBlock(type, data) {
        var codeBlockCloseCond = type == 'codeBlock' && this.codeBlockFlag == false;

        if(codeBlockCloseCond) {
            var codeBlockEndingPat = /`{3}\n/;
            var content = data.replace(codeBlockEndingPat, '</code></pre></div>');

            this.outputBuffer.push({"modifiedData": content, "type": 'codeBlock', "level": 0 });
        }
    }

    openCodeBlock(type, level, data) {
        var codeBlockOpenCond = type == 'codeBlock' && this.codeBlockFlag == true;

        if(codeBlockOpenCond) {
            var codeBlockStartingPat = /^`{3}\n/;
            var content = data.replace(codeBlockStartingPat, '<div class="codeblock"><pre><code>');

            this.outputBuffer.push({"modifiedData": content, "type": 'codeBlock', "level": level });
        }
    }

    openCloseHeading(type, level, data) {
        if(type == 'heading1') {
            var heading1Pat = /^#{1}(?!#)(.+)/;
            var content = data.replace(heading1Pat, '<h1>$1</h1>' );
        }
        else if(type == 'heading2') {
            var heading2Pat = /^#{2}(?!#)(.+)/;
            var content = data.replace(heading2Pat, '<h2>$1</h2>' );
        }
        else if(type == 'heading3') {
            var heading3Pat = /^#{3}(?!#)(.+)/;
            var content = data.replace(heading3Pat, '<h3>$1</h3>' );
        }
        else if(type == 'heading4') {
            var heading4Pat = /^#{4}(?!#)(.+)/;
            var content = data.replace(heading4Pat, '<h4>$1</h4>' );
        }
        else if(type == 'heading5') {
            var heading5Pat = /^#{5}(?!#)(.+)/;
            var content = data.replace(heading5Pat, '<h5>$1</h5>' );
        }
        else if(type == 'heading6') {
            var heading6Pat = /^#{6}(?!#)(.+)/;
            var content = data.replace(heading6Pat, '<h6>$1</h6>' );
        }
        else {
            var content = data;
        }

        this.outputBuffer.push({"modifiedData": content, "type": type, "level": level });
    }

    openCloseWebImage(type, level, data) {
        if(type == 'webImage') {
            var webImagePat = /^\!\[(\S+)\]\((\S+)\)/;
            var content = data.replace(webImagePat, '<div class="img"><img src="$2" alt="$1"></div>');
        }
        else {
            var content = data;
        }

        this.outputBuffer.push({"modifiedData": content, "type": type, "level": level });
    }

    openCloseHyperlink(type, level, data) {
        if(type == 'hyperlink') {
            var hyperlinkPat = /^\[(\S.+)\]\((\S+)\)/;
            var content = data.replace(hyperlinkPat, 
                '<div class="hyperlink">Link: <a target="_blank" href="$2">$1</a></div>');
        }

        // console.log('md_editor3p1_ems.js.content:', content);

        this.outputBuffer.push({"modifiedData": content, "type": type, "level": level });
    }

    testOpenCloseBoldItalic(data) {
		// var boldPat = /(?<!\\)_(?<!\\)_/g;  // (?<!\\): negative(!) lookbehind(?<). Whenever '_' encounters, then check '\' charater is not behind.
        let boldPat = /(?<![\\_])__(?!_)/g;
		// var italicPat = /(?<!\\)_/g;
		let italicPat = /(?<![\\_])_(?!_)/g;
        // var boldItalicPat = /(?<!\\)_(?<!\\)_(?<!\\)_/g;
        let boldItalicPat = /(?<![\\_])___(?!_)/g;

        //  '\_\_' = escape + _ + escape + _ = __ because escape + _ is just _
        //  '\\_\\_' = escape + \ + _ + escape + \ + _ = \_\_ because eacape + \ is \

        // var boldTagStack = [];
        // var italicTagStack = [];

        // var content = data.replace(boldPat, (match) => {
        //     if(boldTagStack.length > 0){
        //         return boldTagStack.pop();
        //     }
        //     else{
        //         boldTagStack.push('</strong>');
        //         return '<strong>';
        //     }
        // });

        // if(boldTagStack.length > 0){
        //     content = content + boldTagStack.pop();
        // }

        //  modified to support multi-lines
        var content = data.replace(boldPat, (match) => {
            if(this.boldTagStack.length > 0){
                return this.boldTagStack.pop();
            }
            else{
                this.boldTagStack.push('</strong>');
                return '<strong>';
            }
        });

        // content = content.replace(italicPat, (match) => {
        //     if(italicTagStack.length > 0){
        //         return italicTagStack.pop();
        //     }
        //     else{
        //         italicTagStack.push('</em>');
        //         return '<em>';
        //     }
        // });

        // if(italicTagStack.length > 0){
        //     content = content + italicTagStack.pop();
        // }

        //  modified to support multi-lines
        content = content.replace(italicPat, (match) => {
            if(this.italicTagStack.length > 0){
                return this.italicTagStack.pop();
            }
            else{
                this.italicTagStack.push('</em>');
                return '<em>';
            }
        });


        //  modified to support multi-lines
        content = content.replace(boldItalicPat, (match) => {
            if(this.boldItalicTagStack.length > 0){
                return this.boldItalicTagStack.pop();
            }
            else{
                this.boldItalicTagStack.push('</em></strong>');
                return '<strong><em>';
            }
        });

        // console.log('md_editor3p1_ems.len(boldTagStack: %d italicTagStack: %d boldItalicTagStack: %d)', this.boldTagStack.length, this.italicTagStack.length, this.boldItalicTagStack.length);

        return content;
    }

    openCloseHorizontalRule(type, data) {
        var hrCond = type == 'hr';

        if(hrCond) {
            var content = '<hr>'
        }
        else {
            var content = data;
        }

        this.outputBuffer.push({"modifiedData": content, "type": type, "level": 0});
    }

    testOpenCloseEscapeCharacter(data) {
        var escPat01 = /\\_/g;
        var escPat02 = /^\\\*/g;
        var escPat03 = /^\\#/g;
        var escPat04 = /^\\\[/g;
        const escPat05 = /\\\(/g;
        const escPat06 = /\\\)/g;

        // let matchings = {'escPat01': data.search(escPat01), 'escPat02': data.search(escPat02), 'escPat03': data.search(escPat03), 'escPat04': data.search(escPat04)}
        // console.log('md_editor3p1_ems.js.data: %o, matching result: %o', data, matchings);

        var content = data.replace(escPat01, '_');
        content = content.replace(escPat02, '*');
        content = content.replace(escPat03, '#');
        content = content.replace(escPat04, '[');
        content = content.replace(escPat05, '(' );
        content = content.replace(escPat06, ')' );

        return content;
    }

    openCloseEquationTag(type, data) {
        // single line equation
        var eqCond = type == 'eq';

        if(eqCond) {
            var eqPat = /<equation>((.*?\n)*?.*?)<\/equation>/ig;

            var content = data.replace(eqPat, (match, p0) => {
			    //console.log('match: ', match);
			    //console.log('p0: ', p0);
			    return '<div class="eq"><img src="http://latex.codecogs.com/png.latex?' + encodeURIComponent(p0) + '"/></div>';
            });
        }
        else{
            var content = data;
        }
        
        this.outputBuffer.push({"modifiedData": content, "type": type, "level": 0});
    }

    testOpenCloseLeadingSpaceTab(data) {
        var content = data;

        // var tabPat01 = /^\t+/;
        var spacePat01 = /^[ ]+/;

        // if(tabPat01.test(content)){
        //     var pLen = content.match(tabPat01)[0].length;
        //     var reps = '';

        //     // console.log('content.match(tabPat01):', content.match(tabPat01));

        //     for(var i=0; i<pLen; i++){
        //         reps = reps + '&nbsp&nbsp&nbsp&nbsp';
        //     }

        //     content = content.replace(tabPat01, reps);
        // }

        if(spacePat01.test(content)){
            var pLen = content.match(spacePat01)[0].length;
            var reps = '';
            
            // console.log('content.match(spacePat01):', content.match(spacePat01));

            for(var i=0; i<pLen; i++){
                reps = reps + '&nbsp';
            }

            content = content.replace(spacePat01, reps);
        }

        return content;
    }

    accumulateEq2Comp(type, data) {
        //  accumulation multi line equations

        // var trailingSTNPat = /\s*$/;    //  Space, Tab, and New line

        if(this.eq2Flag == true && type != 'eq2Begin' && type != 'eq2End') {
            // var component = data.replace(trailingSTNPat, '');
            var component = data;
            this.eq2FIFO.push(component);
        }
    }

    openCloseEquation2Tag(type, data) {
        //  multi line equation
        if(type == 'eq2End') {
            var equationComponent = this.eq2FIFO.reduce((acc, curE) => {
                return acc + curE;
            }, '');
            var equationURIComponent = encodeURIComponent(equationComponent);
			var content = '<div class="eq"><img src="http://latex.codecogs.com/svg.latex?' + equationURIComponent + '"/></div>';
            this.eq2FIFO.splice(0); //  Clear
        }
        else {
            var content = data;
        }

        this.outputBuffer.push({"modifiedData": content, "type": type, "level": 0});
    }

    testOpenCloseCircleNumber(data) {

        const circleNumPat = /(?<!\\)\(([(0-9)]{1})(?<!\\)\)/g;

        let content = data.replace(circleNumPat, (match, p0) => {
			// console.log('match: ', match);
			// console.log('p0: ', p0);

            let mData = data;
            
            switch (p0){
                case '1':
                    mData = '&#9312;';
                    break;
                case '2':
                    mData = '&#9313;';
                    break;
                case '3':
                    mData = '&#9314;';
                    break;
                case '4':
                    mData = '&#9315;';
                    break;
                case '5':
                    mData = '&#9316;';
                    break;
                case '6':
                    mData = '&#9317;';
                    break;
                case '7':
                    mData = '&#9318;';
                    break;
                case '8':
                    mData = '&#9319;';
                    break;
                case '9':
                    mData = '&#9320;';
                    break;
                case '10':
                    mData = '&#9321;';
                    break;
                case '11':
                    mData = '&#9322;';
                    break;
                case '12':
                    mData = '&#9323;';
                    break;
                case '13':
                    mData = '&#9324;';
                    break;
                case '14':
                    mData = '&#9325;';
                    break;
                case '15':
                    mData = '&#9326;';
                    break;
                default:
                    mData = mData;
            }

            return mData;
        });

        // console.log('content: ', content);

        return content;
    }

    testOpenCloseArrowChars(data) {
        const arrowPat01 = /(?<!<)-->/g;
        const arrowPat02 = /<--(?!>)/g;
        const arrowPat03 = /<-->/g;

        // const isRArr = arrowPat01.test(data);
        // const isLArr = arrowPat02.test(data);
        // const isHArr = arrowPat03.test(data);

        // console.log('isRArr: %s, isLArr: %s, isHArr: %s', isRArr, isLArr, isHArr);

        let content = data.replace(arrowPat01, (match, p0) => {
            // console.log('match: ', match);
            // console.log('p0: ', p0);
            return '&rarr;';
        });

        content = content.replace(arrowPat02, (match, p0) => {
            // console.log('match: ', match);
            // console.log('p0: ', p0);
            return '&larr;';
        });

        content = content.replace(arrowPat03, (match, p0) => {
            // console.log('match: ', match);
            // console.log('p0: ', p0);
            return '&harr;';
        });

        // console.log('content: ', content);

        return content;
    }


    modifyLine(evaluationResult) {

        var data = evaluationResult["linedata"];
        var type = evaluationResult["type"];
        var level = evaluationResult["level"];

        var prevLineEvalResult = this.getPrevLineEvalResult();
        var prevType = prevLineEvalResult["type"];
        var prevLevel = prevLineEvalResult["level"];

        var levelDiff = level - prevLevel;

        //  Close previous block
        this.closeList(prevType, type, levelDiff);
        this.closeBQuote(prevType, type, levelDiff);
        this.closeCodeBlock(type, data);

        // console.log('md_editor3p1_ems.js.modifyLine.type:', type);

        //  Open new block or Modify current line
        if(this.eq2Flag == true) {
            this.accumulateEq2Comp(type, data);
        }
        else if(type == 'ul') {
            this.openList(prevType, type, level, levelDiff, data);
        }
        else if(type == 'bquote') {
            this.openBQuote(prevType, type, level, levelDiff, data);
        }
        else if(type == 'codeBlock'){
            this.openCodeBlock(type, level, data);
        }
        else if(type == 'heading1' || type == 'heading2' || type == 'heading3'|| 
            type == 'heading4' || type == 'heading5' || type == 'heading6') {
        
            this.openCloseHeading(type, level, data);
        }
        else if(type == 'webImage'){
            this.openCloseWebImage(type, level, data);
        }
        else if(type == 'hyperlink'){
            this.openCloseHyperlink(type, level, data);
        }
        else if(type == 'hr'){
            this.openCloseHorizontalRule(type, data);
        }
        else if(type == 'eq'){
            this.openCloseEquationTag(type, data);
        }
        else if(type == 'eq2End') {
            this.openCloseEquation2Tag(type, data);
        }
        else {
            var mData = this.testOpenCloseBoldItalic(data); // inline w/ multiple times
            mData = this.testOpenCloseCircleNumber(mData);  // inline w/ multiple times
            mData = this.testOpenCloseArrowChars(mData);    // inline w/ multiple times
            mData = this.testOpenCloseEscapeCharacter(mData);   // inline w/ multiple times
            mData = this.testOpenCloseLeadingSpaceTab(mData);   // not defined pattern but need to be trimmed

            var normalPat = /(.*)\n/;
            var content = mData.replace(normalPat, '$1<br>');
            this.outputBuffer.push({"modifiedData": content, "type": type, "level": level});
        }
    }

    doMarkingDown() {

        //  1. Read line --> Line buffer --> 
        //  2. Evaluation --> evaluationBuffer: line data + type + level --> 
        //  3. Modify&Accumulation --> outputBuffer: modifiedData + type + level -->
        //  4. end line? --> Y: print, N: read next line

        this.splitStrings();

        for (var i=0; i<this.targetStringLines.length; i++) {

            this.readLine(this.targetStringLines[i]);

            var evalResult = this.evaluateLine();

            this.evaluationBuffer.push(evalResult);

            this.modifyLine(evalResult);
        }

        //  Print
        var outputBufferLength = this.outputBuffer.length;
        for (var i=0; i<outputBufferLength; i++){
            this.outputString = this.outputString + this.outputBuffer[i]["modifiedData"];       
        }

        //  for Debug
        // console.log('md_editor3_ems.js.targetStringLines: ', this.targetStringLines);
        // console.log('md_editor3_ems.js.evaluationBuffer: ', this.evaluationBuffer);
        // console.log('md_editor3_ems.js.outputBuffer: ', this.outputBuffer);
        // console.log('md_editor3_ems.js.listTagStack: ', this.listTagStack);
        // console.log('md_editor3_ems.js.bquoteTagStack: ', this.bquoteTagStack);
        // console.log('md_editor3_ems.js.eq2Flag: ', this.eq2Flag);
        // console.log('md_editor3_ems.js.eq2FIFO: ', this.eq2FIFO);

        return this.outputString;
    }

}

export default MarkingDown;
import React, { useState } from "react";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType, AppDispatchType } from "..";

// import { setNoteListInfo } from "../reducers/note_list_info";
import { setNoteListInfo, NoteListState } from "../features/note_list_info";
// import { setPrevPageInfo } from "../reducers/prev_page_info";
import { setPrevPageInfo } from "../features/prev_page_info";

import { Row, Col } from 'antd';

import "../css/notelist_entry.css";


type notelistEntryInputProps = {
    noteNo: number
    // , curPageNo: number
    , title: string
    , lastUpdate: string
    , subjectTitle: string
};

function NoteListEntry(prop: notelistEntryInputProps): JSX.Element {

    const dispatch = useDispatch<AppDispatchType>();
    const navigate = useNavigate();
    
    const [noteNo, setNoteNo] = useState<number>(prop.noteNo);
    // const [curPageNo, setCurPageNo] = useState<number>(prop.curPageNo);
    const [noteTitle, setNoteTitle] = useState<string>(prop.title? prop.title:'제목이 없는 노트');
    const [lastUpdate, setLastUpdate] = useState<string>(prop.lastUpdate);
    const [subjectTitle, setSubjectTitle] = useState<string>(prop.subjectTitle);

    const curPageNo = useSelector<RootStateType, number>((state) => {
        let _curPageNo = state.noteList.curPageNo;

        if(_curPageNo === null || _curPageNo === undefined){
            const sessNoteListInfo = sessionStorage.getItem("noteListInfo");
            if(sessNoteListInfo){
                const parsedSessNoteListInfo: NoteListState = JSON.parse(sessNoteListInfo);
                _curPageNo = parsedSessNoteListInfo["curPageNo"];
            }
        }

        // console.log('notelist.tsx._curPageNo:', _curPageNo);

        return _curPageNo;
    });

    function onClickNoteTitleHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        // let dataNoteNo: number|null = null;

        // if(event.currentTarget.dataset.noteno){
        //     dataNoteNo = parseInt(event.currentTarget.dataset.noteno);
        // }

        // console.log('NoteInfo.tsx.onClickNoteTitleHandler.noteno:', dataNoteNo);
        // console.log('Notelist_entry.tsx.onClickNoteTitleHandler.prop.curPageNo:', prop.curPageNo);

        // console.log('Notelist_entry.tsx.onClickNoteTitleHandler.curPageNo:', curPageNo);
        // console.log('Notelist_entry.tsx.onClickNoteTitleHandler.noteNo:', noteNo);

        // dispatch(setNoteListInfo({"curPageNo": curPageNo, "selectedNoteNo": noteNo}));
        dispatch(setNoteListInfo(curPageNo, noteNo));
        dispatch(setPrevPageInfo('/notelist'));
        navigate('/note');
    }

    return(
        <div className="note_info_component_div">
            {/* <Row>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
                <Col
                    xs={{span: 16}} sm={{span: 16}} md={{span: 12}}
                    lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                >
                    <div className="note_title_div">
                        <a onClick={onClickNoteTitleHandler} data-noteno={noteNo}>{noteTitle}</a>
                    </div>
                </Col>
                <Col
                    xs={{span: 6}} sm={{span: 6}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                >
                    <div className="note_time_div">
                        {lastUpdate}
                    </div>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 4}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
            </Row> */}
            <Row className='note_info_row_div' onClick={onClickNoteTitleHandler} data-noteno={noteNo}>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 2}}
                    lg={{span: 2}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
                <Col className='note_info_main_col_div'
                    xs={{span: 22}} sm={{span: 22}} md={{span: 20}}
                    lg={{span: 20}} xl={{span: 16}} xxl={{span: 16}}
                >
                    <Row className="note_header_row">
                        <Col className="note_writer_div"
                            xs={{span: 10}} sm={{span: 10}} md={{span: 10}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                        >
                            {subjectTitle}
                        </Col>
                        <Col className="note_timecreated_div"
                            xs={{span: 14}} sm={{span: 14}} md={{span: 14}}
                            lg={{span: 12}} xl={{span: 12}} xxl={{span: 12}}
                        >
                            최종 수정: {lastUpdate}
                        </Col>
                    </Row>
                    <Row className="note_title_row">
                        <Col className="note_subject_title_div"
                            xs={{span: 0}} sm={{span: 0}} md={{span: 0}}
                            lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}
                        >
                        </Col>
                        <Col className="note_title_hypen_div"
                            xs={{span: 0}} sm={{span: 0}} md={{span: 0}}
                            lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}
                        >
                        </Col>
                        <Col className="note_article_title_div"
                            xs={{span: 24}} sm={{span: 24}} md={{span: 24}}
                            lg={{span: 24}} xl={{span: 24}} xxl={{span: 24}}
                        >
                            {/* <a onClick={onClickNoteTitleHandler} data-noteno={noteNo}>{noteTitle ? noteTitle : '제목이 없습니다'}</a> */}
                            {noteTitle ? noteTitle : '제목이 없습니다'}
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={{span: 1}} sm={{span: 1}} md={{span: 2}}
                    lg={{span: 2}} xl={{span: 4}} xxl={{span: 4}}
                >
                </Col>
            </Row>
        </div>
    );
}

export default NoteListEntry;
